.structure-section {
  margin-top: rem(120);
  margin-bottom: rem(90);

  @include desktop-sm-down {
    margin-top: rem(30);
    margin-bottom: rem(50);
  }

  .section-head {
    margin-bottom: rem(60);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-15) rem(-30);

    @include desktop-sm-down {
      margin-bottom: rem(-10);
      justify-content: center;
    }
  }

  &__col {
    padding: 0 rem(15);
    margin-bottom: rem(30);
    width: 33.33%;

    @include desktop-sm-down {
      width: 50%;
      margin-bottom: rem(10);
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &__arena-row {
    display: flex;
    flex-wrap: wrap;
    margin: rem(40) rem(-15) 0;

    @include desktop-sm-up {
      display: none;
    }

    @include desktop-sm-down {
      justify-content: center;
    }
  }

  &__arena-col {
    padding: 0 rem(15);
    margin-bottom: rem(30);
    width: 50%;

    @include desktop-sm-down {
      margin-bottom: rem(10);
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &__route-row {
    display: flex;
    flex-wrap: wrap;
    margin: rem(30) rem(-15) 0;

    @include desktop-sm-up {
      display: none;
    }

    @include desktop-sm-down {
      justify-content: center;
      margin-bottom: rem(-15);
    }
  }

  &__route-col {
    padding: 0 rem(15);
    margin-bottom: rem(30);
    width: 50%;

    @include desktop-sm-down {
      margin-bottom: rem(10);
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  .arena-swiper {
    + .arena-info {
      margin-top: rem(70);

      @include desktop-sm-down {
        margin-top: rem(35);
      }
    }
  }
}

@import "includes/structure-section/accordion-block";

.card-structure {
  background: #0e1248;
  border-radius: rem(20);
  overflow: hidden;
  color: #fff;
  height: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: rem(10);
  }

  &__head {
    position: relative;
    border-radius: rem(20);
    padding-top: 36.7%;
    overflow: hidden;
    z-index: 2;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__ico {
    position: absolute;
    top: rem(30);
    left: rem(30);
    width: rem(72);

    @include desktop-sm-down {
      width: rem(22);
      top: rem($container-inner-padding-md);
      left: rem($container-inner-padding-md);
    }
  }

  &__main {
    padding: rem(30);
    position: relative;
    z-index: 2;

    @include desktop-sm-down {
      padding: rem(20) rem($container-inner-padding-md) rem(30);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(36);
    line-height: 130%;
    letter-spacing: 0.02em;
    margin-bottom: rem(10);

    @include desktop-sm-down {
      font-size: rem(20);
      margin-bottom: rem(8);
    }
  }

  &__more {
    font-style: italic;
    font-size: rem(18);
    line-height: 1;

    @include desktop-sm-down {
      font-size: rem(14);
    }
  }

  a.card-structure__more {
    display: inline-block;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.card-stadium {
    &::before {
      content: "";
      display: block;
      background: #10C3FF;
      filter: blur(rem(50));
      border-radius: rem(20);
      width: rem(220);
      height: rem(220);
      position: absolute;
      bottom: rem(62);
      right: rem(-23);
    }
  }

  &.card-arena {
    &::before {
      content: "";
      display: block;
      background: #10C3FF;
      filter: blur(rem(50));
      border-radius: rem(20);
      width: rem(220);
      height: rem(220);
      position: absolute;
      bottom: rem(50);
      left: rem(-64);
    }

    &::after {
      content: "";
      display: block;
      background: #4A36C6;
      filter: blur(rem(35));
      border-radius: rem(20);
      width: rem(377);
      height: rem(331);
      position: absolute;
      top: rem(129);
      right: rem(-156);
    }
  }
}

.route-link {
  display: flex;
  align-items: center;
  height: rem(120);
  padding: rem(30);
  background: #0e1248;
  border-radius: rem(20);
  font-weight: 600;
  font-size: rem(28);
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #ffffff;

  @include desktop-sm-down {
    font-size: rem(15);
    height: rem(62);
    padding: rem(10) rem($container-inner-padding-md);
  }

  &.car {
    background: #0e1248;
  }

  &.public {
    background: #00a3da;
  }

  &__ico {
    width: rem(50);
    margin-right: rem(30);

    @include desktop-sm-down {
      width: rem(25);
      margin-right: rem(10);
    }

    img {
      width: 100%;
    }
  }
}
