@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB45.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB46.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "VTB Cond";
  src: url('../fonts/VTB/VTB47.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB55.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB56.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "VTB Cond";
  src: url('../fonts/VTB/VTB57.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB65.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB66.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "VTB Cond";
  src: url('../fonts/VTB/VTB67.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB75.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB76.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "VTB Cond";
  src: url('../fonts/VTB/VTB77.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB95.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VTB";
  src: url('../fonts/VTB/VTB96.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "VTB Cond";
  src: url('../fonts/VTB/VTB97.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}