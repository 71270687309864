.see-all {
  height: rem(60);
  background: #f7f8fb;
  color: #adbae1;
  display: flex;
  width: 100%;

  @include desktop-sm-down {
    height: rem(50);
  }

  &:hover {
    background: #123063;
    color: #fff;
  }
}