.know-section {
  margin: rem(80) 0;

  @include desktop-sm-down {
    margin-top: rem(25);
    margin-bottom: rem(50);
  }

  &__title {
    margin-bottom: rem(25);
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-10) rem(-20);

    @include desktop-sm-down {
      margin-bottom: rem(-10);
    }
  }

  &__col {
    padding: 0 rem(10);
    margin-bottom: rem(20);
    width: 25%;

    @include desktop-sm-down {
      width: 50%;
      margin-bottom: rem(10);
    }

    @include tablets-down {
      width: 100%;
    }
  }
}