.request-block {
  margin-top: rem(80);
  margin-bottom: rem(80);
  background: #123063;
  border-radius: rem(30);
  overflow: hidden;
  filter: drop-shadow(0 0 rem(150) rgba(#123063, 0.5));

  @include desktop-sm-down {
    margin-top: rem(36);
    margin-bottom: rem(30);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &__bg {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: rem(160);
      height: rem(700);
      background: url("../img/images/squares-bq.png") no-repeat 50% / contain;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(40%, -50%);
      z-index: 2;

      @include desktop-sm-down {
        width: 100%;
        height: rem(75);
        bottom: 0;
        top: auto;
        left: 50%;
        transform: translate(-50%, 40%);
        background: url("../img/images/squares-bq-mob.png") no-repeat 50% 0 / cover;
      }
    }

    &::after {
      content: "";
      display: block;
      width: rem(928);
      height: rem(700);
      position: absolute;
      top: rem(-70);
      right: rem(-244);
      background: #4A36C6;
      filter: blur(rem(200));
      z-index: 1;

      @include desktop-sm-down {
        width: rem(536);
        height: rem(7405);
        top: rem(225);
        left: rem(33);
      }
    }
  }

  &__inner {
    padding: rem(70) rem(130);
    position: relative;
    z-index: 3;
    
    @include desktop-sm-up {
      min-height: rem(390);
    }
    
    @include desktop-sm-down {
      padding: rem(35) rem($container-inner-padding-md);
      padding-bottom: rem(65);
    }
    
  }

  &__head {
    margin-bottom: rem(40);

    @include desktop-sm-down {
      margin-bottom: rem(25);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(48);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: rem(14);

    @include desktop-sm-down {
      font-size: rem(26);
      margin-bottom: rem(10);
    }
  }

  &__desc {
    font-weight: 600;
    font-size: rem(14);
    line-height: 130%;
    color: #FFFFFF;

    @include desktop-sm-down {
      font-size: rem(12);
    }
  }
}

.container_sm {
  .request-block {
    

    &__bg {
      &:after {
        @include desktop-sm-up {
          top: rem(-108);
          left: rem(560);
        }
      }
    }

    &__title {
      margin-bottom: 0;

      @include desktop-sm-up {
       font-size: rem(36);
      }
    }

    &__inner {
      @include desktop-sm-up {
        min-height: rem(300);
        padding: rem(35) rem(140) rem(45)rem(50) ;
      }
    }
  }

  .request-form {
    input {
      @include desktop-sm-up {
        font-size: rem(14);
        padding-left: rem(30);
      }
    }
  }

}