.benefits-block {
  color: #fff;
  background: #0D4D8F;
  border-radius: rem(30);
  padding: rem(80) rem(50) rem(70) rem(130);
  overflow: hidden;
  position: relative;
  margin-top: rem(60);
  margin-bottom: rem(90);

  @include desktop-sm-down {
    border-radius: rem(20);
    padding: rem(40) rem($container-inner-padding-md) 0;
    margin-bottom: rem(50);
    margin-top: rem(25);
  }

  &:after {
    content: "";
    display: block;
    width: rem(1080);
    height: rem(700);
    position: absolute;
    left: rem(1040);
    top: rem(-270);
    background: #00A3DA;
    filter: blur(rem(200));
    border-radius: 50%;
    pointer-events: none;

    @include desktop-sm-down {
      width: rem(544);
      height: rem(352);
      left: rem(140);
      top: rem(-120);
    }
  }

  &__cover {
    position: relative;
    z-index: 2;
  }

  &__title {
    margin-bottom: rem(35);

    @include desktop-sm-down {
      margin-bottom: rem(14);
    }
  }

  .list-rhombus {
    margin-bottom: rem(90);

    @include desktop-sm-down {
      margin-bottom: rem(40);
    }

    &:last-child {
      margin-bottom: 0;
    }

    li {
      font-size: rem(22);

      @include desktop-sm-down {
        font-size: rem(16);
      }
    }
  }

  .benefits-swiper {
    @include desktop-sm-down {
      margin: 0 rem(-$container-inner-padding-md);
    }
  }

  &_type-a {
    padding-bottom: rem(100);

    @include desktop-sm-down {
      padding-bottom: rem(45);
    }
  

    &:after {
      left: rem(572);
      top: rem(381);
      background: #00B5BB;

      @include desktop-sm-down {
        left: rem(140);
        top: rem(140);
      }
    }
  }
}

.benefits-swiper {
  
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: rem(490);

    @include desktop-sm-down {
      width: 100%;

    }

    .card-h {
      height: rem(440);

      @include desktop-sm-down {
        height: auto;
        padding-top: 70%;
        border-radius: 0 0 rem(20) rem(20);
      }
    }

    &:not(:last-child) {
      margin-right: rem(30);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__nav {
    position: absolute;
    bottom: rem(40);
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;

    @include desktop-sm-down {
      right: rem(25);
      bottom: rem(30);
    }
  }

  &__prev,
  &__next {
    width: rem(45);
    height: rem(45);
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194d8;
    cursor: pointer;
    transition: 0.3s;

    @include desktop-sm-down {
      width: rem(25);
      height: rem(25);
    }

    &:hover {
      color: #fff;
      background: #11387a;
    }

    &:not(:last-child) {
      margin-right: rem(15);

      @include desktop-sm-down {
        margin-right: rem(8);
      }
    }

    .icon {
      font-size: rem(12);

      @include desktop-sm-down {
        font-size: rem(7);
      }
    }
  }

  &__prev {
    margin-right: rem(15);
  }
}