&__main-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(110);
  transition: .3s;
}

&__logo {
  display: block;
  flex-shrink: 0;
  max-width: rem(231);
  margin-right: rem(83);
  transition: 0.3s;

  img {
    transition: 0.3s;
  }
}

&__nav {
  margin-right: auto;

  ul {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: rem(55);
      }

      &.active {
        a {
          color: #0194d8;
        }
      }

      a {
        font-weight: 600;
        font-size: rem(16);
        line-height: 1;
        color: #123063;
        padding: rem(10) 0;
        transition: 0.3s;

        &:hover {
          color: #0194d8;
        }
      }
    }
  }
}

&__actions  {
  display: flex;
  align-items: center;
  margin: 0 rem(42);

  .header__sm-btn {
    &:not(:last-child) {
      margin-right: rem(30);
    }
  }
}


&__btn-entry {
  padding: 0 rem(40);
  background: #fff;
  border: rem(2) solid #123063;
  color: #123063;
  height: rem(50);
  font-weight: bold;
  margin-right: rem(30);

  &:hover {
    background: #123063;
    color: #fff;
  }
}


&__btn-lang {
  padding: 0 rem(5);
  font-weight: bold;
  font-size: rem(16);
  background: #ebf4f9;
  height: rem(50);
  width: rem(50);

  &:hover {
    background: #123063;
    color: #fff;
  }
}