.row  {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-15) rem(-30);

  @include desktop-sm-down {
    margin:0 rem(-5) rem(-10);
  }

  &_gallery {
    .col {

      &:nth-child(6n+1) {
        width: 58.5%;
      }

      &:nth-child(6n+2) {
        width: 41.5%;
      }

      &:nth-child(6n+3),
      &:nth-child(6n+4) {
        width: 50%;
      }

      &:nth-child(6n+5) {
        width: 41.5%;
      }

      &:nth-child(6n+6) {
        width: 58.5%;
      }

      @include desktop-sm-down {
        width: 50% !important;
      }

      @include tablets-down {
        width: 100% !important;
      }
    }
  }
}

.col {
  padding: 0 rem(15);
  margin-bottom: rem(30);

  @include desktop-sm-down {
    padding: 0 rem(5);
    margin-bottom: rem(10);
  }

  &.w-100 {
    width: 100%;
  }

  &.w-66 {
    width: 66.66%;

    @include desktop-sm-down {
      width: 50%;
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &.w-60 {
    width: 60%;

    @include desktop-sm-down {
      width: 50%;
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &.w-50 {
    width: 50%;

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &.w-40 {
    width: 40%;

    @include desktop-sm-down {
      width: 50%;
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &.w-33 {
    width: 33.333%;

    @include desktop-sm-down {
      width: 50%;
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &.w-25 {
    width: 25%;

    @include desktop-sm-down {
      width: 33.33%;
    }

    @include tablets-down {
      width: 50%;
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

 
}