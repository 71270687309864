.arena-info {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 rem(-13) rem(-26);
  }

  &__col {
    padding: 0 rem(13);
    margin-bottom: rem(26);
    width: rem(404);
    text-align: center;
    font-weight: 600;
    font-size: rem(18);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #ffffff;

    @include desktop-sm-down {
      width: 50%;
      font-size: rem(14);
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  &__title {
    color: #00a3da;
    margin-bottom: rem(10);
  }

  &__val {
    font-weight: bold;
    font-size: rem(64);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-bottom: rem(10);

    @include desktop-sm-down {
      font-size: rem(36);
    }
  }

  &__desc {
    color: #ffffff;
  }
}
