
.swiper-button-prev,
.swiper-button-next {
  width: rem(55);
  height: rem(55);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #123063;
  border-radius: 50%;
  color: #fff;
  font-size: rem(14);
  transition: .3s;

  @include desktop-sm-down {
    width: rem(22);
    height: rem(22);
    font-size: rem(7);
  }

  &:hover {
    background: #00A3DA;
  }

  &::after {
    display: none;
  }
}

.swiper-button-prev {
  left: rem(-27);

}
.swiper-button-next {
  right: rem(-27);
}
