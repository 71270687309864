.events-block {
  background: #0E1248;
  border-radius: rem(40);
  margin: rem(50) 0;
  position: relative;
  overflow: hidden;

  @include desktop-sm-down {
    border-radius: rem(20);
    margin: rem(20) 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    display: block;
    pointer-events: none;
    width: rem(800);
    height: rem(800);
    border-radius: 50%;
    background: #00A3DA;
    filter: blur(rem(120));
    position: absolute;
    left: rem(-400);
    top: rem(-458);
  
    @include desktop-sm-down {
      display: none;
    }
  }

  &:after {
    content: "";
    display: block;
    pointer-events: none;
    width: rem(543);
    height: rem(522);
    border-radius: 50%;
    background: rgba(16, 195, 255, 0.67);
    filter: blur(rem(100));
    position: absolute;
    right: rem(66);
    bottom: rem(-116);
  
    @include desktop-sm-down {
      display: none;
    }
  }

  &__cover {
    position: relative;
    z-index: 2;
    padding: rem(90) 0 rem(100) 0;

    @include desktop-sm-down {
      padding: rem(30) 0 rem(35) 0;
    }
  }
}