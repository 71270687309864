.card-c {
  border-radius: rem(30);
  overflow: hidden;
  position: relative;
  display: block;

  @include desktop-sm-down {
    border-radius: rem(20);
  }

  &:hover {
    .card-c__img img {
      transform: scale(1.2);
    }
  }

  &__img {
    display: block;
    padding-top: 57%;
    position: relative;
    border-radius: rem(30);
    overflow: hidden;

    @include desktop-sm-down {
      border-radius: rem(20);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          90deg,
          rgba(18, 48, 99, 0.73) 0%,
          rgba(18, 48, 99, 0) 39.45%
        ),
        rgba(18, 48, 99, 0.25);
      mix-blend-mode: darken;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__tag {
    display: inline-block;
    background: rgba(155, 193, 215, 0.7);
    backdrop-filter: blur(rem(4));
    border-radius: rem(100);
    padding: rem(8.5) rem(20) rem(9.5);
    position: absolute;
    top: rem(30);
    left: rem(34);
    font-size: rem(12);
    line-height: 1;
    color: #ffffff;
    z-index: 3;
    text-align: center;
    min-width: rem(80);
    box-sizing: border-box;

    @include desktop-sm-down {
      padding: rem(9) rem(15) rem(9.5);
      font-size: rem(10);
      top: rem(15);
      left: rem($container-inner-padding-md);
      min-width: rem(56);
    }
  }

  &__main {
    padding: rem(20) 0;
    box-sizing: border-box;
    position: relative;

    @include desktop-sm-down {
      padding: rem(15) 0;
    }
  }

  &__title {
    line-height: 110%;
    font-weight: 600;
    font-size: rem(26);
    color: #191c44;
    margin-bottom: rem(10);
  

    @include desktop-sm-down {
      font-size: rem(18);
      margin-bottom: rem(7);

      br {
        display: none;
      }
    }
  }

  a.card-c__title {
    display: block;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__date {
    font-size: rem(14);
    font-style: italic;
    line-height: 1;
    display: flex;
    color: #191c44;

    @include desktop-sm-down {
      font-size: rem(12);
    }

    .dot {
      color: #00A3DA;
      margin: 0 rem(5);
    }
  }


  &__labels {
    display: flex;

    @include desktop-sm-up {
      position: absolute;
      top: rem(-20);
      left: rem(33);
      transform: translateY(-100%);
      z-index: 3;
    }

    @include desktop-sm-down {
      margin-top: rem(4);
    }
  }

  &__label {
    padding: rem(5) rem(16);
    height: rem(40);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: rem(14);
    line-height: 1;
    color: #ffffff;
    background: #00a3da;
    white-space: nowrap;
    border-radius: rem(15);
    z-index: 2;
    transition: background 0.3s, color 0.3s;
    margin-right: rem(16);
    margin-top: rem(16);

    @include desktop-sm-down {
      height: rem(35);
      font-size: rem(12);
      border-radius: rem(10);
      width: 100%;
      max-width: rem(144);
    }

    &:hover {
      background: #0091c2;
    }


    .icon-ticket2 {
      font-size: rem(16);
      margin-right: rem(8);
    }

    .icon-parking {
      font-size: rem(18);
      margin-right: rem(15);
    }

    &.secondary {
      background: rgba(204, 234, 254, 0.5);
      backdrop-filter: blur(rem(40));

      @include desktop-sm-down {
        color: #11387a;
        background: #e0f2ff;
        backdrop-filter: blur(rem(30));
      }

      &:hover {
        background: #ffffff;
        backdrop-filter: blur(rem(30));
        color: #00a3da;
      }
    }
  }

  &_lg {
    @include desktop-sm-up {
      .card-c__img {
        padding-top: 50%;
      }

      .card-c__title {
        font-size: rem(42);
        margin-bottom: rem(18);
        color: #fff;
        font-weight: bold;
        overflow: hidden;
      }

      .card-c__date {
        font-size: rem(16);
        color: #fff;
        font-weight: 600;
        margin-bottom: rem(42);
      }

      .card-c__main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: rem(90) rem(35) rem(30);
        z-index: 2;
      }

      .card-c__labels {
        position: relative;
        bottom: auto;
        left: auto;
        top: auto;
        transform: translate(0);
        margin-bottom: rem(-13);
        align-self: flex-start;
      }

      .card-c__label {
        height: rem(44);
        padding: rem(5) rem(20);

        &:not(:last-child) {
          margin-right: rem(13);
        }
      }

      .card-c__label.secondary {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(rem(30));

        &:hover {
          background: #ffffff;
          backdrop-filter: blur(rem(30));
          color: #00a3da;
        }
      }
    }
  }
}
