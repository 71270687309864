.card-e2 {
  color: #ffffff;
  height: rem(680);
  position: relative;
  background: #161a41;
  border-radius: rem(40);
  overflow: hidden;
  display: block;

  @include desktop-sm-down {
    border-radius: rem(20);
    height: rem(190);
  }

  &:hover {
    .card-e2__img img {
      transform: scale(1.2);
    }
  }

  &__img {
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 100% 50%;
      transition: 3s;
    }
  }

  &__main {
    position: relative;
    height: 100%;
    z-index: 2;
    padding: rem(80) rem(45);
    display: flex;
    flex-direction: column;

    @include desktop-sm-down {
      padding: rem(40) rem(15) rem(25);
    }

    &.end {
    
      @include desktop-sm-up {
        justify-content: flex-end;
      }
    }
  }

  &__title {
    font-weight: 800;
    font-size: rem(44);
    line-height: 110%;
    margin-bottom: rem(11);

    @include desktop-sm-down {
      font-size: rem(24);
      margin-bottom: rem(5);
    }
  }

  &__desc {
    font-style: italic;
    font-weight: 600;
    font-size: rem(14);
    line-height: 1;

    @include desktop-sm-down {
      font-size: rem(12);
    }
  }

  &.sm {
    height: rem(390);

    @include desktop-sm-down {
      height: rem(190);
    }

    .card-e2__main {
      padding: rem(50) rem(45);

      @include desktop-sm-down {
        padding: rem(25) rem(17) rem(25);
      }
    }
  }

}
