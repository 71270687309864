.card-l {
  border-radius: rem(30);
  overflow: hidden;
  padding-top: 106%;
  position: relative;
  display: block;

  @include desktop-sm-down {
    border-radius: rem(20);
    padding-top: 100%;
  }


  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%, 50%;
  }

  &__main {
    position: absolute;
    bottom: rem(14);
    left: rem(14);
    right: rem(14);
    background: #FFFFFF;
    border-radius: rem(25);
    padding: rem(25) rem(30);

    @include desktop-sm-down {
      padding: rem(25) rem(20);
      bottom: rem(8);
      left: rem(8);
      right: rem(8);
    }
  }

  &__type {
    font-style: italic;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #91C9E3;
    margin-bottom: rem(10);

    @include desktop-sm-down {
      font-size: rem(12);
      line-height: rem(15);
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(22);
    line-height: rem(28);
    letter-spacing: 0.02em;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(18);
      line-height: rem(23);
    }
  }

  &__hidden {
    display: none;;
  }

  &__hidden-cover {
    padding-top: rem(20);
  }

  &__desc {
    font-size: rem(16);
    line-height: 150%;
    color: #3A5D97;
  }

  .btn {
    height: rem(52);
    margin-top: rem(20);
    padding: 0 rem(32);
  }

  &__logo {
    position: absolute;
    top: rem(14);
    left: rem(14);
    display: block;
    width: rem(90);
    height: rem(90);
    border-radius: 50%;
    overflow: hidden;

    @include desktop-sm-down {
      width: rem(80);
      height: rem(80);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }
}