.accordion-block {
  margin-top: rem(170);
  margin-bottom: rem(90);

  @include desktop-sm-down {
    margin-top: rem(40);
    margin-bottom: rem(50);
    display: none;
  }

  &__item {
    background: #0e1248;
    border-radius: rem(30);
    overflow: hidden;
    position: relative;

    @include desktop-sm-down {
      border-radius: rem(15);
    }

    &:not(:last-child) {
      border-radius: rem(30) rem(30) 0 0;
      margin-bottom: rem(-30);
      padding-bottom: rem(30);

      @include desktop-sm-down {
        border-radius: rem(15) rem(15) 0 0;
        margin-bottom: rem(-15);
        padding-bottom: rem(15);
      }
    }

    &.item-1 {
      &::before {
        content: "";
        display: block;
        width: rem(874);
        height: rem(840);
        background: #10c3ff;
        filter: blur(rem(125));
        border-radius: 50%;
        position: absolute;
        top: rem(-57);
        left: 60%;
        transform: translateX(-50%);
      }

      .accordion-block__head-ico {
        img {
          width: rem(72);

          @include desktop-sm-down {
            width: rem(35);
          }
        }
      }
    }

    &.item-2 {
      &::before {
        content: "";
        display: block;
        width: rem(874);
        height: rem(839);
        background: #10c3ff;
        filter: blur(rem(125));
        border-radius: 50%;
        position: absolute;
        top: rem(850);
        left: rem(85);
      }

      &::after {
        content: "";
        display: block;
        width: rem(1080);
        height: rem(1041);
        background: #4a36c6;
        filter: blur(rem(140));
        border-radius: 50%;
        position: absolute;
        top: rem(50);
        right: rem(-70);
      }

      .accordion-block__head-ico {
        img {
          width: rem(77);

          @include desktop-sm-down {
            width: rem(35);
          }
        }
      }
    }

    &.item-3 {
      .accordion-block__head-ico {
        img {
          width: rem(76);

          @include desktop-sm-down {
            width: rem(35);
          }
        }
      }
    }

    &.item-4 {
      background: #00a3da;

      .accordion-block__head-ico {
        img {
          width: rem(82);

          @include desktop-sm-down {
            width: rem(40);
          }
        }
      }
    }

    &.active {
      .accordion-block__btn {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    padding: rem(30) rem(65);
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    height: rem(190);

    @include desktop-sm-down {
      height: auto;
      padding: rem(15) rem(30);
    }
  }

  &__head-ico {
    width: rem(100);
    margin-right: rem(50);

    @include desktop-sm-down {
      width: rem(50);
      margin-right: rem(25);
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(36);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #ffffff;
    max-width: rem(654);

    @include desktop-sm-down {
      font-size: rem(18);
      font-weight: 600;
    }
  }

  &__btn {
    margin-left: auto;
    width: rem(54);
    height: rem(54);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #2c508d;
    border-radius: rem(15);
    margin-right: rem(15);
    cursor: pointer;
    flex-shrink: 0;
    transition: .3s;

    @include desktop-sm-down {
      width: rem(34);
      height: rem(34);
    }

    .icon {
      font-size: rem(16);
      transition: 0.3s;

      @include desktop-sm-down {
        font-size: rem(10);
      }
    }

    &:hover {
      background: #0194D8;
    }
  }

  &__main {
    display: none;
    position: relative;
    z-index: 2;
  }

  &__inner {
    padding: 0 rem(60) rem(70);

    @include desktop-sm-down {
      padding: 0 rem(30) rem(50);
    }
  }

  .arena-swiper {
    margin-right: rem(-60);

    @include desktop-sm-down {
      margin-right: rem(-30);
    }
  }
}

@import "includes/accordion-block/arena-swiper";
@import "includes/accordion-block/arena-info";
