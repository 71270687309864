.tooltip {
  display: inline-flex;
  position: relative;
  
  &__title {
    align-items: center;
    justify-content: center;
    width: rem(27);
    height: rem(27);
    border-radius: 50%;
    background: #fff;
    font-weight: 600;
    font-size: rem(18);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #adbae1;
  }

  button {
    &:focus,
    &:hover {
      + .tooltip__main {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
      }
    }
  }

  &__arrow {
    position: absolute;
    width: rem(12);
    height: rem(12);
    background: inherit;
    visibility: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: rem(12);
      height: rem(12);
      background: inherit;
      visibility: visible;
      transform: rotate(45deg);
    }
  }

  &__main {
    padding: rem(20);
    // position: absolute;
    border-radius: rem(15);
    font-size: rem(12);
    line-height: 150%;
    color: #123063;
    width: rem(265);
    background: #eff5fb;
    z-index: 2;
    white-space: normal;
    visibility: hidden;
    filter: drop-shadow(rem(0) rem(4) rem(25) rgba(145, 201, 227, 0.28));
    pointer-events: none;
    opacity: 0;
    transition: .3s;

    &[data-popper-placement^='top'] > .tooltip__arrow {
      bottom: rem(-6);
    }
    
    &[data-popper-placement^='bottom'] > .tooltip__arrow {
      top: rem(-6);
    }
    
    &[data-popper-placement^='left'] > .tooltip__arrow {
      right: rem(-6);
    }
    
    &[data-popper-placement^='right'] > .tooltip__arrow {
      left: rem(-6);
    }

    // &.top {
    //   top: rem(-15);
    //   left: 50%;
    //   transform: translate(-50%, -100%);
      

    //   &:after {
    //     left: 50%;
    //     bottom: 0;
    //     transform: translate(-50%, 100%);
    //     border-top: rem(10) solid #fff;
    //   }
    // }

    // &.bottom {
    //   bottom: rem(-15);
    //   left: 50%;
    //   transform: translate(-50%, 100%);
      

    //   &:after {
    //     left: 50%;
    //     top: 0;
    //     transform: translate(-50%, -100%);
    //     border-bottom: rem(10) solid #fff;
    //   }
    // }

    // &.left {
    //   left: rem(-15);
    //   top: 50%;
    //   transform: translate(-100%, -50%);

    //   &:after {
    //     top: 50%;
    //     right: 0;
    //     transform: translate(100%, -50%);
    //     border-left: rem(10) solid #fff;
    //   }
    // }

    // &.right {
    //   right: rem(-15);
    //   top: 50%;
    //   transform: translate(100%, -50%);

    //   &:after {
    //     top: 50%;
    //     left: 0;
    //     transform: translate(-100%, -50%);
    //     border-right: rem(10) solid #fff;
    //   }
    // }
  }
}

