.popup-lease {
  max-width: rem(730);
  padding-top: 0;
  margin-top: rem(60);
  margin-bottom: rem(60);

  @include desktop-sm-down {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    max-width: 100%;
  }

  .popup__inner {
    width: 100%;
    padding: rem(25);
    padding-bottom: rem(60);
    box-shadow: rem(0) rem(4) rem(32) rgba(145, 201, 227, 0.4);
    border-radius: rem(30);

    @include desktop-sm-down {
      padding: 0 rem($container-padding-md) rem(100);
      border-radius: 0;
      box-shadow: none;
    }
  }

  .popup__title {
    font-weight: 800;
    font-size: rem(38);
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    position: relative;
    z-index: 5;
    margin-bottom: 0;

    @include desktop-sm-down {
      font-size: rem(24);
    }
  }

  &__head {
    padding: rem(45) rem(45) rem(55) rem(40);
    overflow: hidden;
    position: relative;
    background: #0194D8;
    border-radius: rem(30);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      padding: rem(35) rem(80) rem(55) rem($container-padding-md);
      border-radius: 0;
      margin: 0 rem(-$container-padding-md) rem(15);

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(15);
        background: #FFFFFF;
        border-radius: rem(15) rem(15) rem(0) rem(0);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    .blur-1 {
      width: rem(585);
      height: rem(568);
      border-radius: 50%;
      background: linear-gradient(180deg, #363BC6 0%, #1DBEF7 100%);
      filter: blur(rem(50));
      position: absolute;
      top: rem(-400);
      left: rem(477);
      transform: rotate(173.89deg);
      pointer-events: none;

      @include desktop-sm-down {
        width: rem(652);
        height: rem(626);
        left: rem(240);
        top: rem(-157);
      }
    }

    .blur-2 {
      width: rem(537);
      height: rem(522);
      border-radius: 50%;
      background: #10C3FF;
      filter: blur(rem(50));
      position: absolute;
      top: rem(-210);
      left: rem(-188);
      transform: rotate(116.13deg);
      pointer-events: none;

      @include desktop-sm-down {
        width: rem(418);
        height: rem(406);
        top: rem(30);
        left: rem(-145);
      }
    }

    .blur-3 {
      width: rem(652);
      height: rem(626);
      border-radius: 50%;
      background: linear-gradient(180deg, #363BC6 0%, rgba(97, 255, 246, 0.635417) 36.98%, rgba(54, 59, 198, 0) 100%);
      filter: blur(rem(50));
      position: absolute;
      top: rem(-262);
      left: rem(175);
      transform: rotate(175.92deg);
      pointer-events: none;
    }

    .blur-4 {
      width: rem(521);
      height: rem(354);
      border-radius: 50%;
      background: #00C2FC;
      filter: blur(rem(50));
      position: absolute;
      top: rem(-240);
      left: rem(530);
      transform: rotate(165.56deg);
      pointer-events: none;
    }

  }

  .carousel__button.is-close {
    display: none;
  }

  .btn-close {
    width: rem(40);
    height: rem(40);
    background: #EDF6FC;
    border-radius: rem(15);
    top: rem(40);
    right: rem(40);
    padding: 0;

    @include desktop-sm-down {
      top: rem(14);
      right: rem($container-padding-md);
    }

    &:hover {
      background: #E0F3FF;
    }

    .icon {
      font-size: rem(11);
      display: block;
    }
  }

}