.plan-legend {
  background: #FFFFFF;

  @include desktop-sm-up {
    display: inline-flex !important;
    transition: 0.5s;
    cursor: pointer;
    max-width: rem(100);
    align-items: center;
    overflow: hidden;
    border-radius: rem(30);
    position: relative;
    z-index: 5;
    padding: rem(25);
  }

  @include desktop-sm-down {
    display: none;
    position: absolute;
    width: 100%;
    top: rem(80);
    left: 0;
    max-width: 100%;
    flex-direction: column;
    padding: rem(30) rem(25);
    box-shadow: rem(0) rem(4) rem(14) rgba(145, 201, 227, 0.55);
    border-radius: rem(15);
  }


  &.active {
    max-width: rem(1530);
  }

  ul {
    display: flex;
    flex-shrink: 0;

    @include desktop-sm-down {
      flex-direction: column;
      width: 100%;
    }

    li {
      flex-shrink: 0;
      color: #91C9E3;
      display: flex;

      &:not(:last-child) {
        margin-right: rem(30);

        @include desktop-sm-down {
          margin-right: 0;
          margin-bottom: rem(20);
        }
      }
    }
  }


  &__ico {
    width: rem(50);
    height: rem(50);
    background: #F0F6FB;
    border-radius: rem(15);
    color: #91C9E3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(30);

    .icon-sex {
      font-size: rem(21);
    }

    .icon-baby {
      font-size: rem(11);
    }

    .icon-disabilities {
      font-size: rem(18);
    }

    .icon-escalator {
      font-size: rem(26);
    }

    .icon-parking2 {
      font-size: rem(25);
      background: #91C9E3;
      color: #fff;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(20);
    margin-bottom: rem(3);
  }

  &__desc {
    font-style: italic;
    font-size: rem(12);
    line-height: rem(15);
  }

  &__btn {
    display: flex;
    text-align: left;
    padding: 0 rem(20);
    height: rem(44);
    background: #DEE8EE;
    font-weight: 600;
    font-size: rem(12);
    line-height: 150%;
    color: #123063;
    margin-left: rem(120);

    @include desktop-sm-down  {
      font-size: 0;
      color: #123063;
      width: rem(40);
      height: rem(40);
      position: absolute;
      top: rem(5);
      right: rem(5);
      padding: 0;
      background: #EDF6FC;
      margin-left: 0;
    }

    &:hover {
      background: #00A3DA;
      color: #fff;
    }
    

    .icon-cross {
      font-size: rem(11);
      @include desktop-sm-up  {
        display: none;
      }
    }

    
  }
}