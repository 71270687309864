.rules-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: rem(570);

  &:not(:last-child) {
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin-bottom: rem(20);
    }
  }

  &__ico {
    width: rem(50);
    height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #123063;
    border-radius: rem(15);
    margin-right: rem(30);
    color: #fff;
    flex-shrink: 0;

    @include desktop-sm-down {
      width: rem(25);
      height: rem(25);
      margin-right: rem(10);
      border-radius: rem(5);
    }

    &_primary {
      background: #00a3da;
    }

    &_warn {
      background: #ef577f;
    }

    .icon-enter-right {
      font-size: rem(24);

      @include desktop-sm-down {
        font-size: rem(12);
      }
    }

    .icon-enter-left {
      font-size: rem(24);

      @include desktop-sm-down {
        font-size: rem(12);
      }
    }

    .icon-p-parking {
      font-size: rem(14);

      @include desktop-sm-down {
        font-size: rem(8);
      }
    }

    .icon-file {
      font-size: rem(26);

      @include desktop-sm-down {
        font-size: rem(12);
      }
    }

    .icon-soccer-ball {
      font-size: rem(23);

      @include desktop-sm-down {
        font-size: rem(14);
      }
    }

    .icon-ticket2 {
      font-size: rem(22);

      @include desktop-sm-down {
        font-size: rem(14);
      }
    }
  }

  &__main {
    min-width: rem(300);
    margin-right: rem(30);

    @include desktop-sm-down {
      min-width: 0;
      margin-right: auto;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(22);
    line-height: 1;
    letter-spacing: 0.02em;
    color: #123063;
    margin-bottom: rem(8);

    @include desktop-sm-down {
      font-size: rem(14);
      margin-bottom: 0;
    }
  }

  &__desc {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #9bc1d7;

    @include desktop-sm-down {
      font-size: rem(10);
    }
  }

  &__btn {
    height: rem(50);
    background-color: #fff;
    padding: 0 rem(35);
    color: #123063;
    width: rem(180);
    flex-shrink: 0;

    @include desktop-sm-down {
      width: rem(55);
      height: rem(26);
      padding: 0 rem(5);
      border-radius: rem(5);
      font-size: rem(10);
    }

    &_free {
      color: #3cc4a3;
    }
  }
}
