.about-section {
  margin: rem(50) 0;

  @include desktop-sm-down {
    background: #0D4D8F;
    border-radius: rem(15);   
    margin: rem(20) 0; 
    padding-bottom: rem(40);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .about-block,
  .article-swiper {
    margin: rem(50) 0;

    @include desktop-sm-down {
      margin-bottom: rem(40);
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .article-swiper {
    @include desktop-sm-down {
      padding: 0 rem($container-padding-md);
    }
  }

  .article-swiper .card-h {
    
    @include desktop-sm-up {
      height: rem(440);
    }
  }
}