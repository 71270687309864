.plaza-section {
  margin-top: rem(125);
  margin-bottom: rem(125);

  @include desktop-sm-down {
    margin-top: rem(50);
    margin-bottom: rem(40);
  }

  .section-head  {
    margin-bottom: rem(95);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }
  }

  .swiper-slide {
    width: rem(360);

    @include desktop-sm-down {
      width: rem(240);
    }
  }

  .more-swiper {
    + .plaza-swiper {
      margin-top: rem(125);

      @include desktop-sm-down {
        margin-top: rem(30);
      }
    }
  }
}

.plaza-swiper {
  max-width: rem(1051);
  width: 100%;
  margin: rem(125) auto;
  filter: drop-shadow(0 0 rem(150) #363BC6);

  @include desktop-sm-down {
    margin: rem(30) auto;
  }


  .swiper {
    overflow: visible;

    @include desktop-sm-down {
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: 100%;
    padding: 0 rem(38);
    box-sizing: border-box;

    @include desktop-sm-down {
      padding: 0;
    }
  }

  .card-h {
    height: auto;
    padding-top: 52.26%;
    border-radius: rem(500);

    @include desktop-sm-down {
      border-radius: rem(20);
      padding-top: 72%;
    }
    
  }

  .swiper-button-prev {
    left: rem(38);
    transform: translateX(-50%);

    @include desktop-sm-down {
      bottom: rem(15);
      transform: translateX(0);
      right: rem(43);
      left: auto;
      top: auto;
    }
  }

  .swiper-button-next {
    right: rem(38);
    transform: translateX(50%);

    @include desktop-sm-down {
      bottom: rem(15);
      transform: translateX(0);
      right: rem(12);
      left: auto;
      top: auto;
    }
  }
}