.hall-section {

  &:not(:last-child) {
    margin-bottom: rem(60);

    @include desktop-sm-down {
      margin-bottom: rem(40);
    }
  }
  

  .hall-info {
    margin: rem(55) 0;

    @include desktop-sm-down {
      margin: rem(25) 0;
    }
  }

  &__desc {
    margin: rem(55) 0;
    max-width: rem(880);

    @include desktop-sm-down {
      margin: rem(25) 0;
    }
  }

  .list-rhombus {
    margin: rem(55) 0;
    max-width: rem(600);

    @include desktop-sm-down {
      margin: rem(25) 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        font-size: rem(18);
        font-weight: 600;

        @include desktop-sm-down {
          width: 100%;
        }

        &:before {
          font-size: rem(20);
        }
      }
    }
  }

  .article-swiper {
    margin: rem(55) 0;
    
    @include desktop-sm-up {
      filter: drop-shadow(0 0 rem(150) rgba(#0D4D8F, 0.5));
    }

    @include desktop-sm-down {
      margin: rem(25) 0;
    }
  }
}