.lease-form {

  .col {
  
    @include desktop-sm-up {
      padding: 0 rem(3);
      margin-bottom: rem(20);
    }

    .input-wrap {
      margin-bottom: 0;

      .col.w-50 {
        @include desktop-sm-down {
          width: 50%;
        }
      }
    }
  }

  .row {
    @include desktop-sm-up {
     margin: 0 rem(-3) rem(-20);
    }
  }

  .input {
    

    @include desktop-sm-up {
      padding-left: rem(25);
    }

    &::placeholder {
      font-weight: 500;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    @include desktop-sm-up {
      padding-left: rem(25);
    }
  }

  .accordion-content {
    border-radius: rem(20);
    margin: rem(30) 0;

    @include desktop-sm-down {
      margin: rem(20) 0;
    }

    &__head {
      padding: rem(8);
      padding-left: rem(25);

      @include desktop-sm-down {
        padding: rem(15) rem(8) rem(15) rem(20);
      }
      
    }

    &__title {
      font-size: rem(16);

      @include desktop-sm-down {
        font-size: rem(14);
      }
    }

    &__btn {
      @include desktop-sm-down {
        width: rem(51);
        height: rem(51);

        .icon {
          font-size: rem(13);
        }
      }
    }

    &__inner {
      padding: rem(14) rem(60) rem(30) rem(25);

      @include desktop-sm-down {
        padding: 0 rem(20) rem(30) rem(20);
      }

      .col {
        padding: 0 rem(10);

        &.w-50 {
          @include desktop-sm-down {
            width: 50%;
          }
        }
      }

      

      .row {
        margin: 0 rem(-10) rem(-20);
      }
    }
  }

  .file-input {
    @include desktop-sm-down {
      margin: rem(10) 0;
    }
  }

  &__action-row  {
    margin: rem(30) 0;

    @include desktop-sm-down {
      margin: rem(25) 0;
    }
  }

  a {
    color: #0194d8;
    text-decoration: none;
    
    &:hover {
      color: #0194d8;
      text-decoration: underline;
    }
  }

  &__btn-submit {
    padding: 0 rem(50);
    background: #0194D8;

    @include tablets-sm-down {
      display: flex;
      width: 100%;
    }

    &:hover {
      background: #00A3DA;
    }
  }
}

