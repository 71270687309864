.b-hero-section {
  margin-bottom: rem(70);
  position: relative;
  z-index: 3;

  @include desktop-sm-down {
    margin-bottom: rem(25);
  }

  &__content {
    margin-top: rem(50);

    @include desktop-sm-down {
      margin-top: rem(25);
    }
  }

  &__head-cover {
    display: flex;
    align-items: center;
    margin-bottom: rem(20);
    justify-content: space-between;

    h1 {
      margin-bottom: 0;
    }

    .btn-type {
      padding: 0;
      background: #FFFFFF;
      width: rem(42);
      height: rem(42);
      color:  #123063;

      &:hover,
      &.active {
        background: #0194D8;
        color:  #fff;
      }

      &:not(:last-child) {
        margin-right: rem(15);
      }
  
      .icon {
        margin-right: 0;
        color: inherit;
  
        &-tile {
          font-size: rem(16);
        }
  
        &-list {
          font-size: rem(21);
        }
      }
    }
  }

  &_no-mb-mob {
    @include desktop-sm-down {
      margin-bottom: 0;
    }

    .breadcrumbs {
      @include desktop-sm-down {
        margin-bottom: 0;
      }
    }
  }

  
}

@import "includes/b-hero-section/filters-block_b";

.hero-page-tags {
  ul {
    margin-bottom: rem(-15);
    margin-right: rem(-15);
    display: flex;
    flex-wrap: wrap;

    @include desktop-sm-down {
      margin-bottom: rem(-20);
      margin-right: rem(-12);
    }

    li {
      margin-right: rem(15);
      margin-bottom: rem(15);

      @include desktop-sm-down {
        margin-right: rem(12);
        margin-bottom: rem(20);
      }
    }
  }
}

.page-tag {
  height: rem(44);
  padding: 0 rem(20);
  background: #DEE8EE;
  border-radius: rem(15);
  font-weight: 600;
  font-size: rem(12);
  line-height: 150%;
  color: #123063;

  @include desktop-sm-down {
    height: rem(40);
  }

  &:hover {
    background: #00a3da;
    color: #fff;
  }

  .icon {
    margin-right: rem(10);
    font-size: rem(16);
  }

  &_btn {
    background: #123063;
    color: #fff;

    &:hover {
      background: #1B407E;
    }

  }
}