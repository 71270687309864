.widget {
  background: #eff5fb;
  border-radius: rem(30);
  padding: rem(50);
  font-size: rem(18);
  line-height: 170%;
  color: #123063;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;


  @include desktop-sm-down {
    border-radius: rem(20);
    padding: rem(20) rem(18);
    position: static;
    font-size: rem(14);
  }

  &:not(:last-child) {
    margin-bottom: rem(40);

    @include desktop-sm-down {
      margin-bottom: rem(10);
    }
  }

  > div {
    &:not(:last-child) {
      margin-bottom: rem(30);

      @include desktop-sm-down {
        margin-bottom: rem(15);
      }
    }
  }

  &__logo {
    width: rem(90);
    height: rem(90);
    border-radius: 50%;
    position: absolute;
    top: rem(40);
    right: rem(50);
    overflow: hidden;
    margin-bottom: 0 !important;
    z-index: 2;

    @include desktop-sm-down {
      display: none;
      width: rem(60);
      height: rem(60);
      right: rem(165);
      top: rem(16);
    }

    img {
      width: 100%;
    }
  }

  h3 {
    font-weight: bold;
  }

  h4 {
    margin: 0;
  }

  h6 {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #9bc1d7;
    margin: 0;
    max-width: rem(330);

    @include desktop-sm-down {
      font-size: rem(10);
    }
  }

  a[href*="mailto"] {
    font-weight: bold;
    line-height: 150%;
    color: #00a3da;

    &:hover {
      color: #0194D8;
      text-decoration: underline;
    }
  }

  .socials {
    margin-top: rem(18);

    @include desktop-sm-down {
      margin-top: rem(24);
    }
    
    a {
      color: #123063;
      font-size: rem(30);
      border-radius: 50%;

      &:hover {
        color: #00a3da;

      }
    }
  }

  &.card-contact {
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;

    @include desktop-sm-up {
      padding: rem(40) rem(50);
      min-height: rem(270);
    }

    > div {
      &:nth-child(odd) {
        width: 55%;

        @include desktop-sm-down {
          width: 100%;
        }
      }

      &:nth-child(even) {
        width: 45%;
        padding-left: rem(30);

        @include desktop-sm-down {
          width: 100%;
          padding-left: 0;
        }
      }

      &:not(:last-child) {
        @include desktop-sm-up {
          margin-bottom: 0;
        }
      }

      &:not(:nth-child(1), :nth-child(2)) {
        margin-top: auto;

        @include desktop-sm-down {
          margin-top: 0;
        }
      }
    }

    a {
      text-decoration: none;
    }
  }

  &_b {
    background: #FFFFFF;
    border-radius: rem(40);
    font-size: rem(20);
    padding: rem(40);
    min-height: rem(190);

    @include desktop-sm-down {
      padding: rem(30) rem(24);
      border-radius: rem(20);
      min-height: 0;
      font-size: rem(16);
    }

    b {
      font-size: rem(22);

      @include desktop-sm-down {
        font-size: inherit;
      }
    }

    h6 {
      color: #C2DBE7;
      font-style: italic;
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(23);
      margin-bottom: rem(10);

      @include desktop-sm-down {
        font-size: rem(12);
        line-height: 130%;
      }
    }


    .widget__site {
      color: #0194D8;
      font-weight: 600;

      &:hover {
        
        color: #00A3DA;
        text-decoration: underline;
      }
    }

    .socials  {
      margin-top: 0;
      
      a {
        color: #0194D8;
        background: #DEE8EE;

        &:hover {
          background: #0194D8;
          color: #FFF;
        }

      }

    }

    .widget__logo {
      width: rem(126);
      height: rem(126);
      top: rem(28);
      right: rem(50);
    }

    
  }
}

.widget-group {
  .widget {
    &:not(:last-child) {
      margin-bottom: rem(10);

      @include desktop-sm-down {
        margin-bottom: rem(16);
      }
    }
  }
}

.nav-widget {
  padding: rem(35) rem(50);

  @include desktop-sm-down {
    display: none;
  }

  ul {
    li {
      color: #00A3DA;
      font-weight: 600;
      font-size: rem(18);
      line-height: 110%;

      &:not(:first-child, :last-child) {
        a {
          padding: rem(25) 0;
        }
      }

      &:not(:first-child) {
        a {
          padding-top: rem(25);
        }
      }

      &:not(:last-child) {
        border-bottom: rem(2) solid #FAFDFF;

        a {
          padding-bottom: rem(25);
        }
      }

      &.active {
        color: #9BC1D7;
      }

      a {
        display: block;
      }
    }
  }

  .btn {
    margin-top: rem(40);
    width: 100%;
    font-size: rem(14);
  }
}

.widget-event {
  background: #FFFFFF;
  box-shadow: rem(0) rem(4) rem(39) rgba(155, 193, 215, 0.23);
  border-radius: rem(30);
  overflow: hidden;

  &__inner {
    padding: 0 rem(40);
  }

  &__main {
    padding: rem(30) 0;
  }

  &__title  {
    font-weight: bold;
    font-size: rem(24);
    line-height: 130%;
    color: #123063;
    margin-bottom: rem(20);
  }

  &__list {
    li {
      font-size: rem(18);
      line-height: 120%;
      color: #123063;
      display: flex;
      align-items: flex-start;

      &.disabled {
        opacity: 0.5;
      }

      .icon-calendar {
        font-size: rem(17);
        color: #00A3DA;
        margin-right: rem(10);
      }

      .dot {
        color: #00A3DA;
        display: inline-block;
        margin: 0 rem(10);
      }

      .date {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      .time {
        align-self: flex-start;
        white-space: normal;
      }

      b {
        font-weight: bold;
        color: #123063;
      }

      &:not(:last-child) {
        margin-bottom: rem(15);
      }
    }
  }

  &__separate {
    color: #00A3DA;
    margin-bottom: rem(20);
    display: flex;
    align-items: center;

    .rhombus {
      &:not(:last-child) {
        margin-right: rem(8);
      }
    }

  }

  &__info {
    padding: rem(20) 0;
    background: #EDF6FC;
  }

  &__info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info-item  {
    font-size: rem(20);
    line-height: 140%;
    color: #123063;
  }

  &__info-title {
    font-weight: bold;
    font-size: rem(14);
    line-height: 140%;
    color: #00A3DA;
  }

  &__info-separate {
    width: rem(2);
    height: rem(36);
    background-color: #D0E4F0;
    flex-shrink: 0;
  }

  &__footer  {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: rem(30) 0;
    background: #123063;
    font-weight: 600;
    font-size: rem(18);
    line-height: rem(23);
    color: #FFFFFF;

    &:hover {
      background: #102853;
    }

    .icon {
      font-size: rem(16);
      margin-right: rem(10);
    }
  }
}

.contact-widget {
  
  @include desktop-sm-up {
    min-height: rem(410);
  }
}

.tickets-location {
  #cash-registers-map {
    height: rem(210);
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}