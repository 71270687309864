.request-form {
  width: 100%;

  &__row {
    display: flex;
    margin: 0 rem(-15) rem(-30);

    @include desktop-sm-down {
      flex-direction: column;
      margin-bottom: rem(-10);
    }
  }

  &__col {
    padding: 0 rem(15);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin-bottom: rem(10);
    }

    &:nth-child(1) {
      width: 36.5%;

      @include desktop-sm-down {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 36.5%;

      @include desktop-sm-down {
        width: 100%;
      }
    }
    
    &:nth-child(3) {
      width: 25%;

      @include desktop-sm-down {
        width: 100%;
      }
    }
  }

  &__input {
    background: #FFFFFF;
    border-radius: rem(15);
    position: relative;

    &.error {
      border-color: #EF577F;
    }
  }

  input {
    padding: 0 rem(50);
    padding-right: rem(70);
    width: 100%;
    height: rem(75);
    border: none;
    outline: none;
    font-family: inherit;
    font-weight: 600;
    font-size: rem(18);
    background: none;

    @include desktop-sm-down {
      font-size: rem(12);
      padding: 0 rem($container-inner-padding-md);
      padding-right: rem(30);
      height: rem(50);
    }

    &::placeholder {
      opacity: 1;
      color: #a2a6d4;
    }
  }

  &__btn-clean {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: rem(70);
    align-items: center;
    justify-content: center;

    @include desktop-sm-down {
      width: rem(30);
    }

    .icon {
      font-size: rem(13);
      color: #89b6e3;
    }
  }

  &__button {
    height: rem(75);
    display: flex;
    width: 100%;

    @include desktop-sm-down {
      width: 100%;
      height: rem(50);
      margin-top: rem(4);
      font-size: rem(12);
    }
  }

  &__desc {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #5A7EBB;
    margin-top: rem(20);

    a {
      color: #0194D8;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__message {
    padding: rem(15) rem(30);
    padding-top: rem(14);
    border-radius: rem(15);
    position: absolute;
    top: rem(-11);
    left: 50%;
    transform: translate(-50%, -100%);
    font-weight: 600;
    font-size: rem(12);
    line-height: 1;
    text-align: center;
    color: #ffffff;
    display: none;
    white-space: nowrap;

    &:after {
      content: "";
      border: rem(5) solid transparent;
      border-top: rem(5) solid transparent;
      display: block;
      position: absolute;
      bottom: rem(1);
      left: 50%;
      transform: translate(-50%, 100%);
    }

    &.error {
      background: #ef577f;

      &:after {
        border-top-color: #ef577f;
      }
    }
  }
}

.subscribe-answer {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: rem(18);
  color: #ffffff;

  @include desktop-sm-down {
    flex-direction: column;
    text-align: center;
    font-size: rem(14);
  }

  img {
    width: rem(141);
    margin: rem(-15) 0;
    margin-right: rem(50);

    @include desktop-sm-down {
      width: rem(100);
      margin-right: 0;
      margin: 0;
      margin-bottom: rem(15);
    }
  }
}

.container_sm {
  .request-form {
    &__row {
      @include desktop-sm-up {
        margin: 0 rem(-10) rem(-20);
      }
    }

    &__col {
      @include desktop-sm-up {
        padding: 0 rem(10);
        margin-bottom: rem(20);
      }
    }
    input {
      @include desktop-sm-up {
        height: rem(60);
      }
    }

    &__button {
      @include desktop-sm-up {
        height: rem(60);
      }
    }
  }  
}