.home-blur-1 {
  pointer-events: none;
  width: rem(481);
  height: rem(461);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(150));
  position: absolute;
  left: rem(770);
  top: rem(1015);
  z-index: 3;

  @include desktop-sm-down {
    width: rem(80);
    height: rem(80);
    top: rem(580);
    left: 30%;
  }
}

.home-blur-2 {
  pointer-events: none;
  width: rem(796);
  height: rem(796);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(150));
  position: absolute;
  left: rem(1146);
  top: rem(884);
  z-index: 3;

  @include desktop-sm-down {
    width: rem(132);
    height: rem(128);
    top: rem(525);
    left: 50%;
  }
}

.blur-main {
  pointer-events: none;
  width: rem(811);
  height: rem(131);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(54));
  position: absolute;
  left: rem(839);
  bottom: rem(25);
  transform: translateY(100%);

  @include desktop-sm-down {
    width: rem(285);
    height: rem(131);
    bottom: rem(-18);
    left: auto;
    right: rem(-140);
    transform: translateY(0);
  }
}
  
.blur-1 {
  pointer-events: none;
  width: rem(594);
  height: rem(391);
  border-radius: 50%;
  background: rgba(0, 218, 179, 0.67);
  filter: blur(rem(50));
  position: absolute;
  left: rem(-145);
  top: rem(495);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-2 {
  pointer-events: none;
  width: rem(829);
  height: rem(524);
  border-radius: 50%;
  background: #fcff82;
  filter: blur(rem(54));
  position: absolute;
  left: rem(490);
  top: rem(376);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-3 {
  pointer-events: none;
  width: rem(500);
  height: rem(316);
  border-radius: 50%;
  background: rgba(0, 218, 179, 0.67);
  filter: blur(rem(54));
  position: absolute;
  left: rem(1030);
  top: rem(429);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-4 {
  pointer-events: none;
  width: rem(350);
  height: rem(350);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(54));
  position: absolute;
  left: rem(-270);
  top: rem(-186);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-5 {
  pointer-events: none;
  width: rem(580);
  height: rem(580);
  border-radius: 50%;
  background: #b182ff;
  filter: blur(rem(54));
  position: absolute;
  left: rem(-68);
  top: rem(-290);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-6 {
  pointer-events: none;
  width: rem(580);
  height: rem(580);
  border-radius: 50%;
  background: #b182ff;
  filter: blur(rem(54));
  position: absolute;
  left: rem(880);
  top: rem(313);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-7 {
  pointer-events: none;
  width: rem(1080);
  height: rem(694);
  border-radius: 50%;
  background: #4a36c6;
  filter: blur(rem(90));
  position: absolute;
  left: rem(904);
  top: rem(380);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-8 {
  pointer-events: none;
  width: rem(622);
  height: rem(452);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(53));
  position: absolute;
  left: rem(1366);
  top: rem(510);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-9 {
  pointer-events: none;
  width: rem(710);
  height: rem(677);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(150));
  position: absolute;
  left: rem(-700);
  top: rem(309);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-10 {
  pointer-events: none;
  width: rem(783);
  height: rem(677);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(150));
  position: absolute;
  left: rem(1330);
  top: rem(-255);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-11 {
  pointer-events: none;
  width: rem(1112);
  height: rem(712);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(200));
  position: absolute;
  left: rem(500);
  top: rem(604);

  @include desktop-sm-down {
    width: rem(285);
    height: rem(131);
    filter: blur(rem(50));
    left: rem(200);
    top: rem(194);
  }
}

.blur-12 {
  pointer-events: none;
  width: rem(1080);
  height: rem(694);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(140));
  position: absolute;
  left: rem(515);
  top: rem(-37);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-13 {
  pointer-events: none;
  width: rem(1080);
  height: rem(694);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(140));
  position: absolute;
  left: rem(515);
  top: rem(-37);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-14 {
  pointer-events: none;
  width: rem(908);
  height: rem(680);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(200));
  position: absolute;
  left: rem(-400);
  top: rem(-155);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-15 {
  pointer-events: none;
  width: rem(1080);
  height: rem(715);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(250));
  position: absolute;
  left: rem(841);
  top: rem(130);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-16 {
  pointer-events: none;
  width: rem(876);
  height: rem(842);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(100));
  position: absolute;
  left: rem(1032);
  top: rem(85);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-17 {
  pointer-events: none;
  width: rem(1484);
  height: rem(1532);
  border-radius: 50%;
  background: #4A36C6;
  filter: blur(rem(140));
  position: absolute;
  left: rem(773);
  top: rem(796);

  @include desktop-sm-down {
    display: none;
  }
}

.blur-18 {
  pointer-events: none;
  width: rem(621);
  height: rem(725);
  border-radius: 50%;
  background: rgba(16, 195, 255, 0.67);
  filter: blur(rem(50));
  position: absolute;
  left: rem(1366);
  top: rem(818);

  @include desktop-sm-down {
    display: none;
  }
}