.file-link {
  padding: rem(20) rem(30);
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: rem(2) solid #EFF5FB;
  border-radius: rem(15);
  transition: .3s;
  margin: rem(10) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include desktop-sm-down {
    padding: rem($container-inner-padding-md);
  }

  &:hover {
    background: #F3F8FB;

    .file-link__btn {
      background: #00A3DA;
      color: #fff;
    }
  }

  &__ico {
    background: #EDF6FC;
    border-radius: rem(15);
    width: rem(40);
    height: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #123063;
    margin-right: rem(30);
    flex-shrink: 0;

    @include desktop-sm-down {
      width: rem(25);
      height: rem(25);
      margin-right: rem(15);
      display: none;
    }

    .icon-file2 {
      font-size: rem(17);

      @include desktop-sm-down {
        font-size: rem(14);
      }
    }

    .icon-parking {
      font-size: rem(14);

      @include desktop-sm-down {
        font-size: rem(11);
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #00A3DA;
    margin-right: rem(15);

    @include desktop-sm-down {
      font-size: rem(15);
    }
  }

  &__btn {
    height: rem(40);
    padding: 0 rem(40);
    background: #E0F2FF;
    color: #00A3DA;
    margin-left: auto;
    font-size: rem(14);
    flex-shrink: 0;

    @include desktop-sm-down {
      font-size: 0;
      width: rem(40);
      padding: 0;
    }

    .icon-download {
      font-size: rem(12);

      @include desktop-sm-up {
        display: none;
      }
    }
  }
}