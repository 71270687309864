.accordion-content {
  background: #eff5fb;
  border-radius: rem(30);
  color: #123063;

  @include desktop-sm-down {
    border-radius: rem(15);
  }

  &:not(:last-child) {
    margin-bottom: rem(20);

    @include desktop-sm-down {
      margin-bottom: rem(10);
    }
  }

  &.active {
    .accordion-content__btn {
      background: #00a3da;
      color: #fff;

      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &_primary {
    background: #00a3da;
    color: #fff;

    .accordion-content__btn {
      &:hover {
        background: #123063;
      }
    }

    &.active {
      .accordion-content__btn {
        background: #123063;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(28) rem(25);
    padding-left: rem(50);
    cursor: pointer;

    @include desktop-sm-down {
      padding: rem(15) rem(8) rem(15) rem($container-inner-padding-md);
    }

    &:hover {
      .accordion-content__btn {
        background: #00a3da;
        color: #fff;
      }
    }
  }

  &_secondary {
    background: #E4F0F8;
  }


  &__title {
    font-weight: 600;
    font-size: rem(22);
    line-height: 110%;
    margin-right: rem(140);

    @include desktop-sm-down {
      font-size: rem(15);
      margin-left: 0;
      margin-right: rem(10);
    }
  }

  &__btn {
    margin-left: auto;
    width: rem(54);
    height: rem(54);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194d8;
    background: #ffffff;
    border-radius: rem(15);
    cursor: pointer;
    flex-shrink: 0;
    transition: 0.3s;
    align-self: flex-start;

    @include desktop-sm-down {
      width: rem(34);
      height: rem(34);
    }

    &:hover {
      background: #00a3da;
      color: #fff;
    }

    .icon {
      font-size: rem(16);

      @include desktop-sm-down {
        font-size: rem(10);
      }
    }
  }

  &__main {
    display: none;
  }

  &__inner {
    padding: rem(15) rem(50) rem(50);
    font-size: rem(18);
    line-height: 150%;

    @include desktop-sm-down {
      font-size: rem(12);
      padding: rem(4) rem(17) rem(26);
    }
    p {
      a {
        color: #0194d8;
      }
    }

    
  }
}
