&__img-1 {
  position: absolute;
  top: rem(194);
  left: rem(1097);
  flex-shrink: 0;
  width: rem(365);
  transform: rotate(-16.6deg);
  z-index: 1;

  @include desktop-sm-down {
    transform: rotate(-36.43deg);
    left: auto;
    right: rem(-55);
    top: rem(98);
    width: rem(130);
  }
}

&__img-2 {
  position: absolute;
  top: rem(242);
  left: rem(885);
  flex-shrink: 0;
  width: rem(440);
  transform: rotate(-6.31deg);
  z-index: 1;

  @include desktop-sm-down {
    transform: rotate(-6.31deg);
    left: auto;
    right: rem(-65);
    top: rem(85);
    width: rem(248);
  }
}

&__img-3 {
  position: absolute;
  top: rem(-23);
  left: rem(819);
  flex-shrink: 0;
  width: rem(1334);
  z-index: 1;

  @include desktop-sm-down {
    transform: rotate(-6.31deg);
    left: auto;
    right: rem(-65);
    top: rem(85);
    width: rem(248);
  }
}

&__img-4 {
  position: absolute;
  top: rem(160);
  left: rem(870);
  flex-shrink: 0;
  width: rem(1232);
  z-index: 1;

  @include desktop-sm-down {
    display: none;
  }
}

&__img-5 {
  position: absolute;
  top: rem(242);
  left: rem(937);
  flex-shrink: 0;
  width: rem(605);
  z-index: 2;

  @include desktop-sm-down {
    display: none;
  }
}

&__img-6 {
  position: absolute;
  top: rem(93);
  left: rem(1088);
  flex-shrink: 0;
  width: rem(430);
  z-index: 2;

  @include desktop-sm-down {
    display: none;
  }
}

&__img-7 {
  position: absolute;
  top: rem(246);
  left: rem(1239);
  flex-shrink: 0;
  width: rem(520);
  z-index: 2;

  @include desktop-sm-down {
    display: none;
  }
}