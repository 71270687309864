.cashbox-block {
  padding: rem(45) rem(50);
  background: #EFF5FB;
  border-radius: rem(30);
  margin-top: rem(35);
  margin-bottom: rem(100);

  @include desktop-sm-down {
    padding: rem(24) rem($container-inner-padding-md);
    border-radius: rem(20);
    font-size: rem(14);
    margin-bottom: rem(40);
    margin-top: rem(45);
  }

  &__head {
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin-bottom: rem(20);
    }
  }

  &__title  {
    h3 {
      margin-bottom: rem(6);
    }
  }

  &__desc {
    font-weight: bold;
    font-size: rem(14);
    line-height: 150%;
    color: #9BC1D7;

    @include desktop-sm-down {
      font-size: rem(10);
      line-height: 120%;
    }
  }

  &__map {
    border-radius: rem(30);
    overflow: hidden;
    position: relative;
    height: rem(210);
    margin-bottom: rem(35);

    @include desktop-sm-down {
      height: rem(125);
      margin-bottom: rem(20);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 0 rem(-15) rem(-30);

    @include desktop-sm-down {
      flex-wrap: wrap;
      margin-bottom: rem(-17);
    }
  }

  &__col {
    padding: 0 rem(15);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      width: 50%;
      margin-bottom: rem(17);
    }

    @include tablets-down {
      width: 100%;
    }
  }

  &__label {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #9BC1D7;
    margin-bottom: rem(8);
    display: flex;

    @include desktop-sm-down {
      font-size: rem(12);
      margin-bottom: rem(5);
    }

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: rem(22);
      }

      &::before {
        content: "•";
        display: inline-block;
        font-size: rem(30);
        margin-right: rem(7);
        position: relative;
        top: rem(-2);

        @include desktop-sm-down {
          font-size: rem(20);
        }
      }

      &:nth-child(1) {
        &::before {
          color: #48b85e;
        }
      }
    }
  }

  .btn {
    padding: 0 rem(70);

    @include desktop-sm-down {
      display: flex;
      width: 100%;
      height: rem(50);
    }
  }

}