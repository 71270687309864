@mixin desktop-lg-down {
  @media screen and (max-width: $desktop-lg-down) {
    @content;
  }
}

@mixin desktop-lg-up {
  @media screen and (min-width: $desktop-lg-up) {
    @content;
  }
}

@mixin desktop-down {
  @media screen and (max-width: $desktop-down) {
    @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: $desktop-up) {
    @content;
  }
}

@mixin desktop-sm-down {
  @media screen and (max-width: $desktop-sm-down) {
    @content;
  }
}

@mixin desktop-sm-up {
  @media screen and (min-width: $desktop-sm-up) {
    @content;
  }
}

@mixin tablets-lg-down {
  @media screen and (max-width: $tablets-lg-down) {
    @content;
  }
}

@mixin tablets-lg-up {
  @media screen and (min-width: $tablets-lg-up) {
    @content;
  }
}

@mixin tablets-down {
  @media screen and (max-width: $tablets-down) {
    @content;
  }
}

@mixin tablets-up {
  @media screen and (min-width: $tablets-up) {
    @content;
  }
}

@mixin tablets-sm-down {
  @media screen and (max-width: $tablets-sm-down) {
    @content;
  }
}

@mixin tablets-sm-up {
  @media screen and (min-width: $tablets-sm-up) {
    @content;
  }
}

@mixin phones-lg-down {
  @media screen and (max-width: $phones-lg-down) {
    @content;
  }
}

@mixin phones-lg-up {
  @media screen and (min-width: $phones-lg-up) {
    @content;
  }
}

@mixin phones-down {
  @media screen and (max-width: $phones-down) {
    @content;
  }
}

@mixin phones-up {
  @media screen and (min-width: $phones-up) {
    @content;
  }
}

@mixin phones-sm-down {
  @media screen and (max-width: $phones-sm-down) {
    @content;
  }
}

@mixin phones-sm-up {
  @media screen and (min-width: $phones-sm-up) {
    @content;
  }
}

@mixin custom-down($prop) {
  @media screen and (max-width: $prop) {
    @content;
  }
}

@mixin custom-up($prop) {
  @media screen and (min-width: $prop) {
    @content;
  }
}
