.card-f2 {
  display: block;
  background: #FFFFFF;
  border-radius: rem(40);
  overflow: hidden;
  width: 100%;
  transition: .3s;
  padding: rem(20) rem(18) rem(10);
  box-sizing: border-box;

  @include desktop-sm-down {
    padding: rem(15) rem(14) 0;
    border-radius: rem(20);
  }

  &:hover {
    // filter: drop-shadow(rem(0) rem(4) rem(50) #CADEEA);
    .card-f2__img img {
      transform: scale(1.2);
    }
  }

  &__head {
    position: relative;
  }

  &__img {
    padding-top: 62%;
    position: relative;
    border-radius: rem(30);
    overflow: hidden;

    @include desktop-sm-down {
      padding-top: 59.5%;
      border-radius: rem(20);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__logo {
    position: absolute;
    top: rem(15);
    left: rem(15);
    display: block;
    width: rem(50);
    border-radius: 50%;
    overflow: hidden;

    @include desktop-sm-down {
      width: rem(45);
      top: rem(10);
      left: rem(10);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__main {
    padding: rem(30) rem(15);

    @include desktop-sm-down {
      padding: rem(22) rem(10);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 130%;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(16);
    }
  }

  a.card-f3__title {
    position: static;
    
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__desc {
    font-size: rem(14);
    line-height: rem(18);
    color: #91C9E3;
    font-style: italic;
    font-weight: 600;
    margin-bottom: rem(15);

    @include desktop-sm-down {
      font-size: rem(12);
      margin-bottom: rem(10);
    }
  }

  a {
    position: relative;
    z-index: 2;
  }
}
