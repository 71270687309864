&.fixed {

    .container {
      padding-left: rem($container-padding-lg);
      padding-right: rem($container-padding-lg);
    }

    .header__inner {
      filter: drop-shadow(0 0 rem(25) rgba(155, 193, 215, 0.55));
      max-width: rem(1625);
    }

    .header__top-inner {
      height: rem(50);
    }

    .header__top-nav ul li a {
      height: rem(50);
      font-size: rem(14);
    }

    .header__top-nav ul li a img {
      width: rem(29);
      margin-right: rem(20);
    }

    .header__search-btn {
      width: rem(22);
      height: rem(23);

      .icon {
        font-size: rem(22);
      }
    }

    .header__main-inner {
      height: rem(90);
    }

    .header__logo {
      max-width: rem(172);
    }

    .header__nav ul li a {
      font-size: rem(14);
    }

    .header__btn-entry {
      height: rem(44);
      font-size: rem(14);
    }

    .header__btn-lang {
      height: rem(44);
      width: rem(44);
      font-size: rem(14);
    }

    .header__sm-btn {
      height: rem(44);
      width: rem(44);
    }

    .icon-street-map {
      font-size: rem(16);
    }
  
    .icon-p-parking {
      font-size: rem(10);
    }
    
    img[src*="map-marker2.svg"] {
      width: rem(16);
    }

    svg.map-marker2 {
      width: rem(16);
    }

  }