*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 16px;

  @media (max-width: 1590px) {
    font-size: 14px;
  }

  @media (max-width: 1390px) {
    font-size: 12px;
  }

  @media (max-width: 1190px) {
    font-size: 10px;
  }

  @include desktop-sm-down {
    font-size: 16px;
  }
}

html,
body {
  max-width: 100%;
  box-sizing: border-box;
}

body {
  min-width: rem(320);
  font-family: "VTB", sans-serif;
  font-weight: normal;
  color: #123063;
  background: #fff;
  margin: 0;
  line-height: 150%;

  &.b-body {
    background: #F0F6FB;
  }
}

img {
  display: block;
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h5 {
  font-weight: 600;
}

h1,
h2 {
  font-size: rem(48);
  line-height: 150%;
  margin: rem(20) 0;
  font-weight: 700;

  @include desktop-sm-down {
    font-size: rem(26);
    line-height: 130%;
  }

  &:first-child {
    margin-top: 0;
  }
}

h3 {
  font-size: rem(26);
  line-height: 130%;
  margin: rem(20) 0;

  @include desktop-sm-down {
    font-size: rem(20);
    line-height: 120%;
  }

  &:first-child {
    margin-top: 0;
  }

}

h4 {
  font-size: rem(22);
  line-height: 130%;
  margin: rem(20) 0;

  @include desktop-sm-down {
    font-size: rem(18);
    line-height: 120%;
  }

  &:first-child {
    margin-top: 0;
  }
}

h5 {
  font-size: rem(20);
  line-height: 130%;
  margin: rem(20) 0;

  @include desktop-sm-down {
    font-size: rem(14);
    line-height: 120%;
  }

  &:first-child {
    margin-top: 0;
  }
}

.lg {
  h1,
  h2 {
    font-size: rem(72);

    @include desktop-sm-down {
      font-size: rem(30);
    }
  }

  h3 {
    @include desktop-sm-up {
      font-size: rem(36);
      line-height: 120%;
    }
  }
}

.xl {
  h1,
  h2 {
    font-weight: 700;
    font-size: rem(96);
    line-height: 170%;

    @include desktop-sm-down {
      font-weight: 600;
      font-size: rem(28);
      line-height: 120%;
    }
  }
}

p {
  font-size: rem(18);
  line-height: 150%;
  margin: rem(20) 0;

  @include desktop-sm-down {
    font-size: rem(14);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-weight: 600;
    color: #00a3da;
  
    &:hover {
      color: #0194D8;
      text-decoration: underline;
    }
  }

  b {
    font-weight: 600;
  }
}

b {
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

// video[autoplay]::-webkit-media-controls-panel {}
// video[autoplay]::-webkit-media-controls-play-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-volume-slider-container {}
// video[autoplay]::-webkit-media-controls-volume-slider {}
// video[autoplay]::-webkit-media-controls-mute-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-timeline {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-current-time-display {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-timeline-container {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-time-remaining-display {}
// video[autoplay]::-webkit-media-controls-seek-back-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-seek-forward-button {}
// video[autoplay]::-webkit-media-controls-fullscreen-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-picture-in-picture-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-rewind-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-return-to-realtime-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-media-controls-toggle-closed-captions-button {
//   display: none!important;
//   -webkit-appearance: none;
// }
// video[autoplay]::-webkit-full-page-media::-webkit-media-controls-panel {
//   display: none!important;
//   -webkit-appearance: none;
// }