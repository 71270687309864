.plan-scheme {

  &__img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    @include desktop-sm-down {
      width: 200%;

      &:not(.ui-draggable-handle) {
        transform: translate(-50%, -50%);
      }
    }

    > svg {
      width: 100%;
      height: 100%;
      display: block;
    }

    path, rect {
      transition: .3s;
      cursor: pointer;

      &:hover {
        fill: #00a3da;
      }
    }

    &[data-cat*="cat-1"] {
      #section-7,
      #section-8,
      #section-9, 
      #section-10,
      #section-11,
      #section-12,
      #section-13,
      #section-14,
      #section-36,
      #section-35,
      #section-31,
      #section-71,
      #section-70 {
        fill:  #3056CC;
      }
    }
  
    &[data-cat*="cat-2"] {
      #section-15,
      #section-16,
      #section-17,
      #section-18, 
      #section-19,
      #section-20,
      #section-21,
      #section-22,
      #section-23,
      #section-24 {
        fill:  #F1C044;
      }
    }

    &[data-cat*="cat-3"] {
      #section-73,
      #section-72,
      #section-69, 
      #section-67,
      #section-68,
      #section-38,
      #section-37,
      #section-34,
      #section-33,
      #section-32 {
        fill:  rgba(48, 86, 204, 0.8);
      }
    }

    &[data-cat*="cat-4"] {
      #section-25,
      #section-26,
      #section-27, 
      #section-28,
      #section-29,
      #section-30,
      #section-46,
      #section-47,
      #section-48 {
        fill:  #117ED3;
      }
    }

    &[data-cat*="cat-5"] {
      #section-49,
      #section-50,
      #section-51, 
      #section-52,
      #section-53,
      #section-54,
      #section-55,
      #section-56,
      #section-57,
      #section-Ф0,
      #section-Ф1,
      #section-Ф2,
      #section-Ф3,
      #section-Ф4,
      #section-Ф5,
      #section-Ф6,
      #section-Ф7,
      #section-Ф8,
      #section-Ф9,
      #section-Ф10,
      #section-Ф11,
      #section-Ф12,
      #section-Ф13 {
        fill:  #BEEF57;
      }
    }

    &[data-cat*="cat-6"] {
      #section-58,
      #section-59,
      #section-60, 
      #section-61,
      #section-62,
      #section-63,
      #section-64,
      #section-65,
      #section-66,
      #section-39,
      #section-40,
      #section-41, 
      #section-42,
      #section-43,
      #section-44,
      #section-45 {
        fill:  #F25C4C;
      }
    }

    &[data-cat*="cat-7"] {
      #section-1, 
      #section-2,
      #section-3, 
      #section-1,
      #section-5, 
      #section-6,
      #section-74,
      #section-75,
      #section-76 {
        fill:  #123F8A;
      }
    }
  }

  &__tooltip {
    position: absolute;
    padding: rem(17) rem(15);
    background: #FFFFFF;
    border-radius: rem(15);
    transform: translate(-45%, -150%);
    // opacity: 0;
    margin-top: rem(-25);
    transition: all .3s ease;


    .icon-chevron-right {
      font-size: rem(5);
      margin-left: rem(15);
    }

    &::after {
      content: "";
      display: block;
      border: rem(10) solid transparent; 
      border-top: rem(10) solid #fff;
      position: absolute;
      bottom: rem(-19);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__tooltip-title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 120%;
    color: #123063;
  }

  &__tooltip-link {
    display: inline-block;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #0194D8;
    white-space: nowrap;
    margin-top: rem(10);
  }
  


}