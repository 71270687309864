.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @include desktop-sm-down {
    display: none;
  }

  &__inner {
    position: relative;
    background: #fff;
    border-radius: 0 0 rem(15) rem(15);
    overflow: hidden;
    max-width: rem(1530);
    margin: 0 auto;
    transition: .3s;
  }

  .container {
    padding-left: rem(30);
    padding-right: rem(30);
    transition: .3s;
  }

  @import "includes/header/fixed";
  
  @import "includes/header/header__top";
  @import "includes/header/header__main";
  @import "includes/header/header__main-sub";
  @import "includes/header/header__search";
}

@import "includes/header/header_b-header";

