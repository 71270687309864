.mob-search {
  position: fixed;
  top: rem($header-h-m);
  bottom: 0;
  overflow: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  padding-bottom: rem($bottomNav-h);
  z-index: 95;
  display: none;

  @include desktop-sm-up {
    display: none !important;
  }

  &__head {
    border-top: rem(3) solid transparent;
    border-bottom: rem(3) solid #e7f1fb;
    display: flex;
    align-items: center;
  }

  &__input {
    flex-grow: 1;
    font-family: inherit;
    font-size: rem(14);
    color: #123063;
    font-weight: 600;
    background: none;
    border: none;
    outline: none;
    height: rem(65);

    &::placeholder {
      opacity: 1;
      color: #9bc1d7;
    }
  }

  &__input-ico {
    margin-left: rem(10);
    margin-right: rem(20);
    margin-top: rem(5);

    .icon {
      font-size: rem(18);
    }
  }

  &__main {
    padding: rem(25) 0;
    display: none;
  }

  &__search-result {
    li {
      &:not(:last-child) {
        margin-bottom: rem(20);
      }

      a {
        * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    h5 {
      font-weight: 600;
      font-size: rem(16);
      line-height: 120%;
      color: #0194d8;
      margin-bottom: rem(16);
      margin-top: 0;
    }

    p {
      font-size: rem(12);
      line-height: 150%;
      color: #123063;
      margin: 0;
    }
  }
}
