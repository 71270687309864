.parking-section {
  &__section {
    margin-top: rem(55);

    @include desktop-sm-down {
      margin-top: rem(30);
    }

    h3 {
      margin-top: 0;
      margin-bottom: rem(30);
      line-height: 130%;

      @include desktop-sm-down {
        margin-bottom: rem(25);
        font-size: rem(24);
      }
    }
  }

  &__title {
    margin-top: rem(75);
    margin-bottom: rem(45);
    max-width: rem(770);

    @include desktop-sm-down {
      margin-top: rem(40);
      margin-bottom: rem(25);
    }

    h2 {
      font-weight: 600;

      @include desktop-sm-down {
        font-size: rem(30);
      }

      b {
        font-weight: 700;
      }
    }
  }

  @import "includes/parking-section/&__time-block";
  @import "includes/parking-section/&__rules";

  &_b {

    h3 {
      font-weight: bold;
    }

    .parking-section__time-block {
      background: #E4F0F8;
      border-radius: rem(40);
      padding: rem(50);
      margin-bottom: rem(20);

      @include desktop-sm-down {
        padding: rem(40) rem($b-container-inner-padding-md);
        border-radius: rem(20);
      }
    }

    .parking-section__rules {
      background: #E4F0F8;
      border-radius: rem(40);

      @include desktop-sm-down {
        border-radius: rem(20);
      }

      &_primary {
        background: #0194D8;
        color: #fff;
      }

      h3 {
        font-size: rem(22);
        line-height: rem(28);
        margin-bottom: rem(50);

        @include desktop-sm-down {
          margin-bottom: rem(30);
          font-size: rem(18);
        }
      }
    }

    .parking-section__rules-head {
      h3 {
        margin-bottom: rem(22);
      }
    }
    
    .tooltip__main {
      background: #FFFFFF;
    }

    .parking-section__time-col:not(:last-child) {
      @include desktop-sm-down {
        border-bottom-color: #fff;
      }
    }

    .parking-section__title {
      @include desktop-sm-down {
        font-size: rem(30);
      }
    }

  }
}

@import "includes/parking-section/rules-client";
@import "includes/parking-section/rules-item";
