.plan-brands {
  background: #FFFFFF;
  border-radius: rem(30);
  padding: rem(35) rem(75);
  overflow: hidden;
  position: relative;
  z-index: 3;

  @include desktop-sm-down {
    display: none;
  }

  &__title {
    font-weight: 600;
    font-size: rem(24);
    line-height: rem(31);
    letter-spacing: 0.02em;
    color: #123063;
    margin-bottom: rem(35);
    display: inline-block;
  }

  &__swiper {

    .swiper {
      overflow: visible;
    } 

    .swiper-slide {
      width: rem(252);

      &:not(:last-child) {
        margin-right: rem(9);
      }
    }
  }

  &__item {
    height: rem(80);
    display: flex;
    align-items: center;
    justify-content: center;
    border: rem(2) solid #F0F6FB;
    box-sizing: border-box;
    border-radius: rem(20);

    img {
      max-width: rem(150);
      max-height: rem(40);
    }
  }

  &__prev {
    color: #123063;
    padding: rem(10);
    position: absolute;
    top: rem(-70);
    right: rem(70);
    font-size: rem(10);
    cursor: pointer;

    .icon {
      display: block;
    }
  }

  &__next {
    color: #123063;
    padding: rem(10);
    position: absolute;
    top: rem(-70);
    right: rem(45);
    font-size: rem(10);
    cursor: pointer;

    .icon {
      display: block;
    }
  }

}