.map-section {

  @include desktop-sm-down {
    margin: 0 rem(-$container-padding-md) rem(-15);
  }
  
  &__cover {
    position: relative;
  }

  #map {
    height: rem(800);
    width: 100%;
    position: relative;
    border-radius: rem(30);
    overflow: hidden;

    @include desktop-sm-down {
      height: rem(400);
      border-radius: 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
    
    * {
      box-sizing: content-box;
    }
  }

  #map-panorama {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: none;
  }

  &__inner {
    position: absolute;
    top: rem(30);
    left: rem(30);
    max-height: calc(100% - rem(60));
    overflow: auto;
    max-width: rem(530);
    width: 100%;
    z-index: 3;

    @include desktop-sm-down {
      max-width: 100%;
      position: relative;
      top: auto;
      left: auto;
      padding: 0;
      background: #FFFFFF;
      border-radius: rem(15);
      padding-top: rem(50);
      overflow: visible;
      max-height: unset;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(15);
        background: #ffffff;
        border-radius: rem(0) rem(0) rem(15) rem(15) ;
        position: absolute;
        top: rem(50);
        left: 0;
        right: 0;
        z-index: 2;
      }
    }
  }

  .accordion-content {
    overflow: hidden;

    @include desktop-sm-down {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      .accordion-content__head {
        left: calc(50% - #{rem(53)});
      }
      
    }

    &:nth-child(3) {
      .accordion-content__head {
        left: calc(50% + #{rem(53)});
      }
    }

    &.active {

      .accordion-content__head {
        border-color: #00A3DA;
      }
    }

    &__head {
      padding: rem(20) rem(35);
      background: #fff;

      @include desktop-sm-down {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: rem(2);
        border: rem(2) solid transparent;
        border-radius: 50%;
        transition: .3s;
      }
    }

    &__title {
      font-size: rem(18);
      line-height: 130%;
      margin-right: rem(15);

      @include desktop-sm-down {
        display: none;
      }
    }

    &__btn {
      width: rem(40);
      height: rem(40);
      background: #E0F2FF;

      @include desktop-sm-down {
        display: none;
      }

      &:hover {
        background: #0194D8;
      }

      .icon {
        font-size: rem(12);
      }
    }

    &__ico {
      width: rem(40);
      height: rem(40);
      margin-right: rem(30);
      border-radius: rem(15);
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      @include desktop-sm-down {
        margin-right: 0;
        border-radius: 50%;
      }

      &.ico-1 {
        background: #DA4E00;
      }

      &.ico-2 {
        background: #0194D8;
      }

      &.ico-3 {
        background: #B5D054;
      }

      .icon-bus {
        font-size: rem(21);
      }

      .icon-sports-car {
        font-size: rem(21);
      }

      .icon-360-degrees {
        font-size: rem(24);
      }

    }

    &__inner {
      padding: rem(30) rem(35) rem(45);

      @include desktop-sm-down {
        padding: rem(35) rem($container-padding-md) rem(45);
      }
    }
  }
}

.driving-block {
  color: #123063;

  &__cover {
    display: flex;
  }

  &__content,
  &__map-cover {
    width: 50%;
  }

  &__map {
    border-radius: rem(30);
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-height: rem(300);
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: rem(30);
    }

    &.accent-1 {
      .driving-block__title {
        .dot {
          color: #48B85E;
        }
      }
  
      .driving-block__sub-title {
        color: #48B85E;
      }
    }
  
    &.accent-2 {
      .driving-block__title {
        .dot {
          color: #6AC9C8;
        }
      }
  
      .driving-block__sub-title {
        color: #6AC9C8;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: 130%;
    display: flex;
    align-items: center;
    margin-bottom: rem(5);

    .dot {
      display: inline-block;
      font-size: rem(25);
      margin-left: rem(10);
    }

    &.lg {
      font-size: rem(18);

      @include desktop-sm-down {
        font-size: rem(20);
      }
    }
  }

  &__desc {
    font-size: rem(14);
    line-height: 140%;

    .dot {
      color: #0194D8;
    }
  }

  &__sub-title {
    font-weight: 600;
    font-size: rem(14);
    line-height: 130%;
    margin-bottom: rem(7);
  }

  .btn {
    height: rem(50);
    margin-right: rem(15);
    margin-top: rem(20);
    padding: 0 rem(40);

    .icon-checkers {
      font-size: rem(25);
    }

    &.driving-block__btn-taxi {
      padding: 0 rem(5);
    }
  }

  .file-link {
    .btn {
      margin-top: 0;
      margin-right: 0;
      height: rem(40);
    }
  }

  .driving-block__btn-taxi {
    margin-right: 0;
  }

  &__btn-taxi {
    width: rem(50);
    background: #FABC22;
    color: #fff;

    &:hover {
      background: darken($color: #FABC22, $amount: 20);
    }
  }
  
}

.route-form {
  max-width: rem(450);

  &__inputs {
    padding-right: rem(50);
    position: relative;

    @include desktop-sm-down {
      padding-right: 0;
    }
  }

  &__input {
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-bottom: rem(10);
    }

    input {
      width: 100%;
      height: rem(60);
      background: #FFFFFF;
      border-radius: rem(15);
      padding-left: rem(50);
      padding-right: rem(50);
      border: none;

      font-family: inherit;
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(18);
      color: #123063;
      outline: none;

      &::placeholder {
        opacity: 1;
        color: #89B6E3;
      }
    }

    &.input-from {
      .route-form__input-label {
        background: #EF577F;
      }
    }

    &.input-to {
      .route-form__input-label {
        background: #0194D8;
      }
    }
  }

  &__input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: rem(16);
    transform: translateY(-50%);
    color: #fff;
    font-weight: 700;
    font-size: rem(16);
  }

  &__location-btn {
    position: absolute;
    top: 50%;
    right: rem(20);
    transform: translateY(-50%);
    color: #123063;
    

    .icon-locate {
      font-size: rem(16);
      transform: rotate(30deg);
    }
  }

  &__swap-btn {
    position: absolute;
    width: rem(50);
    height: rem(50);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #123063;

    @include desktop-sm-down {
      display: none;
    }
    
    .icon {
      font-size: rem(21);
    }
  }
}

