.filters-block_b {
  .btn {
    box-shadow: none;
    font-weight: 500;
    padding: 0 rem(25);
  }

  .filters-block__cover  {
    max-width: 100%;
  }

  .btn:not(.btn_primary) {
    color: #0194D8;

    &.active,
    &:focus {
      background: #0194D8;
      color: #fff;

      &:hover {
        background: #00A3DA;
      }
    }
    
  }

  .btn.filters-block__btn-clear {
    background-color: #fff;
    color: #123063;

    &:hover {
      background: #DEE8EE;
    }
  }

  .btn-type:not(.btn_primary) {
    padding: 0;
    width: rem(48);
    height: rem(48);
    color:  #123063;


    .icon {
      margin-right: 0;
      color: inherit;

      &-tile {
        font-size: rem(18);
      }

      &-list {
        font-size: rem(26);
      }
    }
  }
}

.filters-block_list {
  margin-top: rem(32);

  .btn {
    padding: 0 rem(15);
    margin-right: rem(10);
  }

  .filters-block__cover {
    align-items: flex-start;

    > div {
      margin-right: rem(10);
    }
  }
}