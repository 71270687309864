&__time-block {
  margin-bottom: rem(50);

  @include desktop-sm-down {
    margin-bottom: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: rem(35);

    @include desktop-sm-down {
      margin-bottom: rem(35);
    }
  }

}

&__time-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 rem(-15) rem(-30);

  @include desktop-sm-down {
    flex-direction: column;
    margin-bottom: rem(-18);
  }
}

&__time-col {
  width: auto;
  padding: 0 rem(15);
  margin-bottom: rem(30);

  @include desktop-sm-down {
    width: 100%;
    margin-bottom: rem(18);
    padding-bottom: rem(18);
  }

  &:not(:last-child) {
    @include desktop-sm-down {
      border-bottom: rem(2) solid #edf6fc;
    }
  }
}

&__time-item {

  @include desktop-sm-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tooltip {
    top: rem(-8);

    @include desktop-sm-down {
      display: none;
    }
  }
}

&__time-title {
  font-weight: 600;
  font-size: rem(14);
  line-height: 140%;
  margin-bottom: rem(2);
  color: #9bc1d7;
  white-space: normal;

  @include desktop-sm-down {
    font-size: rem(10);
    margin-right: rem(15);
    width: 40%;
  }
}

&__time-desc {
  font-size: rem(36);
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #123063;
  white-space: nowrap;
  font-weight: 500;

  @include desktop-sm-down {
    font-size: rem(20);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tooltip {
    letter-spacing: normal;
  }
}