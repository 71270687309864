.b-route-section {
  margin-top: rem(120);
  margin-bottom: rem(120);

  @include desktop-sm-down {
    margin-top: rem(50);
    margin-bottom: rem(50);
  }

  .section-head {
    margin-bottom: rem(60);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }
  }
}
