.registration-form {
  .row {
    margin: 0 rem(-5) rem(20);

    &:last-child {
      margin-bottom: rem(-10);
    }
  }

  .col {
    padding: 0 rem(5);
    margin-bottom: rem(10);

    .input-wrap {
      margin-bottom: 0;
    }

  }

  .input-notify {
    margin-bottom: rem(10);
  }

  .form__btn-submit {
    max-width: rem(230);

    @include desktop-sm-down {
      max-width: 100%;
    }
  }

  .check-input__label a {
    color: #0194D8;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  
}