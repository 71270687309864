.arena-swiper {
  .swiper-slide {
    width: 73.38%;

    &:not(:last-child) {
      margin-right: rem(30);

      @include desktop-sm-down {
        margin-right: rem(15);
      }
    }

    > div {
      padding-top: 45.6%;
      position: relative;
      border-radius: rem(40);
      overflow: hidden;

      @include desktop-sm-down {
        border-radius: rem(15);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__nav {
    position: absolute;
    right: rem(70);
    bottom: rem(80);
    display: flex;
    align-items: center;
    z-index: 2;

    @include desktop-sm-down {
      display: none;
    }
  }

  &__prev,
  &__next {
    width: rem(45);
    height: rem(45);
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194d8;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #fff;
      background: #11387a;
    }

    &:not(:last-child) {
      margin-right: rem(15);
    }

    .icon {
      font-size: rem(12);
    }
  }
}
