.action-section {
  margin-bottom: rem(70);

  @include desktop-sm-down {
    margin-bottom: rem(30);
  }

  .stock-swiper {
    @include desktop-sm-down {
      margin-bottom: rem(25);
  
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(15);
        background: #F0F6FB;
        border-radius: rem(15) rem(15) rem(0) rem(0);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }
  }
}