@use "sass:math";

.subscribe-section {
  background: #0e1248;
  border-radius: rem(30) rem(30) rem(0) rem(0);
  position: relative;
  color: #fff;
  margin-bottom: rem(-40);
  overflow: hidden;
  margin-top: auto;
  z-index: 2;

  @include desktop-sm-down {
    border-radius: rem(15);
    margin-bottom: rem(-15);
    z-index: 2;
  }

  &:after {
    content: "";
    display: block;
    width: rem(160);
    height: rem(1);
    background: #00a3da;
    position: absolute;
    bottom: rem(74);
    left: 50%;
    transform: translateX(-50%);

    @include desktop-sm-down {
      display: none;
    }
  }

  &__cover {
    position: relative;

    &::before {
      content: "";
      display: block;
      background: #10c3ff;
      filter: blur(rem(134));
      position: absolute;
      top: rem(-100);
      left: rem(-620);
      width: rem(874);
      height: rem(560);
      border-radius: 50%;
      pointer-events: none;

      @include desktop-sm-down {
        width: rem(140);
        height: rem(140);
        top: rem(64);
        left: rem(-40);
        filter: blur(rem(40));
      }
    }

    &:after {
      content: "";
      display: block;
      background: #4a36c6;
      filter: blur(rem(120));
      position: absolute;
      top: rem(-410);
      right: rem(-555);
      width: rem(1080);
      height: rem(694);
      border-radius: 50%;
      pointer-events: none;

      @include desktop-sm-down {
        width: rem(200);
        height: rem(200);
        top: rem(-13);
        right: rem(-112);
        filter: blur(rem(50));
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding-top: rem(95);
    padding-bottom: rem(130);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include desktop-sm-down {
      padding-top: rem(26);
      padding-bottom: rem(32);
      flex-direction: column;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(30);
    line-height: 110%;
    letter-spacing: 0.02em;
    width: #{math.div(620, 1530) * 100%};

    @include desktop-sm-down {
      width: 100%;
      font-size: rem(14);
      line-height: 130%;
      margin-bottom: rem(15);
    }
  }

  &__form {
    width: #{math.div(824, 1530) * 100%};

    @include desktop-sm-down {
      width: 100%;
    }
  }
}
