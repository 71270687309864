.plan-section {
  background: #E0F2FA;
  border-radius: rem(40);
  margin-top: rem(76);
  margin-bottom: rem(62);
  overflow: hidden;
  
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .breadcrumbs,
  .b-hero-section__head-cover {
    position: relative;
    z-index: 5;
  }

  &.b-hero-section {
    border-radius: 0;
   
    position: relative;
    z-index: 1;

    @include desktop-sm-up {
      margin-bottom: rem(-30);
      padding-bottom: rem(100);
      min-height: rem(1535);
    }

    .b-plan-block__brands {
      filter: drop-shadow(0 rem(150) rem(150) #363BC6);
    }

    .b-plan-block {
      @include desktop-sm-down {
        padding-top: 0;
      }
    }
  }

}



