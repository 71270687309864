&__main-sub {
  .container {
    border-top: rem(2) solid #edf6fc;
  }
}

&__sub-inner {
  display: flex;
  align-items: center;
  height: rem(228);
  padding: rem(30) 0;
}

&__sub-nav {
  margin-left: rem(40);
  width: rem(234);
  margin-right: rem(40);

  li {
    &:not(:last-child) {
      margin-bottom: rem(8);
    }

    a {
      font-size: rem(16);
      line-height: normal;
      color: #123063;
      transition: 0.3s;
      font-weight: 500;

      &:hover {
        color: #0194d8;
      }
    }
  }
}

&__cards-row {
  display: flex;
  margin: 0 rem(-15);
}

&__cards-col {
  padding: 0 rem(15);

  .card-a {
    width: rem(300);
    height: rem(157);
  }
}

&__sub-footer {
  background: #EDF6FC;
}

&__sub-cover {
  padding: rem(27) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: rem(140);
}

&__phone {
  font-weight: 600;

  &:not(:last-child) {
    margin-right: rem(125);
  }

  a {
    font-size: rem(26);
    line-height: 1;
    letter-spacing: 0.02em;
    color: #1A1F4E;
    transition: .3s;
    font-weight: 500;

    span {
      color: #0194D8;
      transition: .3s;
    }

    &:hover {
      color: #0194D8;

    }
  }
}

&__phone-title {
  
  font-size: rem(14);
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #9BC1D7;
  margin-bottom: rem(6);
}