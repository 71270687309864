.scene-section {
  margin: rem(60) 0;

  @include desktop-sm-down {
    margin-top: rem(40);
    margin-bottom: rem(30);
  }

  &__cover {
    position: relative;
    background: #0e1248;
    padding: rem(100) rem(132);
    border-radius: rem(40);
    overflow: hidden;

    @include desktop-sm-down {
      margin: 0 rem(-$container-padding-md);
      padding: rem(50) rem($container-padding-md) rem(25);
      border-radius: 0;
    }

    &::before {
      content: "";
      display: block;
      background: #00a3da;
      filter: blur(rem(134));
      position: absolute;
      top: rem(-458);
      left: rem(-403);
      width: rem(800);
      height: rem(800);
      border-radius: 50%;
      pointer-events: none;

      @include desktop-sm-down {
        width: rem(395);
        height: rem(385);
        top: rem(-320);
        left: rem(-266);
        filter: blur(rem(40));
      }
    }

    &:after {
      content: "";
      display: block;
      background: rgba(16, 195, 255, 0.67);
      filter: blur(rem(120));
      position: absolute;
      bottom: rem(-266);
      right: rem(-240);
      width: rem(543);
      height: rem(522);
      border-radius: 50%;
      pointer-events: none;

      @include desktop-sm-down {
        width: rem(269);
        height: rem(269);
        bottom: rem(-188);
        right: rem(-167);
        filter: blur(rem(50));
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  .section-head {
    margin-bottom: rem(62);
    color: #fff;

    @include desktop-sm-down {
      margin-bottom: rem(35);
    }
  }

  .scene-swiper .swiper {
    margin: 0 rem(-15);

    @include desktop-sm-down {
      margin: 0;
    }
  }
}

.scene-swiper {
  .swiper {
    @include desktop-sm-down {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: rem(180);

    @include desktop-sm-down {
      width: rem(100);
    }

    &:not(:last-child) {
      margin-right: rem(44);

      @include desktop-sm-down {
        margin-right: rem(20);
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: rem(10) rem(50) 0;

    @include desktop-sm-down {
      display: none;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    padding: rem(7) rem(13);

    .icon {
      font-size: rem(18);
      display: block;
    }

    &:hover {
      color: #0194d8;
    }
  }

  &_type-2 {

    .swiper-slide {
      width: rem(150);
  
      @include desktop-sm-down {
        width: rem(100);
      }
  
      &:not(:last-child) {
        margin-right: rem(47);
  
        @include desktop-sm-down {
          margin-right: rem(15);
        }
      }
    }

    .card-d {
      &__img {
        width: rem(95);
        height: rem(95);
        padding: rem(6);
      }

      &__title {
        color: #123063;
        font-size: rem(16);
        margin: rem(16) 0;

        @include desktop-sm-down {
          font-size: rem(14);
          margin: rem(5) 0;
        }
      }
    }
  }
}

