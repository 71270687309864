

.container-default {
  .share-block2 {
    background: #FFFFFF;
    padding: rem(25);
    padding-right: rem(40);
    width: 100%;

    &__title {
      color: #123063;
    }
  }

  .popup__inner {
    width: 100%;
  }

  .socials li a {
    color: #123063;
  }

  .btn-close {
    top: rem(10);
    right: rem(7);
  }
}