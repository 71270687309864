.b-article {
  margin-bottom: rem(80);

  @include desktop-sm-down { 
    margin-bottom: rem(50);
  }
  
  &__title {
    margin-bottom: rem(25);

    @include desktop-sm-down { 
      margin-bottom: rem(25);
      font-size: rem(22);
    }
  }

  h1 {
    @include desktop-sm-down { 
      font-size: rem(22);
    }
  }

  &__date {
    font-style: italic;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #1B407E;
    margin-bottom: rem(40);

    @include desktop-sm-down { 
      margin-bottom: rem(14);
    }
  }

  .back-btn {
  
    @include desktop-sm-up {
      margin-bottom: rem(40);
      margin-top: rem(40);
    }
  }

  .main-swiper {
    margin-top: rem(40);
    margin-bottom: rem(40);

    @include desktop-sm-down { 
      margin: 0 rem(-$container-padding-md);
      margin-bottom: rem(15);
      border-radius: 0;
      
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(15);
        background: #F0F6FB;
        border-radius: rem(15) rem(15) rem(0) rem(0);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    .swiper-slide {
      padding-top: 50%;

      @include desktop-sm-down { 
        padding-top: 62.1%;
      }
    }

    &__nav {
      @include desktop-sm-down { 
        bottom: rem(40);
      }
    }

    &__next, 
    &__prev {
      @include desktop-sm-down { 
        background: #123063;
        color: #fff;

        &:hover {
          background: #00A3DA;
        }
      }
    }
  }

  p {
    font-size: rem(20);
    line-height: 150%;

    @include desktop-sm-down {
      font-size: rem(15);
    }
  }

  &__btn-shop {
    padding: 0 rem(50);
    margin-top: rem(35);
    height: rem(65);
    font-size: rem(18);

    @include desktop-sm-down {
      margin-top: rem(40);
      display: flex;
      max-width: rem(500);
      height: rem(60);
      font-size: rem(14);
    }
  }
}

.b-article-banner {
  background: #E0F3FF;
  border-radius: rem(30);
  padding: rem(35) rem(55);
  font-weight: bold;
  font-size: rem(20);
  line-height: 150%;
  color: #123063;

  @include desktop-sm-down { 
    padding: rem(25);
    font-size: rem(15);
  }

  &:not(:last-child) {
    margin-bottom: rem(10);
  }

  &:first-child {
    margin-top: rem(40);

    @include desktop-sm-down {
      margin-top: rem(30); 
    }
  }

  &:last-child {
    margin-bottom: rem(40);

    @include desktop-sm-down {
      margin-bottom: rem(30); 
    }
  }

  a {
    color: #0194D8;

    &:hover {
      text-decoration: underline;
      color: #00A3DA;
    }
  }
}