.card-e {
  color: #ffffff;
  height: rem(385);
  position: relative;
  background: #161a41;
  border-radius: rem(30);
  overflow: hidden;
  display: block;

  @include desktop-sm-down {
    border-radius: rem(20);
    height: rem(110);
  }

  &:hover {
    .card-e__img img {
      transform: scale(1.2);
    }
  }

  &__img {
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 100% 50%;
      transition: 3s;
    }
  }

  &__main {
    position: relative;
    height: 100%;
    z-index: 2;
    padding: rem(60) rem(50) rem(80);

    @include desktop-sm-down {
      padding: rem(25) rem(17) rem(25);
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(42);
    line-height: 110%;
    margin-bottom: rem(11);

    @include desktop-sm-down {
      font-size: rem(22);
      font-weight: 600;
      margin-bottom: rem(3);
    }
  }

  &__desc {
    font-style: italic;
    font-weight: 600;
    font-size: rem(16);
    line-height: 1;

    @include desktop-sm-down {
      font-size: rem(10);
    }
  }

  .icon-chevron-right {
    font-size: rem(16);
    position: absolute;
    left: rem(50);
    bottom: rem(40);

    @include desktop-sm-down {
      display: none;
    }
  }
}
