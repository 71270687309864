.plan-categories {
  background: #FFFFFF;
  border-radius: rem(30);
  padding: rem(30) rem(25);
  max-width: rem(100);
  overflow: hidden;
  transition: .5s;
  position: relative;
  z-index: 5;

  @include desktop-sm-down {
    display: none;
  }

  &:hover {
    max-width: rem(350);
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: rem(17);
    }

    li {
      margin: 0 rem(-11);

      &:not(:last-child) {
        margin-bottom: rem(8);
      }
    }
  }

  &__ico {
    width: rem(50);
    height: rem(50);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(15);
    margin-right: rem(30);
    font-size: rem(24);
    color: #fff;

    .icon-electronics {
      font-size: rem(17);
    }

    .icon-dots {
      font-size: rem(21);
    }

    .icon-search {
      color: #00A3DA;
    }

    &.type-1 {
      background: #F0F6FB;
    }

    &.type-2 {
      background: radial-gradient(92% 92% at 0% 100%, #0194D8 0%, #4A36C6 100%), #E4F0F8;
    }

    &.type-3 {
      background: radial-gradient(92% 92% at 0% 100%, #F5EF55 0%, #F1C044 100%), #E4F0F8;
    }

    &.type-4 {
      background: radial-gradient(92% 92% at 0% 100%, #9989D4 0%, #A48BFF 100%), #E4F0F8;
    }

    &.type-5 {
      background: radial-gradient(92% 92% at 0% 100%, #EF577F 0%, #EF57BB 100%), #E4F0F8;
    }

    &.type-6 {
      background: radial-gradient(92% 92% at 0% 100%, #41D485 0%, #BEEF57 100%), #E4F0F8;
    }

    &.type-7 {
      background: radial-gradient(92% 92% at 0% 100%, #FB6B2D 0%, #EF5757 100%), #E4F0F8;
    }

    &.type-8 {
      background: radial-gradient(92% 92% at 0% 100%, #2D61B9 0%, #123F8A 100%), #E4F0F8;
    }
  }


  .icon-eye-cross {
    font-size: rem(16);
    color: #91C9E3;
    margin-left: auto;
    display: none;
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: rem(11) rem(13);
    padding-right: rem(15);
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(20);
    color: #123063;
    border-radius: rem(15);
    min-width: rem(318);

    &:hover {
      background: #F0F6FB;
    }

    &.active {
      .icon-eye-cross {
        display: block;
      }
    }

    &.title {
      pointer-events: none;
    }
  }
}