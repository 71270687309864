.card-a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: rem(31);
  overflow: hidden;
  color: #fff;

  &.empty {
    .card-a__main {
      padding-bottom: rem(35);
    }

    .card-a__img {
      &::after {
        display: none;
      }
    }
  }

  &:hover {
    .card-a__img img {
      transform: scale(1.2);
    }
  }

  &__tag {
    display: inline-block;
    background: #0194d8;
    border-radius: rem(100);
    padding: rem(6) rem(15) rem(5);
    font-weight: 550;
    font-size: rem(12);
    line-height: rem(12);
    color: #fff;
    position: absolute;
    top: rem(12);
    left: rem(22);
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: rem(-13);
      background: linear-gradient(
        180.2deg,
        rgba(14, 18, 72, 0) 45.6%,
        #0e1248 99.83%
      );
      mix-blend-mode: darken;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
      transition: 3s;
    }
  }

  &__main {
    padding: rem($container-inner-padding-md) rem(22);
    margin-top: auto;
    position: relative;
    z-index: 2;
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 110%;
    color: #fff;
  }

  &__date {
    font-style: italic;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(14);
    margin-top: rem(6);
    min-height: rem(14);
  }
}
