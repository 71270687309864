@use "sass:math";
.icon-360-degrees {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-baby {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 11) * 1em;
}

.icon-bank {
  font-size: math.div(17, 10) * 1rem;
  height: math.div(17, 21) * 1em;
}

.icon-beauty {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-box {
  font-size: math.div(20, 10) * 1rem;
  height: math.div(20, 18) * 1em;
}

.icon-burger {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 30) * 1em;
}

.icon-bus {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 21) * 1em;
}

.icon-calendar {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 17) * 1em;
}

.icon-check {
  font-size: math.div(9, 10) * 1rem;
  height: math.div(9, 13) * 1em;
}

.icon-checkers {
  font-size: math.div(10, 10) * 1rem;
  height: math.div(10, 25) * 1em;
}

.icon-chevron-down {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 25) * 1em;
}

.icon-chevron-left {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 15) * 1em;
}

.icon-chevron-right {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 15) * 1em;
}

.icon-chevron-top {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 25) * 1em;
}

.icon-close {
  font-size: math.div(11, 10) * 1rem;
  height: math.div(11, 11) * 1em;
}

.icon-clothes {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-cross {
  font-size: math.div(27, 10) * 1rem;
  height: math.div(27, 27) * 1em;
}

.icon-disabilities {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 18) * 1em;
}

.icon-doc {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 21) * 1em;
}

.icon-dots {
  font-size: math.div(5, 10) * 1rem;
  height: math.div(5, 21) * 1em;
}

.icon-download {
  font-size: math.div(12, 10) * 1rem;
  height: math.div(12, 12) * 1em;
}

.icon-electronics {
  font-size: math.div(27, 10) * 1rem;
  height: math.div(27, 17) * 1em;
}

.icon-enter-left {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-enter-right {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-entertainment {
  font-size: math.div(22, 10) * 1rem;
  height: math.div(22, 23) * 1em;
}

.icon-entry {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 14) * 1em;
}

.icon-escalator {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 26) * 1em;
}

.icon-eye {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 22) * 1em;
}

.icon-eye-cross {
  font-size: math.div(22, 10) * 1rem;
  height: math.div(22, 22) * 1em;
}

.icon-fb {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 25) * 1em;
}

.icon-fb2 {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 29) * 1em;
}

.icon-file {
  font-size: math.div(26, 10) * 1rem;
  height: math.div(26, 26) * 1em;
}

.icon-file2 {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 17) * 1em;
}

.icon-food {
  font-size: math.div(20, 10) * 1rem;
  height: math.div(20, 18) * 1em;
}

.icon-forbidden {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 29) * 1em;
}

.icon-ig {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 26) * 1em;
}

.icon-ig2 {
  font-size: math.div(30, 10) * 1rem;
  height: math.div(30, 30) * 1em;
}

.icon-list {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 26) * 1em;
}

.icon-locate {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 16) * 1em;
}

.icon-map {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-map-marker {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 17) * 1em;
}

.icon-map-marker2 {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 20) * 1em;
}

.icon-message {
  font-size: math.div(20, 10) * 1rem;
  height: math.div(20, 20) * 1em;
}

.icon-minus {
  font-size: math.div(4, 10) * 1rem;
  height: math.div(4, 23) * 1em;
}

.icon-next {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 18) * 1em;
}

.icon-ok {
  font-size: math.div(26, 10) * 1rem;
  height: math.div(26, 26) * 1em;
}

.icon-p-parking {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 14) * 1em;
}

.icon-pacifier {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 23) * 1em;
}

.icon-parking {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-parking2 {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 25) * 1em;
}

.icon-people {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-percent {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 18) * 1em;
}

.icon-plus {
  font-size: math.div(22, 10) * 1rem;
  height: math.div(22, 23) * 1em;
}

.icon-present {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-prev {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 18) * 1em;
}

.icon-restaurant {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-search {
  font-size: math.div(30, 10) * 1rem;
  height: math.div(30, 29) * 1em;
}

.icon-services {
  font-size: math.div(20, 10) * 1rem;
  height: math.div(20, 20) * 1em;
}

.icon-sex {
  font-size: math.div(22, 10) * 1rem;
  height: math.div(22, 21) * 1em;
}

.icon-share {
  font-size: math.div(14, 10) * 1rem;
  height: math.div(14, 13) * 1em;
}

.icon-soccer-ball {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 23) * 1em;
}

.icon-sports-car {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 21) * 1em;
}

.icon-square {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-star {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 22) * 1em;
}

.icon-star2 {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 24) * 1em;
}

.icon-street-map {
  font-size: math.div(20, 10) * 1rem;
  height: math.div(20, 20) * 1em;
}

.icon-swap {
  font-size: math.div(17, 10) * 1rem;
  height: math.div(17, 21) * 1em;
}

.icon-ticket {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 20) * 1em;
}

.icon-ticket2 {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-tile {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 20) * 1em;
}

.icon-vk {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 26) * 1em;
}

.icon-vk2 {
  font-size: math.div(30, 10) * 1rem;
  height: math.div(30, 30) * 1em;
}

