.card-g {
  display: block;
  border-radius: rem(30);
  overflow: hidden;
  width: 100%;
  transition: .3s;
  color: #FFFFFF;
  position: relative;

  @include desktop-sm-down {
    border-radius: rem(20);
  }

  &:hover {
    .card-g__img img {
      transform: scale(1.2);
    }
  }

  &__tag {
    display: inline-block;
    background: rgba(155, 193, 215, 0.7);
    backdrop-filter: blur(rem(4));
    border-radius: rem(100);
    padding: rem(8.5) rem(23) rem(9.5);
    font-size: rem(12);
    line-height: 1;
    color: #fff;
    position: absolute;
    top: rem(30);
    left: rem(38);
    z-index: 3;
    text-align: center;
    min-width: rem(80);
    box-sizing: border-box;

    @include desktop-sm-down {
      top: rem(15);
      left: rem($container-inner-padding-md);
      min-width: rem(56);
      padding: rem(8.5) rem(15) rem(9.5);
      font-size: rem(10);
    }
  }

  &__img {
    padding-top: 65.3%;
    position: relative;
    overflow: hidden;

    @include desktop-sm-down {
      padding-top: 66.66%;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(18, 48, 99, 0.73) 0%, rgba(18, 48, 99, 0) 56.41%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      @include desktop-sm-down {
        bottom: rem(-30);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__main {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    padding: rem(80) rem(38) rem(30);
    box-sizing: border-box;

    @include desktop-sm-down {
      padding: rem(60) rem($container-inner-padding-md) rem(20);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(26);
    line-height: 110%;
    margin-bottom: rem(16);
    overflow: hidden;

    @include desktop-sm-down {
      font-size: rem(18);
      margin-bottom: rem(8);
    }
  }

  &__date {
    font-size: rem(14);
    font-style: italic;
    line-height: 1;

    @include desktop-sm-down {
      font-size: rem(10);
    }
  }


}
