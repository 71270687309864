.main-swiper {
  border-radius: rem(30);
  overflow: hidden;

  @include desktop-sm-down {
    border-radius: rem(20);
  }

  .swiper-slide {
    padding-top: 40.6%;
    position: relative;

    @include desktop-sm-down {
      padding-top: 66.2%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__nav {
    position: absolute;
    bottom: rem(40);
    right: rem(60);
    z-index: 2;
    display: flex;
    align-items: center;

    @include desktop-sm-down {
      right: rem(25);
      bottom: rem(16);
    }

    
  }

  &__prev,
  &__next {
    width: rem(45);
    height: rem(45);
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194d8;
    cursor: pointer;
    transition: 0.3s;

    @include desktop-sm-down {
      width: rem(25);
      height: rem(25);
    }

    &:hover {
      color: #fff;
      background: #11387a;
    }
    

    &:not(:last-child) {
      margin-right: rem(15);

      @include desktop-sm-down {
        margin-right: rem(8);
      }
    }

    .icon {
      font-size: rem(12);

      @include desktop-sm-down {
        font-size: rem(7);
      }
    }
  }

  &__prev {
    margin-right: rem(15);
  }

  &__logo {
    width: rem(90);
    height: rem(90);
    border-radius: 50%;
    position: absolute;
    top: rem(40);
    right: rem(50);
    overflow: hidden;
    z-index: 2;

    @include desktop-sm-up {
      display: none;
    }

    @include desktop-sm-down {
      width: rem(60);
      height: rem(60);
      right: rem(15);
      top: rem(16);
    }

    img {
      width: 100%;
    }

    &_b {
      @include desktop-sm-down {
        width: rem(80);
        height: rem(80);
        right: auto;
        left: rem(24);
        top: rem(24);
      }
    }
  }

  .btn-photo-report {
    position: absolute;
    bottom: rem(40);
    left: rem(38);
    height: rem(46);
    padding: 0 rem(20);
    font-size: rem(14);
    z-index: 2;

    @include desktop-sm-down {
      display: none;
    }
  }
}

