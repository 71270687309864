.time-section {
  margin-bottom: rem(30);
  margin-top: rem(80);

  @include desktop-sm-down {
    margin-top: rem(50);
    margin-bottom: rem(45);
  }

  &__title {
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin-bottom: rem(15);
      display: none;
    }
  }
}