.b-accordion-block {
  &__item {
    border-radius: rem(40);
    overflow: hidden;
    position: relative;
    background: #FFFFFF;

    @include desktop-sm-down {
      border-radius: rem(20);
    }

    &:not(:last-child) {
      margin-bottom: rem(20);
    }


    &.item-1 {
      .b-accordion-block__head-ico {
        img {
          width: rem(65);

          @include desktop-sm-down {
            width: rem(27);
          }
        }
      }

      .driving-block {

        &__desc {
          max-width: rem(500);
        }
      }

      .btn-parking {
        background: #123063;
        color: #fff;
        min-width: rem(180);

        &:hover {
          background: #102853;
        }
      }
    }

    &.item-2 {

      .b-accordion-block__head-ico {
        img {
          width: rem(66);

          @include desktop-sm-down {
            width: rem(30);
          }
        }
      }

      .driving-block {

        &__desc {
          max-width: rem(400);
        }

        &__title {
          margin-bottom: rem(17);
        }
      }


    }

    &.active {
      .b-accordion-block__btn {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    padding: rem(40) rem(65);
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    height: rem(140);
    cursor: pointer;

    @include desktop-sm-down {
      padding: rem(0) rem(23);
      height: rem(74);
    }
  }

  &__head-ico {
    width: rem(100);
    margin-right: rem(50);
    flex-shrink: 0;

    @include desktop-sm-down {
      margin-right: rem(20);
      width: rem(30);
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(36);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #123063;
    max-width: rem(654);

    @include desktop-sm-down {
      font-size: rem(18);
      line-height: rem(23);
    }
  }

  &__btn {
    margin-left: auto;
    width: rem(54);
    height: rem(54);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194D8;
    background: #E0F3FF;
    border-radius: rem(15);
    margin-right: rem(15);
    cursor: pointer;
    flex-shrink: 0;

    @include desktop-sm-down {
      width: rem(40);
      height: rem(40);
      margin-right: 0;
    }

    .icon {
      font-size: rem(16);
      transition: 0.3s;
    }
  }

  &__main {
    display: none;
    position: relative;
    z-index: 2;
  }

  &__inner {
    padding: 0 rem(60);
    padding-bottom: rem(70);

    @include desktop-sm-down {
      padding: 0 rem(23);
      margin: rem(25) 0;
    }
  }

  .driving-block {
    position: relative;

    &__cover {
      position: relative;
      padding: rem(30);

      @include desktop-sm-down {
        flex-direction: column;
        padding: 0;
      }
    }
  
    &__content {
      position: relative;
      z-index: 2;
      background: #FFFFFF;
      border-radius: rem(30);
      max-width: rem(580);
      padding: rem(50) rem(40);

      @include desktop-sm-down {
        width: 100%;
        padding: 0;
        margin-bottom: rem(35);
      }
    }
  
  
    &__map-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include desktop-sm-down {
        position: relative;
        height: auto;
        margin-bottom: rem(35);
      }
    }

    &__title {
      margin-bottom: rem(17);
    }

    &__desc {
      max-width: rem(480);
    }

    .btn {
      margin-top: rem(45);
    }
  }

  
  
}
