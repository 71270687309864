.header,
.footer {
  flex: 0 0 auto;
}

.main {
  flex: 1 0 auto;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: rem(1570);
  padding: 0 rem($container-padding-lg);
  margin: 0 auto;

  @include desktop-sm-down {
    padding: 0 rem($container-padding-md);
  }

  &_sm {
    max-width: rem(1050);
  }
}

.container-full {
  padding: 0 rem(45);

  @include desktop-sm-down {
    padding: 0;
  }
}

.hide-desktop-sm {
  @include desktop-sm-down {
    display: none !important;
  }
}

.show-desktop-sm {
  @include desktop-sm-up {
    display: none !important;
  }
}

.icon {
  display: inline-block;
  width: 1em;
  fill: currentColor;
  flex-shrink: 0;
  transition: transform 0.3s;
  color: inherit;
  font-size: inherit;
}

.btn {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: rem(60);
  border-radius: rem(15);
  padding: 0 rem(15);
  // font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: rem(16);
  line-height: rem(19);
  color: #123063;
  transition: 0.3s;

  @include desktop-sm-down {
    font-size: rem(14);
  }

  &_sm {
    padding: 0;
    width: rem(50);
    height: rem(50);

    .icon {
      color: #fff;
      font-size: rem(20);
    }
  
    .icon-street-map {
      font-size: rem(20);
    }
  
    .icon-p-parking {
      font-size: rem(13);
    }
  
    img[src*="map-marker2.svg"] {
      width: rem(20);
      transition: .3s;
    }
  
    svg.map-marker2 {
      width: rem(20);
    }
  }
}

.btn_primary {
  background: #00a3da;
  color: #fff;

  &:hover {
    background: #11387a;
  }
}


.btn_white {
  background: #FFFFFF;
  color: #00A3DA;

  &:hover {
    background: #11387A;
    color: #fff;
  }
}

.offset-header {
  padding-top: rem($header-h);

  @include desktop-sm-down {
    padding-top: rem($header-h-m);
  }
}
