.b-page-cover {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-15) rem(-30);

    @include desktop-sm-down {
      flex-direction: column;
      margin: 0 rem(-$container-padding-md) rem(10);
    }

    &.not-content {
      margin-bottom: 0;
    }

  }

  &__col {
    width: 100%;
    padding: 0 rem(15);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      padding: 0 rem($container-padding-md);
    }

    &:nth-child(1) {
      @include desktop-sm-up {
        width: calc(100% - #{rem(520)});
      }
    }

    &:nth-child(2) {
      @include desktop-sm-up {
        width: rem(520);
      }

      @include desktop-sm-down {
        order: 10;
      }
    }

    .request-block {
      margin-bottom: 0;
    }
  }

  .article-cover {
    max-width: rem(880);
  }

  .scheme-section {
    margin-top: rem(60);
    margin-bottom: 0;

    @include desktop-sm-down {
      margin-top: 0;
    }
  }

  &_shop {

    .b-page-cover__col {
      position: relative;
      z-index: 2;

      @include desktop-sm-down {
        margin-bottom: rem(10);
      }

      &:first-child {
        .main-swiper {

          
          @include desktop-sm-down {
            overflow: auto;
            border-radius: 0;
            margin: 0 rem(-$container-padding-md);

            &::after {
              content: "";
              display: block;
              width: 100%;
              height: rem(15);
              background: #F0F6FB;
              border-radius: rem(15) rem(15) rem(0) rem(0);
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 1;
            }
          }

          .swiper-slide {
            @include desktop-sm-up {
              min-height: rem(500);
              box-sizing: border-box;
            }
          }

          &__nav {
            @include desktop-sm-down {
              bottom: rem(40);
            }
          }

          &__next, 
          &__prev {
            @include desktop-sm-down { 
              background: #123063;
              color: #fff;

              &:hover {
                background: #00A3DA;
              }
            }
          }
        }

      }
    }
  }

  .b-shop-desc {
    margin-top: rem(50);

    @include desktop-sm-down {
      margin-top: rem(40);
      margin-bottom: rem(35);
    }
  }

}