.filters-block {
  display: flex;
  justify-content: space-between;

  @include desktop-sm-down {
    display: none;
  }

  &__cover {
    display: flex;
    align-items: flex-end;
    max-width: 70%;

    > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: rem(-20);
    
      &:last-child {
        margin-right: rem(-20);
      }
    }
  }

  .btn {
    margin-right: rem(20);
    margin-bottom: rem(20);
    height: rem(44);
    box-shadow: rem(1) rem(1) rem(1) #095fa2;
    padding: 0 rem(35);
    font-size: rem(14);
    font-weight: 600;
    white-space: nowrap;

    &:not(.btn_primary) {
      background: #fff;
      color: #123063;
    }

    &.active {
      background: #123063;
      color: #fff;
    }

    b {
      font-weight: bold;
    }

    .icon {
      margin-right: rem(13);
      font-size: rem(13);
    }

    &:not(.btn_primary, .active, .filters-block__btn-clear, .filters-block__btn-archive) {
      &:hover {
        background: #e0f2ff;
      }
    }
  }

  .btn.filters-block__btn-clear {
    background: #123063;
    color: #fff;

    &:hover {
      background: #102853;
    }
  }

  .filters-block__btn-archive {
    padding: 0 rem(50);
  }
  

  
}
