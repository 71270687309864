.min-h-0 {
  min-height: rem(0);
}

.min-h-530 {
  @include desktop-sm-up {
    min-height: rem(530);
  }
}

.min-h-680 {
  @include desktop-sm-up {
    min-height: rem(680);
  }
}