.calendar-block {
  margin-bottom: rem(40);

  @include desktop-sm-down {
    margin-bottom: rem(30);
  }

  &__title {
    font-weight: 600;
    font-size: rem(14);
    line-height: 1;
    color: #123063;
    margin-bottom: rem(22);

    @include desktop-sm-down {
      font-size: rem(12);
      margin-bottom: rem(8);
    }
  }

  &__week {
    display: flex;
  }

  &__day {
    width: rem(60);
    height: rem(60);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    font-size: rem(20);
    line-height: rem(20);
    color: #123063;
    background: #f7f8fb;
    border-radius: rem(10);
    box-sizing: border-box;
    transition: 0.3s;

    @include desktop-sm-down {
      width: rem(40);
      height: rem(40);
      font-size: rem(16);
    }

    &:hover {
      color: #00a3da;
    }

    &:not(:last-child) {
      margin-right: rem(10);
    }

    span {
      font-weight: 300;
      font-size: rem(13);
      line-height: 1;
      color: #adbae1;
      display: inline-block;
      margin-top: rem(5);

      @include desktop-sm-down {
        font-size: rem(10);
        margin-top: 0;
      }
    }

    &.active {
      background: #00a3da;
      color: #ffffff;

      &:hover {
        color: #123063;
      }
    }
  }
}

.calendar-swiper {
  padding-right: rem(20);

  @include desktop-sm-down {
    padding-right: 0;
  }

  .swiper {
    @include desktop-sm-down {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: auto;

    &:not(:last-child) {
      margin-right: rem(55);

      @include desktop-sm-down {
        margin-right: rem(25);
      }
    }
  }

  &__prev,
  &__next {
    color: #123063;
    font-size: rem(15);
    cursor: pointer;

    @include desktop-sm-down {
      display: none;
    }
  }

  &__prev {
    left: 0;
    top: rem(55);
    display: none;
  }

  &__next {
    position: absolute;
    right: 0;
    top: rem(55);
  }
}
