.card-k {
  background: linear-gradient(180deg, #F8FBFD 0%, #F0F6FB 35.94%, #FFFFFF 100%);
  border-radius: rem(30);
  overflow: hidden;
  position: relative;
  display: block;
  height: 100%;

  @include desktop-sm-down {
    border-radius: rem(20);
  }

  &:hover {
    .card-k__btn {
      background: #00A3DA;
      color: #fff;
    }

    &:after {
      width: rem(400);
      height: rem(360);
      top: rem(60);
    }
  }

  &::after {
    content: "";
    display: block;
    background: linear-gradient(171.67deg, #F8FDFF 27.03%, #F0F6FB 93.61%);
    filter: blur(rem(50));
    width: rem(550);
    height: rem(490);
    position: absolute;
    top: rem(-11);
    left: 50%;
    transform: translateX(-50%) rotate(177deg) matrix(1, 0, 0.08, 1, 0, 0);
    border-radius: 50%;
    transition: .3s;
  }

  &::before {
    content: "";
    display: block;
    background: linear-gradient(180deg, #363BC6 0%, rgba(97, 255, 246, 0.635417) 46.35%, rgba(54, 59, 198, 0) 100%);
    filter: blur(rem(50));
    width: rem(410);
    height: rem(370);
    position: absolute;
    top: rem(-135);
    left: 50%;
    transform: translateX(-50%) rotate(-45deg) matrix(1, 0, 0.08, 1, 0, 0);
    border-radius: 50%;
  }

  &__cover {
    position: relative;
    z-index: 2;
    min-height: rem(330);
    padding: rem(55) rem(45) rem(100);
    box-sizing: border-box;

    @include desktop-sm-down {
      min-height: rem(220);
      padding: rem(35) rem(30) rem(50);
    }
  }

  &__title {
    font-size: rem(26);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #1B407E;

    @include desktop-sm-down {
      font-size: rem(18);
    }
    
    b {
      font-weight: bold;
      color: #0194D8;
    }
  }

  &__btn {
    background: #E0F3FF;
    border-radius: rem(15);
    width: rem(60);
    height: rem(60);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194D8;
    font-size: rem(11);
    position: absolute;
    right: rem(20);
    bottom: rem(20);
    transition: .3s;

    @include desktop-sm-down {
      width: rem(40);
      height: rem(40);
      font-size: rem(8);
    }
      

  }
}