.card-f3 {
  display: block;
  background: #FFFFFF;
  border-radius: rem(30);
  overflow: hidden;
  width: 100%;
  transition: .3s;
  padding: rem(20) rem(20) 0;
  box-sizing: border-box;
  height: 100%;
  position: relative;

  @include desktop-sm-down {
    padding: rem(15) rem(14) 0;
    border-radius: rem(20);
  }

  &:hover {
    // filter: drop-shadow(rem(0) rem(4) rem(50) #CADEEA);

    .card-f3__img img {
      transform: scale(1.2);
    }
  }

  &__head {
    position: relative;
  }

  &__img {
    padding-top: 68%;
    position: relative;
    border-radius: rem(25);
    overflow: hidden;

    @include desktop-sm-down {
      padding-top: 50.8%;
      border-radius: rem(20);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__logo {
    position: absolute;
    top: rem(10);
    left: rem(10);
    display: block;
    width: rem(90);
    height: rem(90);
    border-radius: 50%;
    overflow: hidden;

    @include desktop-sm-down {
      width: rem(45);
      height: rem(45);
      top: rem(10);
      left: rem(10);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__main {
    padding: rem(35) rem(15);

    @include desktop-sm-down {
      padding: rem(22) rem(10);
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(22);
    line-height: 130%;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(16);
    }
  }
  a.card-f3__title {
    position: static;
    
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__desc {
    font-size: rem(14);
    line-height: rem(18);
    color: #91C9E3;
    font-style: italic;
    font-weight: 600;
    margin-bottom: rem(10);

    @include desktop-sm-down {
      font-size: rem(12);
      margin-bottom: rem(10);
    }
  }

  &__info {
    display: flex;
    margin-bottom: rem(-15);
    margin-right: rem(-15);
    margin-top: rem(35);
  }

  &__btn-info {
    background: #F0F6FB;
    border-radius: rem(15);
    height: rem(40);
    padding: 0 rem(20);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(15);
    margin-bottom: rem(15);
    font-size: rem(12);
    line-height: 150%;
    color: #123063;
    font-weight: 500;

    b {
      font-weight: 600;
    }
    

    .icon {
      margin-right: rem(10);
      font-size: rem(16);
    }
  }

  a {
    position: relative;
    z-index: 2;
  }

  a.card-f3__btn-info {
    color: #0194D8;
    transition: .3s;

    &:hover {
      background-color: #0194D8;
      color: #fff;
    }
  }
}
