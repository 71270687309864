.know-card {
  display: flex;
  align-items: center;
  font-size: rem(14);
  line-height: 150%;
  color: #FFFFFF;
  background: linear-gradient(264.94deg, #00A3DA 0.69%, #106DD0 99.37%);
  border-radius: rem(15);
  height: 100%;
  min-height: rem(120);
  padding: rem(20) rem(45);
  position: relative;
  overflow: hidden;

  @include desktop-sm-down {
    min-height: rem(90);
    font-size: rem(12);
    padding-left: rem(14);
    padding-right: rem(20);
  }

  &::after {
    content: "";
    display: block;
    width: rem(114);
    height: rem(117);
    background: url("../img/images/know-card/know-card-bg.svg") no-repeat 50% / contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  > div {
    position: relative;
    z-index: 2;
  }

  &__ico {
    font-size: rem(52);
    border-radius: 50%;
    background-color: #fff;
    flex-shrink: 0;
    display: block;
    margin-right: rem(30);
    color: #0F71D1;
    position: relative;
    z-index: 2;

    @include desktop-sm-down {
      font-size: rem(45);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}