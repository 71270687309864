.time-block {
  background: linear-gradient(180deg, #103F81 0%, #00A3DA 100%);
  border-radius: rem(30);
  padding: rem(16) rem(30);

  @include desktop-sm-down {
    border-radius: rem(20);
    margin: 0 rem(-$container-padding-md);
    padding: rem(16) rem($container-padding-md);;
  }

  h3.lg {
    @include desktop-sm-down {
      font-size: rem(24); 
    }
  }

  table {
    width: 100%;
    text-align: left;

    @include desktop-sm-down {
      display: block;
    }

    tbody {
      @include desktop-sm-down {
        display: block;
        width: 100%;
      }
    }

    tr {
      @include desktop-sm-down {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-5);
      }
    }

    th, td {
      padding-left: rem(15);
      padding-right: rem(15);

      @include desktop-sm-down {
        padding: 0 rem(5);

        &:before {
          content: attr(data-label);
          font-weight: 600;
          font-size: rem(12);
          line-height: 150%;
          letter-spacing: 0.02em;
          color: #9BC1D7;
          margin-bottom: rem(3);
          display: block;
        }
      }

      &:first-child {
        width: 25%;
        padding-left: rem(70);

        @include desktop-sm-down {
          display: block;
          width: 100%;
          padding-left: rem(5);
          margin-bottom: rem(16);
        }
      }

      &:nth-child(2) {
        width: 10%;

        @include desktop-sm-down {
          display: block;
          width: 30%;
        }
      }

      &:nth-child(3) {
        width: 18%;

        @include desktop-sm-down {
          display: block;
          width: 70%;
        }
      }

      &:last-child {
        width: 47%;
        padding-right: rem(70);

        @include desktop-sm-down {
          display: block;
          width: 100%;
          padding-right: rem(5);
          margin-top: rem(22);
        }
      }
    }
  }

  &__head {

    @include desktop-sm-down {
      padding: 0 rem($container-inner-padding-md);
    }

    table {
      @include desktop-sm-down {
        display: none;
      }
    }

    

    th {
      font-weight: 500;
      font-size: rem(14);
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #FFFFFF;

    }
  }

  &__title {
    margin-bottom: rem(30);
    color: #FFFFFF;

    @include desktop-sm-up {
      display: none;
    }

    @include desktop-sm-down {
      margin-bottom: rem(15);
    }
  }


  &__main {
    background: #FFFFFF;
    border-radius: rem(30);
    overflow: hidden;
    margin-top: rem(10);
    padding: rem(25) 0;

    @include desktop-sm-down {
      border-radius: rem(20);
      padding: rem(20) rem($container-inner-padding-md);
    }

    td {
      font-weight: normal;
      font-size: rem(20);
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #123063;
      padding-top: rem(10);
      padding-bottom: rem(10);

      @include desktop-sm-down {
        font-size: rem(16);
        line-height: rem(18);
      }
      
    }

    b {
      font-weight: bold;
    }

    .btn {
      height: rem(50);
      padding: 0 rem(20);
      font-size: rem(14);
      white-space: nowrap;

      .icon {
        font-size: rem(16);
        margin-right: rem(10);
      }
    }

    .btn_present {
      background: #EDF6FC;
      color: #0194D8;

      &:hover {
        background: #11387A;
        color: #fff;
      }
    }

  }

  &__date {
    display: flex;
    align-items: center;

    @include desktop-sm-down {
      display: block;
    }
  }

  &__day {
    font-weight: bold;
    font-size: rem(64);
    line-height: 90%;
    letter-spacing: 0.02em;
    color: #123063;
    margin-right: rem(30);

    @include desktop-sm-down {
      display: inline;
      margin-right: 0;
      font-size: rem(18);
      line-height: 110%;
    }
  }

  &__day-info {
    @include desktop-sm-down {
      display: inline-flex;
      flex-flow: row-reverse;
      align-items: center;
    }
  }

  &__week {
    font-size: rem(14);
    line-height: 150%;
    color: #9BC1D7;

    @include desktop-sm-down {
      font-size: rem(18);
      font-weight: 600;
      margin-left: rem(10);
    }
  }

  &__month {
    font-size: rem(20);
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(18);
      font-weight: 600;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include desktop-sm-down {
      flex-direction: column;
      justify-content: flex-start;
    }

    .btn {

      @include desktop-sm-down {
        display: flex;
        width: 100%;
        height: rem(44);
      }

      &:not(:last-child) {
        margin-right: rem(20);

        @include desktop-sm-down {
          margin-right: 0;
          margin-bottom: rem(10);
        }
      }
    }
  }

  &__see-all {
    margin-top: rem(17);
    height: rem(43);
    background: #FFFFFF;
    color: #00A3DA;
    display: flex;
    width: 100%;
    font-size: rem(14);

    @include desktop-sm-up {
      display: none;
    }
    &:hover {
      background: #0091C2;
      color: #fff;
    }
  }
  

  &_type-2 {
    table {
      td, th {
        
        @include desktop-sm-up {
          padding-right: rem(15);
          padding-left: rem(15);
        }

        &:first-child {
          @include desktop-sm-up {
            width: 50%;
          }
          
        }

        &:nth-child(2) {
          @include desktop-sm-up {
            width: 25%;
          }

          @include desktop-sm-down {
            width: 100%;
          }
          
        }

        &:last-child {
          @include desktop-sm-up {
            width: 25%;
            padding-right: rem(40);
          }
          
        }
      }
    }
  }
}