.socials {
  display: flex;

  li {
    &:not(:last-child) {
      margin-right: rem(12);

      @include desktop-sm-down {
        margin-right: rem(30);
      }
    }

    a {
      display: block;
      color: #fff;
      font-size: rem(30);
      border-radius: 50%;
      transition: 0.3s;

      .icon {
        display: block;
      }

      &:hover {
        color: #00a3da;
        
      }
    }
  }
}
