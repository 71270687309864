&_type-1 {

  .hero-section__bg {
    &::before {
      height: 123%;

      @include desktop-sm-down {
        height: 100%;
      }
    }
  }
}

&_type-2 {
  background: none;
  padding-bottom: rem(270);

  @include desktop-sm-down {
    padding-bottom: rem(70);
  }

  + div {
    margin-top: rem(-270);
    position: relative;
    z-index: 10;
    padding-top: 0;

    @include desktop-sm-down {
      margin-top: rem(-70);
    }

    .main-swiper {
      .main-swiper__nav {
        right: rem(10);
        bottom: rem(40);
      }
    }
  }

  .hero-section__bg {
    &::before {
      height: 136%;
      top: rem(-196);

      @include desktop-sm-down {
        height: 100%;
        top: 0;
      }
    }
  }

  &::after {
    display: none;
  }

  .hero-section__inner {
    @include desktop-sm-down {
      padding-bottom: rem(30);
    }
  }
}

&_type-3 {
  padding-bottom: rem(150);

  @include desktop-sm-down {
    padding-bottom: 0;
  }

  .hero-section__bg {
    &::before {
      height: 100%;
    }
  }

  h1, h2 {
    @include desktop-sm-down {
      font-size: rem(18);
    }
  }

  + div {
    margin-top: rem(-150);
    position: relative;
    z-index: 10;
    padding-top: 0;

    @include desktop-sm-down {
      margin-top: 0;
    }

    .main-swiper {
      @include desktop-sm-down {
        margin: 0 rem(-$container-padding-md);
        position: relative;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: rem(35);
          background: #FFFFFF;
          border-radius: rem(15) rem(15) rem(0) rem(0);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
        }

        + * {
          margin-top: 0;
        }
      }
    }

    .main-swiper__nav {
      @include desktop-sm-down {
        bottom: rem(55);
        right: rem($container-padding-md);
      }
    }
  }

  .blur-main {
    width: rem(811);
    height: rem(603);
    left: rem(505);
    top: rem(387);
    bottom: auto;
    transform: translateY(0);
    filter: blur(rem(150));
  }

  &.type-3-article {
    padding-bottom: rem(90);

    @include desktop-sm-down {
      padding-bottom: rem(50);
    }

    + div {
      margin-top: rem(-90);

      @include desktop-sm-down {
        margin-top: rem(-50);
      }
    }
  } 

}

&_type-4 {
  padding-bottom: rem(250);

  @include desktop-sm-down {
    padding-bottom: rem(70);
  }

  + div {
    margin-top: rem(-250);
    position: relative;
    z-index: 10;
    padding-top: 0;

    @include desktop-sm-down {
      margin-top: rem(-70);
    }

    .main-swiper,
    .cash-registers-map {
      @include desktop-sm-down {
        margin: 0 rem(-$container-padding-md);
        position: relative;
        overflow: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: rem(35);
          background: #FFFFFF;
          border-radius: rem(15) rem(15) rem(0) rem(0);
          position: absolute;
          bottom: rem(-1);
          left: 0;
          right: 0;
          z-index: 2;
        }

        + * {
          margin-top: 0;
        }
      }
    }
    
    .main-swiper__nav {
      @include desktop-sm-down {
        bottom: rem(55);
        right: rem($container-padding-md);
      }
    }

    .page-cover__col {
      &:nth-child(1) {
        @include desktop-sm-down {
          margin-bottom: 0;
        }
      }
    }
  }

  .hero-section__bg {
    &::before {
      height: 115%;
      top: 0;

      @include desktop-sm-down {
        height: 100%;
        top: 0;
      }
    }
  }

}

&_type-5 {
  @include desktop-sm-down {
    padding-bottom: rem(70);
  }

  + div {
    position: relative;
    z-index: 10;
    padding-top: 0;

    @include desktop-sm-down {
      margin-top: rem(-70);
    }

    .main-swiper,
    .cash-registers-map {
      @include desktop-sm-down {
        margin: 0 rem(-$container-padding-md);
        position: relative;
        overflow: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: rem(35);
          background: #FFFFFF;
          border-radius: rem(15) rem(15) rem(0) rem(0);
          position: absolute;
          bottom: rem(-1);
          left: 0;
          right: 0;
          z-index: 2;
        }

        + * {
          margin-top: 0;
        }
      }
    }
    
    .main-swiper__nav {
      @include desktop-sm-down {
        bottom: rem(55);
        right: rem($container-padding-md);
      }
    }

    .page-cover__col {
      &:nth-child(1) {
        @include desktop-sm-down {
          margin-bottom: 0;
        }
      }
    }
  }

  .hero-section__bg {
    &::before {
      height: 115%;
      top: 0;

      @include desktop-sm-down {
        height: 100%;
        top: 0;
      }
    }
  }
}

&_type-6 {
  &::after {
    display: none;
  }

  .hero-section__bg {
    &::before {
      height: 123%;

      @include desktop-sm-down {
        height: 100%;
      }
    }
  }

  + div {
    @include desktop-sm-down {
      margin-top: rem(-15);
      position: relative;
      z-index: 6;
      margin-bottom: rem(20);
    }
  }
}


