.tickets-location {
  h3,
  h4 {
    margin-bottom: 0;

    @include desktop-sm-down {
      margin-bottom: rem(10);
    }
  }

  > div {
    @include desktop-sm-down {
      &:not(:last-child) {
        margin-bottom: rem(24);
      }
    }
  }

  &__markers {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #9bc1d7;
    display: flex;

    @include desktop-sm-down {
      font-size: rem(12);
    }

    li {
      display: flex;
      align-items: center;
      margin-top: rem(6);

      &:not(:last-child) {
        margin-right: rem(22);
      }

      &::before {
        content: "•";
        display: inline-block;
        font-size: rem(30);
        margin-right: rem(7);
        position: relative;
        top: rem(-2);

        @include desktop-sm-down {
          font-size: rem(20);
        }
      }

      &:nth-child(1) {
        &::before {
          color: #48b85e;
        }
      }
    }
  }

  .btn {
    font-size: rem(14);
  }
}
