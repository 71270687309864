.card-j {
  position: relative;
  z-index: 1;
  font-size: rem(18);
  line-height: 140%;
  color: #FFFFFF;
  height: 100%;
  padding: rem(10) 0;

  @include desktop-sm-down {
    font-size: rem(16);
    padding: rem(5) 0;
  }


  &::before {
    content: "";
    display: block;
    width: 80%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(156.96% 156.96% at 5% 100%, rgba(0, 163, 218, 0.04) 0%, rgba(33, 117, 207, 0.04) 100%), #EDF6FC;
    border-radius: rem(30);

    @include desktop-sm-down {
      padding: rem(25) rem(35);
      border-radius: rem(20);
      width: 87%;
    }
  }

  &__cover {
    padding: rem(130) rem(25) rem(45);
    background: radial-gradient(156.96% 156.96% at 5% 100%, #00A3DA 0%, #2175CF 100%), #EDF6FC;
    border-radius: rem(30);
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include desktop-sm-down {
      padding: rem(25) rem(35);
      border-radius: rem(20);
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url("../img/images/squares-bq2.png") no-repeat 50% rem(-80) / rem(356);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      @include desktop-sm-down {
        background: url("../img/images/squares-bq2-mob.png") no-repeat rem(-70) 50% / rem(132);
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }
}