.header_b-header {

  .container {
    padding: 0 rem($container-padding-lg);

    @include desktop-sm-down {
      padding: 0 rem($container-padding-md);
    }
  }
 
  .header {
    &__inner {
      max-width: 100%;
      background: #DEE8EE;
    }

    &__top {
      background: #DEE8EE;
    }

    &__main {
      background: #F0F6FB;
      border-radius: rem(40) rem(40) rem(0) rem(0);
    }

    &__logo {
      max-width: rem(277);
    }

    &__btn-lang {
      background: #123063;
      color: #fff;

      &:hover {
        background: #1B407E;
      }
    }

    &__top-nav {
      ul {
        li {

          &.active {
            background: #F0F6FB;
          }
          &:after,
          &:before {
            background-color: #DEE8EE;
          }
        }
      }
    }

    &__search {
      background: #FFFFFF;
    }

    &__search-main {
      border-top: none;
    }

    &__search-main-inner {
      border-top: rem(2) solid #edf6fc;
    }
  }

  &.fixed {
    .header__inner {
      max-width: 100%;
      filter: drop-shadow(rem(0) rem(4) rem(33) rgba(145, 201, 227, 0.12));
    }
  }
}