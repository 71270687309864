.swiper-block {
  position: relative;
}


.btn-fixed {
  bottom: rem(70);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 95;

  @include desktop-sm-up {
    display: none !important;
  }

  .btn {
    display: flex;
    width: 100%;
    margin: 0 auto;
  
    @include desktop-sm-down {
      height: rem(50);
      font-size: rem(14);
    }
  }
}

.two-btns-fixed {
  bottom: rem(70);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 80;

  @include desktop-sm-up {
    display: none !important;
  }

  .container {
    display: flex;
  }

  .btn {
    display: flex;
    width: 100%;
    margin: 0 auto;
    font-size: rem(14);
    
  
    @include desktop-sm-down {
      height: rem(50);
      font-size: rem(14);
    }
    

    &:not(:last-child) {
      margin-right: rem(10);
    }

    &:nth-child(1) {
      width: calc(57% - rem(5));
    }

    &:nth-child(2) {
      width: calc(43% - rem(5));
    }

    .icon {
      font-size: rem(16);
      margin-right: rem(10);
    }
  }

  .btn_ticket {
    font-size: rem(14);

    .icon {
      font-size: rem(16);
      margin-right: rem(10);
    }
  }

  .btn_parking {
    background: #123063;

    &:hover {
      background: #11387A;
    }
  }
}

.back-btn {
  background: #247AC6;
  padding: rem(11) rem(19) rem(13) rem(16);
  color: #A1D5F6;
  font-size: rem(12);
  line-height: rem(15);
  height: auto;

  @include desktop-sm-down {
    width: auto;
    display: flex;
    justify-content: flex-start;
    height: rem(40);
    padding: 0 rem($container-inner-padding-md);
    margin: 0 rem(-$container-inner-padding-md);
    border-radius: 0;
  }

  .icon {
    font-size: rem(6);
    margin-right: rem(14);
  }

  &:hover {
    background: #30C8FB;
    color: #fff;
  }

  &_b {
    background: #DEE8EE;
    color: #123063;
  }
}

.forbiddance-block {
  padding: rem(40) rem(100);
  background: #EFF5FB;
  border-radius: rem(30);
  position: relative;
  margin-top: rem(50);

  @include desktop-sm-down {
    margin-top: rem(25);
    border-radius: rem(20);
    padding: rem(17) rem($container-inner-padding-md) rem(25);
  }

  > .icon {
    font-size: rem(29);
    color: #EF577F;

    @include desktop-sm-up {
      position: absolute;
      top: rem(40);
      left: rem(50);
    }

    @include desktop-sm-down {
      font-size: rem(15);
      margin-bottom: rem(15);
    }
  }

  h3 {
    color: #EF577F;
    margin-bottom: rem(32);
    margin-top: 0;

    @include desktop-sm-down {
      margin-bottom: rem(10);
      font-size: rem(14);
    }
  }

  p {
    font-weight: 500;
    font-size: rem(18);
    line-height: 150%;
    color: #123063;
    margin-top: 0;

    @include desktop-sm-down {
      font-size: rem(12);
    }
  }
}

.page-tags {
  margin-top: rem(25);
  margin-bottom: rem(40);
  display: flex;
  align-items: center;

  @include desktop-sm-down {
    margin-bottom: rem(40);
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(-15);

    @include desktop-sm-down {
      margin-bottom: rem(-5);
    }

    li {
      background: rgba(255, 255, 255, 0.12);
      backdrop-filter: blur(rem(4));
      border-radius: rem(100);
      font-weight: bold;
      font-size: rem(12);
      line-height: rem(12);
      display: inline-block;
      color: #FFFFFF;
      padding: rem(10) rem(25);
      margin-bottom: rem(15);

      @include desktop-sm-down {
        font-size: rem(10);
        line-height: rem(10);
        margin-bottom: rem(5);
        padding: rem(8) rem(10)
      }

      &:not(:last-child) {
        margin-right: rem(16);

        @include desktop-sm-down {
          margin-right: rem(5);
        }
      }
    }
  }

  .share-btn {
    margin-left: rem(15);
    margin-left: auto;
  }

}

.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(26);
  height: rem(26);
  font-size: rem(13);
  border-radius: 50%;
  color: #00A3DA;
  background-color: #fff;
  backdrop-filter: blur(rem(2));
  padding: 0;

  .icon-share {
    margin-right: rem(1);
  }
}

.block-hidden {
  &__overflow {
    overflow: hidden;
    max-height: rem(180);
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: rem(55);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.show {
      max-height: 100%;

      &:after {
        display: none;
      }
    }

    + button {
      font-weight: bold;
      font-size: rem(18);
      line-height: 150%;
      color: #00A3DA;
      margin-top: rem(20);

      @include desktop-sm-down {
        font-size: rem(16);
      }

      &:hover {
        text-decoration: underline;
        color: #0194d8;
      }
    }
 
  }
}

.list-rhombus {

  li {
    line-height: 170%;
    font-size: rem(16);
    padding-left: rem(10);
    position: relative;

    @include desktop-sm-down {
      font-size: rem(14);
    }

    &:before {
      content: "";
      display: block;
      width: 0.3214em;
      height: 0.3214em;
      transform: rotate(45deg);
      background-color: #00A3DA;
      margin-right: rem(20);
      margin-top: 0.65em;
      flex-shrink: 0;
      position: absolute;
      top: 0;
      left: 0;

      @include desktop-sm-down {
        margin-right: rem(10);
        font-size: inherit
      }
    }
  }
}

.rhombus {
  display: block;
  width: 0.3214em;
  height: 0.3214em;
  transform: rotate(45deg);
  background-color: #00A3DA;
}

.cash-registers-map {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: rem(30);
  min-height: rem(400);

  @include desktop-sm-down {
    border-radius: rem(20);
    height: rem(280);
    min-height: 0;
    margin: 0 rem(-$container-padding-md);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: 3s;
  }
}

.lodges-benefits {
  margin: rem(70) 0;

  @include desktop-sm-down {
    margin: rem(35) 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .col {
    width: 16.66%;

    @include desktop-sm-down {
      width: 33.33%;
    }

    @include tablets-down {
      width: 50%;
    }

    @include phones-lg-down {
      width: 100%;
    }
  }

}

.b-last-section {
  margin-bottom: rem(-30);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  @include desktop-sm-down {
    margin-bottom: rem(-15);
  }
}

.contact-block {
  background: #E4F0F8;
  border-radius: rem(40);
  padding: rem(60) rem(50);
  display: flex;
  align-items: center;
  margin-bottom: rem(24);

  @include desktop-sm-down {
    flex-direction: column;
    align-items: flex-start;
    padding: rem(35) rem($container-inner-padding-md);
    padding-bottom: rem(50);
    border-radius: rem(20) rem(20) rem(0) rem(0);
    margin: 0 rem(-$container-inner-padding-md);
  }


  &:last-child {
    margin-bottom: rem(48);
  }

  &__item {
    &:not(:last-child) {
      margin-right: rem(130);

      @include desktop-sm-down {
        margin-right: 0;
        margin-bottom: rem(15);
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #91C9E3;
    margin-bottom: rem(6);
  }

  &__desc {
    font-weight: bold;
    font-size: rem(22);
    line-height: rem(28);
    letter-spacing: 0.02em;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(16);
      line-height: rem(20);
    }

    &[href*="mailto"] {
      color: #0194D8;

      &:hover {
        text-decoration: underline;
        color: #00A3DA;
      }
    }
  }
}

.not-found {
  text-align: center;
  max-width: rem(830);
  margin: rem(120) auto;
  

  @include desktop-sm-down {
    margin: rem(60) auto rem(80);
  }

  &__title {
    font-weight: bold;
    font-size: rem(288);
    line-height: 0.8;
    letter-spacing: 0.02em;
    color: #123063;
    margin-bottom: rem(75);
    

    @include desktop-sm-down {
      font-size: rem(140);
      margin-bottom: rem(35);
    }
  }

  &__desc {
    font-size: rem(20);
    line-height: 150%;
    text-align: center;
    color: #123063;
    max-width: rem(830);
    margin-bottom: rem(80);

    @include desktop-sm-down {
      font-size: rem(16);
      margin-bottom: rem(35);
    }

  }

  .btn {
    padding: 0 rem(50);
    height: rem(65);
    font-size: rem(18);

    @include desktop-sm-down {
      font-size: rem(16);
    }
  }
}