.shops-list {

  ul {
    margin-bottom: rem(-10);

    li {
      margin-bottom: rem(10);
    }
  }

  
  &__item {
    display: block;
    background: #FFFFFF;
    border-radius: rem(30);
    padding: rem(30) rem(100) rem(50);

    @include desktop-sm-down {
      padding: rem(20) rem($b-container-inner-padding-md) rem(30);
    }
  }

  &__item-title {
    font-weight: bold;
    font-size: rem(36);
    line-height: rem(46);
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #1B407E;
    margin-bottom: rem(40);

    @include desktop-sm-down {
      font-size: rem(30);
      line-height: rem(38);
      margin-bottom: rem(11);
    }
  }

  &__item-desc  {
    font-weight: 600;
    font-size: rem(22);
    line-height: rem(28);
    letter-spacing: 0.02em;
    color: #123063;

    &:hover {
      color: #00A3DA;
    }

    @include desktop-sm-down {
      font-size: rem(18);
      line-height: rem(23);
    }
  }
}