.excursions-block {
  margin-bottom: rem(60);
  margin-top: rem(50);

  @include desktop-sm-down {
    margin-bottom: rem(45);
    margin-top: rem(45);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: rem(45);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }
  }

  .col {
    width: 33.33%;

    @include desktop-sm-down {
      width: 50%;
    }

    @include tablets-down {
      width: 100%;
    }
  }
}