.share-block2 {
  padding: rem(35);
  background: rgba(18, 48, 99, 0.56);
  border-radius: rem(30);

  @include desktop-sm-down {
    border-radius: rem(20);  
    padding: rem(25);
  }

  &__title {
    font-weight: 500;
    font-size: rem(12);
    line-height: 140%;
    color: #FFFFFF;
    margin-bottom: rem(20);
  }

  .socials {
    li {
      &:not(:last-child) {
        margin-right: rem(25);
      }
    }
  }
}