.card-lease {
  background: #E4F0F8;
  border-radius: rem(40);
  padding: rem(40);

  @include desktop-sm-down {
    padding: rem(15);
    padding-bottom: rem(35);
    border-radius: rem(20);
  }

  &__img {
    padding-top: 45.2%;
    position: relative;
    border-radius: rem(30);
    overflow: hidden;

    @include desktop-sm-down {
      border-radius: rem(20);
      padding-top: 50.1%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__main {
    padding: rem(30);
    padding-bottom: 0;

    @include desktop-sm-down {
      padding: rem(22) rem(10);
      padding-bottom: 0;
    }

  }

  &__head {
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin-bottom: rem(24);
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(36);
    line-height: rem(46);
    letter-spacing: 0.02em;
    color: #123063;
    margin-bottom: rem(10);

    @include desktop-sm-down {
      font-size: rem(22);
      line-height: rem(28);
      margin-bottom: rem(8);
    }
  }

  &__address {
    font-style: italic;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #91C9E3;

    @include desktop-sm-down {
      font-size: rem(12);
      line-height: rem(15);
    }
  }

  .list-rhombus {
    margin: rem(30) 0;

    @include desktop-sm-down {
      margin: rem(18) 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        font-size: rem(18);
        padding-left: rem(15);

        @include desktop-sm-down {
          width: 100%;
          font-size: rem(14);
          padding-left: rem(10);
        }

        &.last  {
          width: 100%;
          

          @include desktop-sm-up {
            order: 100;
          }
        }
      }
    }
  }

  hr {
    margin: rem(40) 0;
    border: rem(1) solid #F0F6FB;

    @include desktop-sm-down {
      display: none;
    }
  }

  &__contacts {
    margin: rem(40) 0;
    display: flex;
    flex-wrap: wrap;

    @include desktop-sm-down {
      margin-top: rem(18);
      margin-bottom: rem(35);
    }
  }

  &__contacts-item {
    width: 50%;

    @include desktop-sm-down {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }
  }

  &__contacts-title {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #91C9E3;
    margin-bottom: rem(3);

    @include desktop-sm-down {
      font-size: rem(12);
    }
  }

  &__contacts-desc {
    font-size: rem(26);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #123063;
    font-weight: 500;

    @include desktop-sm-down {
      font-size: rem(22);
    }
  }

  &__btn-request {
    height: rem(65);
    padding: 0 rem(50);
    font-size: rem(18);

    @include desktop-sm-down {
      height: rem(60);
      width: 100%;
      font-size: rem(14);
      padding: 0 rem(15);
    }
  }
}