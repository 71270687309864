&__search-main {
  border-top: rem(2) solid #edf6fc;
}

&__search-head-inner {
  display: flex;
  align-items: center;
  height: rem(110);
  padding-top: rem(30);
  padding-bottom: rem(30);
}

&__search-ico {
  margin-right: rem(36);
  margin-top: rem(5);

  .icon {
    font-size: rem(29);
    color: #123063;
  }
}

&__search-input {
  flex-grow: 1;
  font-family: inherit;
  font-size: rem(22);
  color: #123063;
  font-weight: 600;
  background: none;
  border: none;
  outline: none;

  &::placeholder {
    opacity: 1;
    color: #89b6e3;
  }
}

&__search-btn-clear {
  width: rem(50);
  height: rem(50);
  background: #ebf4f9;

  .icon {
    font-size: rem(13);
    color: #89b6e3;
  }

  &:hover {
    background: #123063;
    color: #fff;

    .icon {
      color: #fff;
    }
  }

  + .btn {
    margin-left: rem(18);
  }
}

&__search-btn-go {
  background: #ebf4f9;
  font-weight: 600;
  color: #0194d8;
  height: rem(50);
  padding: 0 rem(35);

  &:hover {
    background: #123063;
    color: #fff;
  }
}

&__search-main-inner {
  padding-top: rem(40);
  padding-bottom: rem(40);
}

&__search-result {
  li {
    &:not(:last-child) {
      margin-bottom: rem(24);
    }

    a {
      * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  h5 {
    font-weight: 600;
    font-size: rem(18);
    line-height: 130%;
    color: #0194d8;
    margin-bottom: rem(12);
    margin-top: 0;
  }

  p {
    font-size: rem(14);
    line-height: 150%;
    color: #123063;
    max-width: rem(606);
    margin: 0;
  }
}
