.card-h {
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: rem(30);
  height: rem(570);
  position: relative;

  @include desktop-sm-down {
    border-radius: rem(20);
    height: auto;
    padding-top: 63%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: 3s;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}