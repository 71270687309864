.table {
  border-radius: rem(30);
  overflow: hidden;
  border-collapse: collapse;
  width: 100%;

  @include desktop-sm-down {
    border-radius: rem(15);
  }

  thead {
    @include desktop-sm-down {
      display: block;
      height: rem(15);
      background: #00A3DA;
    }
   

    th {
      background: #00A3DA;
      height: rem(30);
      color: #fff;
      font-weight: bold;
      font-size: rem(10);
      line-height: 140%;
      padding: 0 rem(30);

    }

    tr {
      @include desktop-sm-down {
        display: none;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {

        td {
          background: #F3F8FB;

          @include desktop-sm-down {
            background: none;
          }
        }

        @include desktop-sm-down {
          background: #F3F8FB;
        }
      }

      &.style-2 {

        @include desktop-sm-down {
          background-color: #fff;
          border-bottom: rem(1) solid #F3F8FB;
        }
        
        td {
          background-color: #fff;

          &:not(:first-child) {
            border-bottom: rem(1) solid #F3F8FB;

            @include desktop-sm-down {
              border-bottom: none;
            }
          }

          &:last-child {
            @include desktop-sm-down {
              
            }
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    tr {
      @include desktop-sm-down {
        display: block;
        padding: rem(15);
      }
    }

    td {
      height: rem(50);
      font-size: rem(12);
      line-height: 140%;
      color: #123063;
      padding: rem(15) rem(30);

      @include desktop-sm-down {
        display: block;
        height: auto;
        width: 100%;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: rem(10);
        }

        &:before {
          content: attr(aria-label);
          display: block;
          font-weight: bold;
          font-size: rem(10);
          line-height: 140%;
          color: #9BC1D7;
        }
      }
    }
  }
}

.arena-table {
  background: #FFFFFF;

  tbody {
    td {
      width: rem(100);

      @include desktop-sm-down {
        width: 100%;
      }

      &:nth-child(1) {
        font-weight: 600;

        span {
          color: #9BC1D7;

          @include desktop-sm-down {
            font-size: rem(10);
          }
        }
      }

      &:last-child {
        text-align: center;

        @include tablets-sm-down {
          text-align: left;
        }
      }
    } 
  }
}