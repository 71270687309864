.subscribe-form {
  background: #ffffff;
  border-radius: rem(15);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(3);
  width: 100%;
  border: rem(2) solid transparent;
  transition: .3s;;

  &.error {
    border-color: #EF577F;
  }

  &__input {
    flex-grow: 1;
    position: relative;
    margin-right: rem(5);
  }

  input {
    padding: 0 rem(40);
    padding-right: rem(70);
    width: 100%;
    height: rem(65);
    background: #fff;
    border: none;
    outline: none;
    font-family: inherit;
    font-weight: 600;
    font-size: rem(18);

    @include desktop-sm-down {
      font-size: rem(12);
      padding: 0 rem($container-inner-padding-md);
      padding-right: rem(30);
      height: rem(38);
    }

    &::placeholder {
      opacity: 1;
      color: #a2a6d4;
    }
  }

  &__btn-clean {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: rem(70);
    align-items: center;
    justify-content: center;

    @include desktop-sm-down {
      width: rem(30);
    }

    .icon {
      font-size: rem(13);
      color: #89b6e3;
    }
  }

  &__button {
    height: rem(65);
    background: #0e1248;
    padding: 0 rem(60);
    color: #fff;

    @include desktop-sm-down {
      width: rem(38);
      height: rem(38);
      padding: 0;
      font-size: 0;
    }

    .icon {
      font-size: rem(8);

      @include desktop-sm-up {
        display: none;
      }
    }

    &:hover {
      background: #143977;
    }
  }

  &__message {
    padding: rem(15) rem(30);
    padding-top: rem(14);
    border-radius: rem(15);
    position: absolute;
    top: rem(-11);
    left: 50%;
    transform: translate(-50%, -100%);
    font-weight: 600;
    font-size: rem(12);
    line-height: 1;
    text-align: center;
    color: #ffffff;
    display: none;
    white-space: nowrap;

    &:after {
      content: "";
      border: rem(5) solid transparent;
      border-top: rem(5) solid transparent;
      display: block;
      position: absolute;
      bottom: rem(1);
      left: 50%;
      transform: translate(-50%, 100%);
    }

    &.error {
      background: #ef577f;

      &:after {
        border-top-color: #ef577f;
      }
    }
  }
}


