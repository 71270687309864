.footer {
  background: #0e1248;
  border-radius: rem(40) rem(40) rem(0) rem(0);
  padding-top: rem(90);
  padding-bottom: rem(50);
  color: #fff;
  position: relative;
  z-index: 3;

  @include desktop-sm-down {
    padding-top: rem(45);
    padding-bottom: rem(80);
    border-radius: 0;
    z-index: 1;
  }

  &__row {
    display: flex;
    margin: 0 rem(-15);

    @include desktop-sm-down {
      flex-wrap: wrap;
    }

    &:not(:first-child) {
      margin-top: rem(110);

      @include desktop-sm-down {
        margin-top: 0;
      }
    }

    @include desktop-sm-down {
      @include tablets-sm-up {
        &:nth-child(1) {
          .footer__col {
            &:nth-child(1) {
              order: 1;
            }

            &:nth-child(2) {
              order: 3;
            }

            &:nth-child(3) {
              order: 4;
            }

            &:nth-child(4) {
              order: 2;
            }
          }
        }

        &:nth-child(2) {
          .footer__col {
            &:nth-child(1) {
              order: 2;
            }

            &:nth-child(2) {
              order: 1;
            }

            &:nth-child(3) {
              order: 3;
            }

            &:nth-child(4) {
              order: 4;
            }
          }
        }
      }
    }
  }

  &__col {
    width: 25%;
    padding: 0 rem(15);
    position: relative;
    flex-shrink: 0;

    @include desktop-sm-down {
      width: 50%;
    }

    @include tablets-sm-down {
      width: 100%;
    }

  }

  &__logo {
    display: block;
    max-width: rem(270);
    margin-bottom: rem(55);

    @include desktop-sm-down {
      max-width: rem(230);
      margin-bottom: rem(30);
    }
  }

  &__nav {
    @include desktop-sm-down {
      margin-bottom: rem(20);
    }

    ul {
      li {
        font-weight: 600;
        font-size: rem(20);
        line-height: 1;

        @include desktop-sm-down {
          font-size: rem(15);
        }

        &:not(:last-child) {
          margin-bottom: rem(35);

          @include desktop-sm-down {
            margin-bottom: rem(20);
          }
        }

        a {
          &:hover {
            color: #0194d8;
          }
        }
      }
    }
  }

  &__tel {
    font-weight: 600;
    font-size: rem(36);
    line-height: 1;
    margin-bottom: rem(15);
    display: inline-block;

    @include desktop-sm-down {
      font-size: rem(30);
      margin-bottom: rem(10);
      margin-top: rem(15);
    }
  }

  &__call-back {
    font-weight: 600;
    font-size: rem(18);
    line-height: 1;
    color: hsl(195, 100%, 43%);
    display: inline-block;

    @include desktop-sm-down {
      font-size: rem(15);
      margin-bottom: rem(37);
    }

    &:hover {
      color: #30C8FB;
    }
  }

  &__copyring {
    font-weight: bold;
    font-size: rem(16);
    line-height: 1;

    @include desktop-sm-down {
      margin-bottom: rem(25);
    }
  }

  &__desc {
    color: #989BC3;
    font-size: rem(16);
    line-height: 120%;
    display: inline-block;

    @include desktop-sm-down {
      font-size: rem(12);
      margin-bottom: rem(13);
    }
  }

  a.footer__desc {
    &:hover {
      text-decoration: underline;
    }
  }

  &__go-btn {
    padding: 0 rem(45);
    height: rem(60);
    margin-top: rem(40);

    @include desktop-sm-down {
      margin-top: rem(30);
      transform: translate(0);
      width: 100%;
    }

    @include desktop-sm-down {
      @include tablets-sm-up {
        margin-top: 0;
      }
    }

    &:first-child {
      @include desktop-sm-up {
        margin-top: 0;
      }
    }

    &:hover {
      background: #0194D8;
    }
  }

  .socials {
    @include desktop-sm-down {
      margin-bottom: rem(38);
    }
  }
}

.footer_b-footer {
  .footer {

    &__logo {
      max-width: rem(277)
    }

    &__nav {
      @include desktop-sm-down {
        margin-bottom: rem(40);
      }

      ul {
        @include desktop-sm-up {
          display: flex;
          flex-wrap: wrap;
          margin-right: rem(-75);
          margin-bottom: rem(-40);
        }

        li {
          @include desktop-sm-up {
            display: inline-block;
            margin-right: rem(75);
            margin-bottom: rem(40);
          }
        }
      }
      
    }

    &__copyring {
      @include desktop-sm-down {
        margin-bottom: rem(20);
      }
    }

    &__row {
      &:first-child {
        .footer__col {
          flex-shrink: 0;

          &:nth-child(2) {
            width: auto;
            flex-grow: 1;
            flex-shrink: 1;
          }

          &:nth-child(3) {
            width: auto;
          }
        }
      }

      &:not(:first-child) {
        @include desktop-sm-up {
          margin-top: rem(55);
        }
      }
    }
  }
}