.visitor-actions {
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;

    &:not(:last-child) {
      margin-right: rem(25);
    }

    li {
      &:not(:last-child) {
        margin-right: rem(25);
      }
    }
  }

  .btn {
    height: rem(56);
    padding: 0 rem(20);
    font-size: rem(14);

    .icon {
      margin-right: rem(10);
      font-size: rem(16);
    }
  }

  .btn_parking {
    background: rgba(255, 255, 255, 0.3);

    &:hover {
      background: #11387a;
    }
  }
  

  .btn_book {
    border: rem(2) solid #00A3DA;
    color: #FFFFFF;

    &:hover {
      background: #11387a;
      border-color: #11387a;
    }
  }
}

