.content-actions {
  display: flex;
  flex-wrap: wrap;

  @include desktop-sm-up {
    margin-top: rem(30);
    display: none;
  }

  .btn {
    display: flex;
    width: 100%;
    margin: 0 auto;
    font-size: rem(14);
    

    @include desktop-sm-down {
      height: rem(50);
      font-size: rem(14);
    }

    &:not(:last-child) {
      margin-bottom: rem(15);
    }
    
    &:nth-child(1) {
      width: calc(57% - rem(5));
      margin-right: rem(10);
    }

    &:nth-child(2) {
      width: calc(43% - rem(5));
    }

    .icon {
      font-size: rem(16);
      margin-right: rem(10);
    }
  }

  .btn_ticket {
    font-size: rem(14);

    .icon {
      font-size: rem(16);
      margin-right: rem(10);
    }
  }

  .btn_parking {
    background: #123063;

    &:hover {
      background: #11387A;
    }
  }

  .btn_book {
    border: rem(2) solid #00A3DA;
    color: #00A3DA;

    &:hover {
      background: #11387a;
      border-color: #11387a;
      color: #fff;
    }
  }
}
