.photo-gallery {
  margin: rem(60) 0;

  @include desktop-sm-down {
    margin: rem(30) 0;
  }

  &__title {
    margin-bottom: rem(60);

    @include desktop-sm-down {
      margin-bottom: rem(40);
    }
  }

  .col {
    
    &.w-50 {
      .card-h {
        height: auto;
        padding-top: 65%;
      }

      &.small {
        width: 37.35%;
    
        @include desktop-sm-down {
          width: 50%;
        }

        @include tablets-sm-down {
          width: 100%;
        }

        .card-h {
          padding-top: 65%;
      
        }
      }
    }
  }

  &__col-btn {
    flex-grow: 1;
  }

  

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-weight: bold;
    font-size: rem(24);
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    background: #11387A;
    padding: 0 rem(30);
    overflow: hidden;

    @include desktop-sm-down {
      border-radius: rem(15);
      height: rem(60);
      font-size: rem(14);
      margin-top: rem(10);

      br {
        display: none;
      }
    }

    &::before {
      content: "";
      display: block;
      width: rem(280);
      height: rem(180);
      border-radius: 50%;
      background: #00A3DA;
      filter: blur(rem(50));
      position: absolute;
      top: rem(-83);
      left: rem(-125);
      transition: .3s;
    }

    &::after {
      content: "";
      display: block;
      width: rem(280);
      height: rem(180);
      border-radius: 50%;
      background: #4A36C6;
      filter: blur(rem(50));
      position: absolute;
      bottom: rem(-29);
      right: rem(-120);
      transition: .3s;
    }

    span {
      position: relative;
      z-index: 2;
    }

    &:hover {
      &::before {
        background: #4A36C6;
      }
  
      &::after {
        background: #00A3DA;
      }
    }
  }
}

.container_sm {
  .photo-gallery__btn {
    @include desktop-sm-up {
      font-size: rem(18);
    }
  }
}