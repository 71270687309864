.popup-vip-book {
  .popup__inner {
    padding: 0;
    max-width: rem(1010);
  }

  .popup__title {
    margin-bottom: rem(25);

    @include desktop-sm-down {
      margin-bottom: rem(13);
    }
    
  }

  .form__btn-submit + .form__agreement {
    margin-top: rem(35);
    margin-bottom: 0;

    @include desktop-sm-down {
      margin-top: rem(13);
    }
  }
}