&__top {
  background: #edf6fc;
}

&__top-inner {
  height: rem(60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}

&__top-nav {
  margin-left: rem(-30);

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0 rem(15);
      margin-right: rem(-15);
      position: relative;

      &:first-child {
        padding-left: 0;

        a {
          padding-left: rem(30);
        }

        &::before {
          display: none !important;
        }
      }

      &::before,
      &::after {
        content: "";
        display: none;
        width: rem(15);
        height: 100%;
        background-color: #edf6fc;
        position: absolute;
        bottom: 0;
      }

      &::before {
        left: 0;
        border-radius: 0 0 rem(15) 0;
      }

      &::after {
        right: 0;
        border-radius: 0 0 0 rem(15);
      }

      a {
        border-top: rem(3) solid transparent;
        border-bottom: rem(3) solid transparent;
        height: rem(60);
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: rem(15);
        line-height: rem(15);
        padding: 0 rem(35);
        transition: 0.3s;

        img {
          flex-shrink: 0;
          width: rem(40);
          margin-right: rem(25);
          transition: 0.3s;
        }

        &:hover {
          color: #0194d8;
        }
      }

      &.active {
        background: #fff;
        z-index: 2;

        a {
          border-top-color: #009fe3;
        }

        &::before,
        &::after {
          display: block;
        }
      }
    }
  }
}

&__top-time  {
  margin-left: auto;
  margin-right: rem(42);
  font-weight: 600;
  font-size: rem(18);
  line-height: rem(23);
  color: #123063;

  b {
    font-weight: bold;
  }
}

&__search-btn {
  width: rem(30);
  height: rem(30);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  .icon {
    color: #123063;
  }

  .icon-search {
    font-size: rem(29);
    transition: .3s;
  }

  .icon-cross {
    font-size: rem(27);
    transition: .3s;
  }

  &:not(.close) {
    .icon-cross {
      display: none;
    }
  }

  &.close {
    .icon-search {
      display: none;
    }
  }

  &:hover {
    .icon {
      color: #0194d8;
    }
  }
}
