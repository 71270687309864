.card-b {
  display: block;
  position: relative;
  border-radius: rem(30);
  overflow: hidden;

  @include desktop-sm-down {
    border-radius: rem(15);
  }

  &:hover {
    .card-b__img img {
      transform: scale(1.2);
    }
  }

  &__img {
    padding-top: 119%;
    background: #161a41;
    position: relative;
    overflow: hidden;

    @include desktop-sm-down {
      padding-top: 114%;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        179.96deg,
        rgba(14, 18, 72, 0) 0.04%,
        #0e1248 99.97%
      );
      mix-blend-mode: darken;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      @include desktop-sm-down {
        bottom: rem(-30);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__main {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    padding: rem(25) rem(20);
    color: #fff;
    box-sizing: border-box;

    @include desktop-sm-down {
      padding: rem(15) rem(12);
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(28);
    line-height: 110%;
    color: #ffffff;
    margin-bottom: rem(20);

    @include desktop-sm-down {
      font-size: rem(16);
      line-height: 120%;
      margin-bottom: rem(8);
    }
  }

  &__date {
    font-weight: 600;
    font-size: rem(13);
    line-height: 1;
    display: flex;
    color: #ffffff;

    @include desktop-sm-down {
      font-size: rem(10);
      font-weight: normal;
      font-style: italic;
    }

    &.disabled {
      color: #3e4164;
    }
  }

  &__time {
    font-style: italic;
    color: #ffffff;

    &::before {
      content: "•";
      color: #00a3da;
      margin: 0 rem(8);
      line-height: 0.5;
    }
  }

  &__footer {
    font-weight: 600;
    font-size: rem(14);
    line-height: 1;
    text-align: center;
    color: #ffffff;
    padding: rem(25);
    background: #00a3da;

    @include desktop-sm-down {
      font-size: rem(12);
      padding: rem(18);
    }
  }

  &.secondary {
    .card-b__footer {
      background: #232870;
    }

    .card-b__time {
      &::before {
        color: #da4e00;

        @include desktop-sm-down {
        }
      }
    }
  }
}
