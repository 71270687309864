.share-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 0;

  &__title {
    font-size: rem(12);
    line-height: 140%;
    color: #CAE4FD;
    margin-right: rem(30);
    max-width: rem(130);

    @include phones-lg-down {
      margin-right: rem(18);
    }
  }

  .socials {

    li {
      &:not(:last-child) {
        margin-right: rem(10)
      }
    }

    a {
      color: rgba(#fff, 0.59);
    }

    a:hover {
      color: #fff;
    }
  }
}