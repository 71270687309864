.rules-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: rem(570);

  @include desktop-sm-down {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: rem(2) solid #ffffff;
    padding-bottom: rem(20);
  }

  &__logo {
    @include desktop-sm-down {
      padding-bottom: rem(20);
      border-bottom: rem(2) solid #ffffff;
      margin-bottom: rem(20);
      width: 100%;
    }

    img {
      max-height: rem(80);

      @include desktop-sm-down {
        max-height: rem(66);
        margin: 0 auto;
      }
    }
  }

  &__content {
    @include desktop-sm-down {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #9bc1d7;

    @include desktop-sm-down {
      font-size: rem(10);
      width: 40%;
    }
  }

  &__desc {
    font-size: rem(36);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #123063;
    font-weight: 500;

    @include desktop-sm-down {
      font-size: rem(20);
    }

    
  }
}
