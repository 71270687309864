.popup-entry {
  .popup__inner {
    max-width: rem(520);
    padding-bottom: 0;
  }

  .form__btn-registry {
    margin: 0 rem(-40);
    border-top: rem(1) solid #9BC1D7;
    height: rem(80);
    border-radius: 0;
    display: flex;
    color: #00A3DA;
    margin-top: rem(30);
    font-size: rem(18);
    background: none;
    width: calc(100% + rem(80));

    @include tablets-down {
      width: 100%;
      margin: 0;
      margin-top: rem(30);
    }

    &:hover {
      text-decoration-line: underline;
      color: #0194D8;
      background: none;
    }

  }
}