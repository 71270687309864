.card-stock {
  background: linear-gradient(180deg, #64B8DA 0%, #70BFDD 100%);
  padding: rem(80);
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: rem(480);
  box-sizing: border-box;

  @include desktop-sm-up {
    border-radius: rem(15);
  }

  @include desktop-sm-down {
    min-height: rem(275);
    padding: rem(42) rem($container-padding-lg);
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: 0.3s ease;
  }

  &__cover {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-weight: 800;
    font-size: rem(78);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: rem(70);
    max-width: rem(800);

    @include desktop-sm-down {
      font-size: rem(30);
      margin-bottom: rem(24);
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(-15);
  }

  &__btn {
    background: #FFFFFF;
    border-radius: rem(15);
    height: rem(65);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(200);
    margin-bottom: rem(15);
    font-weight: 600;
    font-size: rem(18);
    line-height: rem(23);
    color: #0194D8;
    padding: rem(10) rem(25);
    transition: .3s;

    @include desktop-sm-down {
      width: rem(158);
      height: rem(44);
      font-size: rem(14);
    }

    &:hover {
      background: #DEE8EE;
    }

    &:not(:last-child) {
      margin-right: rem(15);
    }
  }

  

}