.poster-section {
  margin-top: rem(60);
  margin-bottom: rem(60);

  @include desktop-sm-down {
    margin-top: rem(20);
    margin-bottom: rem(40);
  }
 

  .section-head {
    margin-bottom: rem(45);

    @include desktop-sm-down {
      margin-bottom: rem(24);
    }
  }

  .see-all {
    margin-top: rem(25);
  }
}
