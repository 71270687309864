.scheme-section {
  margin: rem(90) 0;

  @include desktop-sm-down {
    margin: rem(40) 0;
  }

  &__inner {
    background: #0D4D8F;
    border-radius: rem(30);
    position: relative;
    overflow: hidden;
    color: #fff;

    @include desktop-sm-down {
      border-radius: rem(15);
    }

    &:not(:last-child) {
      margin-bottom: rem(50);

      @include desktop-sm-down {
        margin-bottom: rem(30);
      }
    }

    &::after {
      content: "";
      display: block;
      width: rem(1080);
      height: rem(700);
      border-radius: 50%;
      background: #00A3DA;
      filter: blur(rem(200));
      position: absolute;
      top: rem(-270);
      right: rem(-590);

      @include desktop-sm-down {
        width: rem(544);
        height: rem(352);
        top: rem(-120);
        right: rem(-386);
      }
    }
  }

  &__cover {
    padding: rem(80) rem(130) rem(60);
    position: relative;
    z-index: 2;

    @include desktop-sm-down {
      padding: rem(40) rem($container-inner-padding-md) 0;
    }
  }

  &__title {
    max-width: rem(900);
    margin-bottom: rem(35);

    @include desktop-sm-down {
      margin-bottom: rem(15);
    }
  }

  h2 {
    @include desktop-sm-down {
      font-size: rem(24);
      line-height: 130%;
    }
  }

  &__desc {
    font-weight: bold;
    font-size: rem(18);
    line-height: 150%;
    margin-bottom: rem(35);
    max-width: rem(750);

    @include desktop-sm-down {
      font-size: rem(14);
      margin-bottom: rem(25);
    }
  }

  &__list-cover {
    display: flex;

    @include desktop-sm-down {
      margin-bottom: rem(30);
      flex-wrap: wrap;
    }
    
  }

  &__list {
    margin-bottom: rem(35);
    padding-right: rem(50);
    width: 50%;

    @include desktop-sm-down {
      padding-right: 0;
      margin-bottom: 0;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: rem(15);
      }
    }
  

    li {
      font-size: rem(22);
      line-height: 170%;
      padding-left: rem(40);
      position: relative;

      @include desktop-sm-down {
        font-size: rem(14);
      }

      &::before {
        content: "";
        display: block;
        width: rem(9);
        height: rem(9);
        position: absolute;
        top: rem(13);
        left: 0;
        transform:  rotate(45deg);
        background: #00A3DA;

        @include desktop-sm-down {
          top: rem(7);
        }
      }

      
    }
  }

  .main-swiper {

    @include desktop-sm-down {
      margin: 0 rem(-$container-inner-padding-md);
      border-radius: rem(15);
    }
  }

  .main-swiper .swiper-slide {
    padding-top: 0;

    img {
      position: relative;
      top: auto;
      left: auto;
    }
  }
}