&__rules {
  background: #eff5fb;
  border-radius: rem(30);
  padding: rem(40) rem(50) rem(50);
  margin-bottom: rem(20);

  @include desktop-sm-down {
    padding: rem(25) rem($container-inner-padding-md) rem(35);
    margin-bottom: rem(10);
  }

  &_primary {
    background: #0194D8;
    color: #fff;
    border-radius: rem(30);
  }

  h3 {
    margin-bottom: rem(70);

    @include desktop-sm-down {
      margin-bottom: rem(25);
    }
  }

  &_client {
    .parking-section__rules-row {
      align-items: center;
    }
  }

  ul {
    li {
      line-height: 170%;

      @include desktop-sm-down {
        &:not(:last-child) {
          margin-bottom: rem(20);
        }
      }
    }
  }
}

&__rules-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-15) rem(-30);

  @include desktop-sm-down {
    flex-direction: column;
    margin-bottom: rem(-20);
  }
}

&__rules-col {
  padding: 0 rem(15);
  margin-bottom: rem(30);
  width: 50%;

  @include desktop-sm-down {
    width: 100%;
    margin-bottom: rem(20);
  }
}

&__rules-head {
  margin-bottom: rem(40);

  @include desktop-sm-down {
    margin-bottom: rem(30);
  }

  h3 {
    margin-bottom: rem(22);

    @include desktop-sm-down {
      margin-bottom: rem(20);
    }
  }

  p {
    margin-top: rem(22);
    font-size: rem(20);

    @include desktop-sm-down {
      font-size: rem(15);
      margin-top: rem(20);
    }
  }
}
