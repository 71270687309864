.cookie-section {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;

  &__inner {
    background: #ffffff;
    border-radius: rem(10);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(40) rem(130);
    font-size: rem(16);
    line-height: 150%;
    color: #123063;
    filter: drop-shadow(0 rem(4) rem(24) rgba(54, 65, 210, 0.15));

    @include desktop-sm-down {
      flex-direction: column;
      padding: rem(15) rem($container-padding-md);
      font-size: rem(12);
      text-align: center;
      margin: 0 rem(-$container-padding-md);
    }
  }

  .btn {
    border: rem(2) solid #00a3da;
    background: none;
    color: #0194d8;
    display: block;
    width: 100%;
    max-width: rem(230);
    height: rem(50);
    font-size: rem(14);
    margin-left: rem(160);

    @include desktop-sm-down {
      margin-left: 0;
      margin-top: rem(15);
    }

    &:hover {
      background-color: #00a3da;
      color: #fff;
    }
  }
}
