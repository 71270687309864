.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(rem(0) rem(-5) rem(16) rgba(77, 113, 149, 0.1));

  @include desktop-sm-up {
    display: none !important;
  }

  &__inner {
    display: flex;
    height: rem($bottomNav-h);

    li {
      width: 25%;
      background: #ffffff;
      backdrop-filter: blur(rem(4));

      &.active {
        a {
          background: #e5f0f6;
          backdrop-filter: blur(rem(4));
        }
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: rem(7) rem(10);
        height: rem(60);
        font-size: rem(8);
        line-height: rem(8);
        text-align: center;
        color: #89b6e3;

        img {
          width: rem(30);
          margin-bottom: rem(7);
        }
      }
    }
  }
}
