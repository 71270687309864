.page-content {
  margin-top: rem(50);
  margin-bottom: rem(100);

  @include desktop-sm-down {
    margin-top: rem(5);
    margin-bottom: rem(35);
  }
  
  .gallery-item-section {
    position: relative;
    z-index: 10;
  }

  .calendar-block {
    &:first-child {
      @include desktop-sm-down {
        padding-top: rem(10);
      }
    }
  }
}

.for-media-content {
  &__top-swiper {
    margin-bottom: rem(80);

    @include desktop-sm-down {
      margin-bottom: rem(40);
    }
  }
}

.vip-content {
  .main-swiper {
    @include desktop-sm-down {
      margin-bottom: rem(40);
    }
  }
}

.b-page-content {
  margin-top: rem(70);
  margin-bottom: rem(100);

  @include desktop-sm-down {
    margin-top: rem(25)
  }

  &_driving {

    @include desktop-sm-down {
      margin-bottom: rem(-15);
      margin-top: rem(40);
    }

    .map-section {
      @include desktop-sm-down {
        margin-top: rem(-15);
      }
    }


    .map-section__inner {
      @include desktop-sm-down {
        padding-top: rem(80);
        margin-bottom: rem(-15);
  
        &::after {
          display: none;
        }
      }
    }
  
    .map-section .accordion-content__head {
      @include desktop-sm-down {
        top: rem(17);
      }
    }
  
    .map-section__inner {
      @include desktop-sm-down {
        border-radius: rem(15);
        box-shadow: rem(0) rem(4) rem(26) rgba(122, 169, 191, 0.13)
      }
    }
  
    .driving-block {
      @include desktop-sm-down {
        border-radius: rem(0) rem(0) rem(15) rem(15);
      }
      
    }
  }
}