.advertisers-section {
  margin: rem(60) 0;

  @include desktop-sm-down {
    margin: rem(30) 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    max-width: rem(880);
  }

  &__title {
    margin-bottom: rem(30)
  }

  &__file-links {
    &:not(:last-child) {
      margin: rem(70) 0;

      @include desktop-sm-down {
        margin: rem(30) 0;
      }
    }

    &:last-child {
      margin-top: rem(70);

      @include desktop-sm-down {
        margin-top: rem(30);
      }
    }
  }

  .file-link {
    position: relative;
    z-index: 2;
  }

  .list-rhombus {
    margin-top: rem(40);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin: rem(30) 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      @include desktop-sm-down {
        flex-direction: column;
      }

      li {
        font-weight: bold;
        margin-right: rem(110);

        @include desktop-sm-down {
          margin-right: 0;
        }
      }
    }
  }

  .article-swiper {
    @include desktop-sm-up {
      filter: drop-shadow(0 0 rem(150) rgba(#0D4D8F, 0.5));
    }

    &:not(:last-child) {
      margin: rem(70) 0;

      @include desktop-sm-down {
        margin: rem(30) 0;
      }
    }

    &:last-child {
      margin-top: rem(70);

      @include desktop-sm-down {
        margin-top: rem(30);
      }
    }
  }
  
}