.hero-section {
  &_home {
    min-height: rem(470);
    padding-bottom: rem(90);

    @include desktop-sm-down {
      padding-bottom: 0;
    }
    
    .hero-section__bg {
      background-color: #0e1248;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #1A1F4E 0%, rgba(26, 31, 78, 0) 35.05%, #1A1F4E 79.31%);
        z-index: 2;
        pointer-events: none;
      }
    
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #8B95ED;
        mix-blend-mode: multiply;
        z-index: 2;
        pointer-events: none;
      }
    }

    .hero-swiper {
      margin-top: rem(140);

      @include desktop-sm-down {
        margin-top: rem(80);
      }
    }

    &::before {
      display: none;
    }
  }

  &__home-img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    top: 0;
    left: 0;
    max-width: unset;
    z-index: 1;

    @include desktop-sm-down {
      min-width: rem(760);
      height: auto;
      left: 50%;
      transform: translateX(-50%);
      top: -10%;
    }

    img {
      margin: 0 auto;
    }
  }
}

.hero-swiper {
  &__head {
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: rem(40);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }

    h1, h2 {
      margin-top: 0;
      margin-bottom: 0;

      @include desktop-sm-down {
        font-size: rem(26);
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: rem(10) rem(50) 0;

    @include desktop-sm-down {
      display: none;
    }
  }

  &__prev,
  &__next {
    cursor: pointer;
    padding: rem(7) rem(13);
    transition: 0.3s;

    .icon {
      font-size: rem(18);
      display: block;
    }

    &:hover {
      color: #0194d8;
    }
  }

  &__filters {
    position: relative;
    z-index: 3;
    margin-left: auto;

    button {
      display: block;
      width: rem(26);
      height: rem(26);
      background: rgba(255, 255, 255, 0.16);
      border-radius: 50%;
      position: relative;

      @include desktop-sm-up {
        display: none;
      }

      &:focus,
      &:hover {
        + ul {
          display: block;
        }
      }

      &::after {
        content: "•••";
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        font-size: rem(10);
        writing-mode: vertical-rl;
      }
    }

    ul {
      display: flex;
      align-items: center;
      margin-left: auto;

      @include desktop-sm-down {
        position: absolute;
        left: rem(-10);
        top: 50%;
        transform: translate(-100%, -50%);
        display: none;
        background: rgba(255, 255, 255, 0.16);
        padding: rem(15);
        border-radius: rem(15);
      }

      li {
        font-weight: 600;
        font-size: rem(24);
        line-height: 1;
        color: #ffffff;

        @include desktop-sm-down {
          font-size: rem(16);
        }

        &:not(:last-child) {
          margin-right: rem(37);

          @include desktop-sm-down {
            margin-right: 0;
            margin-bottom: rem(10);
          }
        }

        a {
          transition: 0.3s;
          border-bottom: rem(1) solid #9bc1d7;

          &:hover {
            color: #0194d8;
            border-bottom-color: #0194d8;
          }
        }
      }
    }
  }

  .swiper {
    @include desktop-sm-down {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: rem(292);

    @include desktop-sm-down {
      width: rem(180);
    }

    &:not(:last-child) {
      margin-right: rem($container-inner-padding-md);

      @include desktop-sm-down {
        margin-right: rem(10);
      }
    }
  }
}
