.breadcrumbs {
  margin-top: rem(55);
  margin-bottom: rem(12);

  @include desktop-sm-down {
    margin-top: 0;
    background: rgba(0, 163, 218, 0.11);
    padding: rem(11) rem($container-padding-md);
    margin: 0 rem(-$container-padding-md) rem(32);
  }

  +.page-tags {
    @include desktop-sm-down {
      margin-top: rem(-10);
    }
  }

  ul {
    display: flex;

    li {
      font-weight: 550;
      font-size: rem(12);
      line-height: rem(12);
      color: rgba(255, 255, 255, 0.5);
      display: inline;

      @include desktop-sm-down {
        font-size: rem(10);
      }

      &:not(:last-child) {
        &::after {
          content: "•";
          color: #fff;
          padding: 0 rem(3);
        }
      }

      a {
        color: #ffffff;
        display: inline-block;

        &:hover {
          color: #A1D5F6;
        }
      }
    }
  }

  &_b {

    @include desktop-sm-up {
      margin-top: rem(40);
    }

    @include desktop-sm-down {
      background: #F8FCFF;
    }

    ul {
      li {
        color: #91C9E3;

        &:not(:last-child) {
          &::after {
            color: inherit;
          }
        }

        a {
          color: #0194D8;

          &:hover {
            color: #91C9E3;
            text-decoration: underline;
          }

          &:after {
            color: #91C9E3;
          }
        }
      }
    }
  }
  
}
