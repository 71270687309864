

.air-datepicker-global-container {
  width: 100%;
  max-width: rem(350);
}


.air-datepicker {
  --adp-month-cell-height: #{rem(42)};
  --adp-year-cell-height: #{rem(56)};
  --adp-day-cell-height: #{rem(32)};
}

.air-datepicker,
.air-datepicker.-inline- {
  background: #FFFFFF;
  box-shadow: rem(0) rem(4) rem(13) rgba(145, 201, 227, 0.33);
  border-radius: rem(20);
  // margin-top: rem(6);
  border: none;
  width: 100%;

  @include desktop-sm-down {
    border-radius: rem(15);
  }
}

.air-datepicker-cell {
  border-radius: 0;
  font-weight: 600;
  font-size: rem(14);
  color: #123063;
}

.air-datepicker-cell.-selected- {
  background: #0194D8;
}
.air-datepicker-cell.-selected-.-focus- {
  background: #11387A;
}

.air-datepicker-nav--action path {
  stroke: #0194D8;
}

.air-datepicker-nav--title {
  font-weight: 600;
  font-size: rem(14);
  line-height: rem(18);
  color: #123063;
  background: #F0F6FB;
  border-radius: rem(15);
  padding: 0 rem(24);
}

.air-datepicker-nav--title i {
  color: #123063;
}

.air-datepicker-nav {
  height: rem(45);
  border-bottom: none;
  padding: rem(9) 0;
}

.air-datepicker-body--day-name {
  color: #9bc1d7;
}

.air-datepicker--content {
  padding-right: rem(40);
  padding-left: rem(40);
  padding-bottom: rem(17);
}

.air-datepicker--pointer {
  display: none;
}