
.storis-modal {
  width: 100%;
  background-color: transparent;
  padding: 0;
  display: none;

  @include tablets-sm-down {
    max-width: 100%
  }

  & > .carousel__button {
    display: none;
  }

  :focus {
    outline: none;
  }

  .container {
    @include tablets-sm-down {
      padding: 0;
    }
  }
}

.storis-modal-wrap {
  z-index: 19;

  .fancybox {
    &__slide {
      overflow-x: hidden;
      padding: rem(80) rem(8);

      @include desktop-sm-up {
        padding: rem(170) rem(24) rem(64);
      }

      @include desktop-sm-down {
        padding: rem($header-h-m) 0 rem($bottomNav-h);
      }
    }

    &__backdrop {
      background: rgba(14, 18, 72, 0.9);
    }
  }

  .carousel__button {
    color: $white;

    &.is-close {
      top: 0;
      right: 0;
      width: auto;
      height: auto;

      @include desktop-sm-up {
        top: 0;
        right: 0;
      }

      @include tablets-sm-down {
        top: rem(10);
      }
    }
  }
}

.storis-slider {
  position: relative;
  padding-top: rem(36);

  @include desktop-sm-up {
    padding-top: rem(24);
  }

  @include tablets-sm-down {
    padding-top: 0;
  }

  & > .swiper {
    overflow: visible;
    width: calc(140vh);
    max-width: 100%;

    @include desktop-sm-down {
      width: calc(80vh);
    }

    @include tablets-sm-down {
      width: 100%;
    }
  }

  .swiper-slide {
    .story-slider {
      transform: scale(0.8);
      pointer-events: none;
    }
  }

  .swiper-slide-active {
    z-index: 2;

    & > .story-slider {
      transform: scale(1);
      pointer-events: auto;

      .story-slider__nav {
        opacity: 1;
        visibility: visible;
      }

      // .storis-card__info {
      //   visibility: visible;
      //   opacity: 1;
      // }
    }
  }

  .swiper-slide-prev {
    .story-slider {
      transform-origin: left;
    }
  }

  .swiper-slide-next {
    .story-slider {
      transform-origin: right;
    }
  }

  .story-slider {
    transition: transform 0.3s;

    .swiper-pagination {
      padding: rem(20) rem(17.5) 0;
      position: absolute;
      bottom: auto;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;

      @include desktop-sm-down {
        padding: rem($container-padding-md) rem($container-padding-md) 0;
      }
    }

    .swiper-pagination-custom {
      background-color: transparent;
      flex-grow: 1;
      height: rem(4);
      padding: 0 rem(2.5);
      margin: 0;
      opacity: 1;
      border-radius: rem(20);
      overflow: hidden;

      @include desktop-sm-down {
        height: rem(2);
      }

      &:only-child {
        display: block!important;
      }

      &__inner {
        width: 100%;
        height: 100%;
        background-color: rgba($white, 0.5);
        position: relative;
        display: block;
        border-radius: rem(20);
      }

      &__progress {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: scaleX(0);
        height: 100%;
        border-radius: rem(20);
        background-color: $white;
        transform-origin: left;
      }

      &.filled {
        .swiper-pagination-custom__progress {
          transform: scaleX(1);
        }
      }

      &.swiper-pagination-bullet-active {
        background-color: transparent;

        // .swiper-pagination-custom__progress {
        //   width: 100%;
        // }
      }
    }
  }
}

.story-slider {
  position: relative;

  &__nav {
    opacity: 0;
    visibility: hidden;
  }

  &__btn {
    position: absolute;
    top: 50%;
    width: rem(30);
    height: rem(30);
    margin-top: rem(-15);
    border-radius: 50%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00A3DA;
    font-size: rem(8);
    transition: .3s;

    @include tablets-sm-down {
      display: none;
    }

    &:not(.swiper-button-disabled):hover {
      background-color: #00A3DA;
      color: #fff;
      cursor: pointer;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
    }

    &.swiper-button-lock {
      display: flex;
    }
  }

  &__prev {
    left: rem(-60);
  }

  &__next {
    right: rem(-60);
  }
}

.storis-card {
  width: 100%;
  padding-top: 169.36%;
  position: relative;

  @include tablets-sm-down {
    padding-top: 0;
    height: calc(100vh - rem($header-h-m+$bottomNav-h));
  }

  video,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: rem(40) rem(20) 0;
    background-image: linear-gradient(180deg, rgba(23, 27, 66, 0.8) 39.42%, rgba(22, 27, 66, 0) 100%);
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1;
    // opacity: 0;
    // visibility: hidden;

    @include tablets-sm-down {
      padding: rem(20) rem($container-padding-md) 0;
    }

    &-inner {
      position: relative;
      padding-left: rem(80);
      min-height: rem(60);
      display: flex;
      align-items: center;
      font-weight: 550;
      font-size: rem(14);
      line-height: 1.3;
      color: $white;

      @include tablets-sm-down {
        font-size: rem(14);
        min-height: rem(40);
        padding-left: rem(50);
      }
    }

    &-pic {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: rem(60);
      height: rem(60);
      border: rem(2) solid #00A3DA;
      padding: rem(2);

      @include tablets-sm-down {
        width: rem(40);
        height: rem(40);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  &__btn {
    height: rem(50);  
    padding: 0 rem(30);
    position: absolute;
    bottom: rem(30);
    left: 50%;
    transform: translateX(-50%);
    color: #00A3DA;
    z-index: 3;
    background: #FFFFFF;
    white-space: nowrap;
    min-width: rem(200);

    @include tablets-sm-down {
      bottom: rem(20);
      height: rem(40);
      font-size: rem(12);
    }

    &:hover {
      background: #0091C2;
      color: #fff;
    }
  }
}