.tabs-block {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include desktop-sm-down {
    display: flex;
    text-align: center;
    margin-right: rem(-$container-padding-md);
    margin-left: rem(-$container-padding-md);
    padding: 0 rem($container-padding-md);
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__inner {
    display: inline-flex;

    // @include desktop-sm-down {
    //   margin: 0 auto;
    // }
  }

  .btn {
    padding: 0 rem(37);
    height: rem(75);
    border: rem(2) solid #00a3da;
    font-size: rem(18);
    color: #fff;
    white-space: nowrap;

    @include desktop-sm-down {
      font-size: rem(12);
      border: none;
      padding: 0 rem(20);
      height: rem(50);
      border-radius: 0;
    }

    &:not(:last-child) {
      @include desktop-sm-up {
        margin-right: rem(30);
      }

      &:not(.active) {
        @include desktop-sm-down {
          border-right: rem(1) solid #10397b;
        }
      }
    }

    @include desktop-sm-down {
      &:first-child {
        border-radius: rem(15) 0 0 rem(15);
      }

      &:last-child {
        border-radius: 0 rem(15) rem(15) 0;
      }
    }

    &:not(.active) {
      background: none;

      &:hover {
        background: #00A3DA;
      }

      @include desktop-sm-down {
        background: #204b94;
      }
    }

    &.active {
      border: none;
      background: #00A3DA;

      &:hover {
        background: #0194D8;
      }
    }

    
  }
}
