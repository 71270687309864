.card-f {
  display: block;
  background: #f7f8fb;
  border-radius: rem(30);
  overflow: hidden;
  width: 100%;
  transition: .3s;

  @include desktop-sm-down {
    border-radius: rem(20);
  }

  &:hover {
    background: #F0F9FF;

    .card-f__img img {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }

  &__head {
    position: relative;
  }

  &__img {
    padding-top: 69.44%;
    position: relative;
    overflow: hidden;

    @include desktop-sm-down {
      padding-top: 40.67%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30%;
      transition: 3s;
    }
  }

  &__ico {
    display: none;
    position: absolute;
    top: rem(26);
    left: rem(36);
    width: rem(65);
    border-radius: 50%;
    overflow: hidden;

    @include desktop-sm-down {
      width: rem(45);
      top: rem(20);
      left: rem(20);
    }
  }

  &__main {
    padding: rem(40) rem(36);

    @include desktop-sm-down {
      padding: rem(22) rem(20) rem(28);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(26);
    line-height: 110%;
    color: #123063;
    margin-bottom: rem(16);

    @include desktop-sm-down {
      font-size: rem(18);
      margin-bottom: rem(8);
    }
  }

  &__desc {
    font-size: rem(14);
    line-height: 1;
    color: #9bc1d7;

    @include desktop-sm-down {
      font-size: rem(10);
    }
  }
}
