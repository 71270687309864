.stock-swiper {
  margin-bottom: rem(40);

  @include desktop-sm-down {
    margin: 0 rem(-$container-padding-md);
  }

  .swiper-slide {

    @include desktop-sm-up {
      width: 75%;
    }

    &:not(:last-child) {
    
      @include desktop-sm-up {
        margin-right: rem(30);
      }
    }

    &:not(.swiper-slide-active) {
      .card-stock {
        > img {
          @include desktop-sm-up {
            transform: translateX(-57%);
          }
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include desktop-sm-down {
      display: none;
    }
  }

}
