.b-plan-block {
  padding-top: rem(80);
  padding-bottom: rem(70);
  height: rem(1240);
  box-sizing: border-box;

  @include desktop-sm-down {
    padding-top: rem(65);
    padding-bottom: rem(25);
    height: auto;
  }

  &__inner {
    position: relative;

    @include desktop-sm-down {
      padding-bottom: rem(70);
    }
  }

  &__scheme {
  
    @include desktop-sm-up {
      width: rem(1381);
      height: rem(769);
      position: absolute;
      top: 0;
      right: 0;
    }

    @include desktop-sm-down {
      width: 100%;
      padding-top: 85%;
      position: relative;
    }
  }

  &__categories {
    margin-bottom: rem(45);
    position: relative;
    z-index: 2;
  }

  &__legend {
    margin-bottom: rem(40);
    position: relative;
    z-index: 2;
  }

  &__btn {
    width: rem(56);
    height: rem(56);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(23);
    border-radius: 50%;
    color: #0194D8;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    transition: .3s;

    @include desktop-sm-down {
      width: rem(39);
      height: rem(39);
      top: rem(7);
      filter: drop-shadow(rem(0) rem(4) rem(23) #91C9E3);
    }

    &:hover {
      background: #0194D8;

      svg {
        stroke: #fff;
      }
    }

    svg {
      width: rem(23);
      stroke: #0194D8;
      stroke-width: rem(4);
      stroke-linecap: round;
      transition: .3s;

      @include desktop-sm-down {
        width: rem(16);
      }
    }


    &.zoom-in {
      right: 0;
    }

    &.zoom-out {
      right: rem(66);

      @include desktop-sm-down {
        right: rem(48);
      }
    }
  }

  &__select {
    margin-bottom: rem(30);
    filter: drop-shadow(rem(0) rem(4) rem(17) rgba(145, 201, 227, 0.66));
    position: relative;
    z-index: 5;
  
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      background: #123063;
      border-radius: rem(15);
      
    }

    .select2-container--default .select2-selection--single {
      border: none;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      background: url("../img/icons/chevron-down2.svg") no-repeat 50% / contain;
    }

    .select2-hidden-accessible.active + .select2 .select2-selection {
      background: #fff;
    }
  }

  &__cover {
    @include desktop-sm-down {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: rem(60);

    }
  }

  &__legend-btn {
    width: rem(53);
    height: rem(53);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: rem(0) rem(4) rem(14) rgba(145, 201, 227, 0.55);
    border-radius: rem(15);
    font-size: rem(18);
    color: #91C9E3;
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s;

    &:hover {
      color: #fff;
      background: #0194D8;
    }
  }
}

.plan-brands-card {
  display: flex;
  position: relative;
  padding: rem(30) rem(25);
  background: #FFFFFF;
  border-radius: rem(20);

  @include desktop-sm-up {
    display: none !important;
  }

  &__ico {
    width: rem(74);
    height: rem(74);
    border-radius: 50%;
    overflow: hidden;
    margin-right: rem(20);
    position: relative;
    flex-shrink: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: rem(23);
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #123063;
    min-height: rem(50);
  }

  &__link {
    font-style: italic;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #0194D8;

    .icon {
      font-size: rem(5);
      margin-left: rem(10);
    }
  }

  &__close {
    position: absolute;
    top: rem(12);
    right: rem(12);
    color: #91C9E3;
    font-size: rem(16);

  }
}

@import "includes/b-plan-block/plan-scheme";
@import "includes/b-plan-block/plan-categories";
@import "includes/b-plan-block/plan-legend";
@import "includes/b-plan-block/plan-brands";
