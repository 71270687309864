.header-mob {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(rem(0) rem(6) rem(16) rgba(77, 113, 149, 0.1));
  background-color: #ffffff;

  @include desktop-sm-up {
    display: none !important;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(60);
  }

  &__logo {
    display: block;
    max-width: rem(139);
  }

  &__search-btn {
    width: rem(20);
    height: rem(20);
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      color: #123063;
    }

    .icon-search {
      font-size: rem(19);
    }

    .icon-cross {
      font-size: rem(18);
    }

    &:not(.close) {
      .icon-cross {
        display: none;
      }
    }

    &.close {
      .icon-search {
        display: none;
      }
    }

    &:hover {
      .icon {
        color: #0194d8;
      }
    }
  }

  &__menu-btn {
    display: block;
    width: rem(30);
    height: rem(20);
    transition: all 0.3s ease;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-left: rem(35);

    .icon {
      color: #123063;
    }

    .icon-burger {
      font-size: rem(30);
      color: #00a3da;
    }

    .icon-cross {
      font-size: rem(18);
    }

    &:not(.close) {
      .icon-cross {
        display: none;
      }
    }

    &.close {
      .icon-burger {
        display: none;
      }
    }

    &:hover {
      .icon {
        color: #0194d8;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    .header-mob__sm-btn {
      &:not(:last-child) {
        margin-right: rem(20);
      }
    }
  }

  &__sm-btn {
    width: rem(32);
    height: rem(32);
    border-radius: rem(10);

    .icon {
      font-size: rem(14);
    }
  
    .icon-street-map {
      font-size: rem(14);
    }
  
    .icon-p-parking {
      font-size: rem(9);
    }

  }

  &_b-header-mob {
    .header-mob__menu-btn {
      margin-left: rem(20);
    }

    .header-mob__menu-btn .icon-burger {
      color:  #123063;
    }
  }
}

@import "includes/header-mob/mob-menu";
@import "includes/header-mob/mob-search";