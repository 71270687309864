.news-section {
  margin-bottom: rem(120);
  margin-top: rem(60);

  @include desktop-sm-down {
    margin-top: rem(40);
    margin-bottom: rem(50);
  }

  .section-head {
    margin-bottom: rem(90);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }
  }

  .swiper-slide {
    width: rem(360);

    @include desktop-sm-down {
      width: rem(290);
    }
  }
}